<div class="tj-modal-header">
	<h4 class="tj-modal-header-title" i18n>{{ 'sendVisitBto' | translate }}</h4>
</div>
<div class="tj-modal-body tj-send-modal">
	<p>{{ 'sendVisitIConfirm' | translate }}</p>
	<ul>
		<li>{{ 'sendVisitBulletOne' | translate }}</li>
		<li>{{ 'sendVisitBulletTwo' | translate }}</li>
		<li>{{ 'sendVisitBulletThree' | translate }}</li>
		<li>{{ 'sendVisitBulletFour' | translate }}</li>
		<li>{{ 'sendVisitBulletFive' | translate }}</li>
		<li>{{ 'sendVisitBulletSix' | translate }}</li>
		<li>{{ 'sendVisitBulletSeven' | translate }}</li>
		<li>{{ 'sendVisitBulletEight' | translate }}</li>
		<li>{{ 'sendVisitBulletNine' | translate }}</li>
	</ul>
</div>
<div class="tj-modal-footer">
	<button type="button" class="tj-btn tj-btn__danger" (click)="leave()">{{ 'cancel' | translate }}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus>{{
		'sendVisitBto' | translate
		}}</button>
</div>
