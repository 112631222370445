import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './views/auth/auth.component';
import { HomeComponent } from './views/home/home.component';
import { ProfileComponent } from './views/profile/profile.component';
import { VisitComponent } from './views/visit/visit.component';
import { LoginCallbackComponent } from './views/login-callback/login-callback.component';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'auth', component: AuthComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'profile', component: ProfileComponent },
	{
		path: 'bto/:id',
		component: VisitComponent,
		canDeactivate: [(component: VisitComponent) => component.canDeactivate()],
		data: { type: 'bto' }
	},
	{
		path: 'gfb/:id',
		component: VisitComponent,
		canDeactivate: [(component: VisitComponent) => component.canDeactivate()],
		data: { type: 'gfb' }
	},
	{ path: 'login-callback', component: LoginCallbackComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
