import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, HostListener, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Breadcrumb, BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserService } from 'src/app/services/user.service';
import { notApplicablePreventionWithoutReason, Prevention, PreventionDialogData, Visit, VisitService } from 'src/app/services/visit.service';
import { TreeService } from 'src/app/services/tree.service';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { CanComponentDeactivate } from 'src/app/can-deactivate.guard';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeactivateConfirmComponent } from 'src/app/components/deactivate-confirm/deactivate-confirm.component';
import { WorkSystemComponent } from './sub-components/work-system/work-system.component';
import { SpecificCharacteristicsComponent } from './sub-components/specific-characteristics/specific-characteristics.component';
import { RisksEvaluatedComponent } from './sub-components/risks-evaluated/risks-evaluated.component';
import { WorksiteMeasuresComponent } from './sub-components/worksite-measures/worksite-measures.component';
import { PersonalMeasuresComponent } from './sub-components/personal-measures/personal-measures.component';
import { PreventionComponent } from './sub-components/prevention/prevention.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { PresentAndAbsentStaffComponent } from './sub-components/present-and-absent-staff/present-and-absent-staff.component';
import { SendModalComponent } from './sub-components/send-modal/send-modal.component';
import { CancelVisitModalComponent } from './sub-components/cancel-visit/cancel-visit.component';
import { SendGfbModalComponent } from './sub-components/send-gfb-modal/send-gfb-modal.component';
import { Subscription, firstValueFrom, iif } from 'rxjs';
import { AdditionalInformationComponent } from './sub-components/additional-information/additional-information.component';
import { StartModalComponent } from './sub-components/start-modal/start-modal.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ReasonMissingDialogComponent } from './sub-components/prevention/sub-components/reason-missing-dialog/reason-missing-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	standalone: true,
	selector: 'app-visit',
	templateUrl: './visit.component.html',
	styleUrls: ['./visit.component.scss'],
	imports: [
		CommonModule,
		MatTabsModule,
		WorkSystemComponent,
		SpecificCharacteristicsComponent,
		RisksEvaluatedComponent,
		WorksiteMeasuresComponent,
		PersonalMeasuresComponent,
		PreventionComponent,
		PresentAndAbsentStaffComponent,
		AdditionalInformationComponent,
		TranslatePipe
	]
})
export class VisitComponent implements OnInit, CanComponentDeactivate {
	private activatedRoute = inject(ActivatedRoute);
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	visit: Visit | null = null;
	visitType: 'bto' | 'gfb' = this.route.snapshot.data['type'];
	hasUnsavedFields: boolean = false;
	startedByMe: boolean = false;
	scrolled: boolean = window.scrollY > 55;
	formattedDate: string = '';
	screenService: Subscription | null = null;
	menuOpened: boolean = false;
	sendModalRef: NgbModalRef | null = null;
	cancelModalRef: NgbModalRef | null = null;
	startModal: NgbModalRef | null = null;
	@ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
	private currentIndex: number = 0;
  	private protectedTabIndex: number = 4; // Prävention Tab Index Number

	constructor(
		public router: Router,
		private userService: UserService,
		private breadcrumbService: BreadcrumbService,
		private visitService: VisitService,
		private modalService: NgbModal,
		private treeService: TreeService,
		private route: ActivatedRoute,
		private oidcSecurityService: OidcSecurityService,
		private authService: AuthService,
		private http: HttpClient,
		public dialog: MatDialog
	) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.visit = this.visitService.getStoredVisit();

		if (this.visit?.executedByUserId === this.userService.getUser()?.sub) this.startedByMe = true;
		this.formattedDate = this.visit?.submitDate ? DateTime.fromISO(this.visit?.submitDate ?? '').toFormat('dd.MM.yyyy') : '';

		this.setBreadcrumb();
	}

	async ngOnInit(): Promise<void> {
		this.visitType = this.route.snapshot.data['type'];

		this.oidcSecurityService.isAuthenticated().subscribe((isAuthenticated) => {
			if (!isAuthenticated) {
				this.authService.logout();
				return;
			}
		});

		await this.getData();

		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			if (visit?.hasUnsavedFields) this.hasUnsavedFields = true;
			else this.hasUnsavedFields = false;
			this.visit = visit;
			this.formattedDate = this.visit?.submitDate ? DateTime.fromISO(this.visit?.submitDate ?? '').toFormat('dd.MM.yyyy') : '';
		});
	}

	ngOnDestroy(): void {
		this.visitService.clearVisit();
		this.treeService.clearTree();
		this.screenService?.unsubscribe();
		this.breadcrumbService.setBreadcrumb({ locations: [] });
		this.modalService.dismissAll();
	}

	setBreadcrumb() {
		const visitType = this.route.snapshot.data['type'];
		const locations: Breadcrumb['locations'] = [{ name: visitType === 'bto' ? 'BTO' : 'GFB', path: '/home' }];
		if (this.visit?.workplace.workplaceName) locations.push({ name: this.visit.workplace.workplaceName });

		this.breadcrumbService.setBreadcrumb({ locations });
	}

	canDeactivate() {
		if (this.visit?.hasUnsavedFields) {
			const modalRef = this.modalService.open(DeactivateConfirmComponent, { centered: true, backdrop: 'static' });
			return modalRef.result;
		}

		return true;
	}

	async getData() {
		if (!this.userService.getUser() || !this.authService.getToken()) return;

		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const visitId = this.activatedRoute.snapshot.params['id'] as string;

			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/${this.visitType}/${visitId}`, { headers, observe: 'response' })
			);

			if (response.status !== 200) throw new Error('Error fetching visit data');

			const visit = response.body as Visit;

			this.setVisit(visit || {});

			if (visit?.executedByUserId === this.userService.getUser()?.sub) this.startedByMe = true;
			this.formattedDate = this.visit?.submitDate ? DateTime.fromISO(this.visit?.submitDate ?? '').toFormat('dd.MM.yyyy') : '';

			const locations: Breadcrumb['locations'] = [{ name: this.visitType === 'bto' ? 'BTO' : 'GFB', path: '/home' }];
			if (visit?.workplace?.workplaceName) locations.push({ name: visit?.workplace?.workplaceName });

			this.breadcrumbService.setBreadcrumb({ locations });
		} catch (error: unknown) {
			this.router.navigate(['/home']);
		}
	}


	changeTab(event: MatTabChangeEvent) {
		if (event.tab.textLabel !== 'risks') {
			this.treeService.clearTree();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	onBeforeUnload() {
		return !this.hasUnsavedFields;
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(event: Event) {
		event.preventDefault();

		const nextScrolled = window.scrollY > 55;
		if (nextScrolled === this.scrolled) return;

		this.scrolled = nextScrolled;
	}

	setVisit(visit: Visit) {
		this.visit = visit;
		this.visitService.setVisit(visit);
	}

	goHome() {
		this.router.navigate(['/home']);
	}

	onMenuToggle() {
		this.menuOpened = !this.menuOpened;
	}

	showSendModal(event: Event) {
		event.stopPropagation();
		event.preventDefault();

		if (this.visitType === 'bto') {
			this.sendModalRef = this.modalService.open(SendModalComponent, { centered: true, backdrop: 'static' });
			this.sendModalRef.result
				.then((result) => {
					if (result) {
						this.sendVisit();
					}
				})
				.catch(() => {});
		} else {
			this.sendModalRef = this.modalService.open(SendGfbModalComponent, { centered: true, backdrop: 'static' });
			this.sendModalRef.result
				.then((result) => {
					if (result) {
						this.sendVisit();
					}
				})
				.catch(() => {});
		}
	}

	showStartModal(event: Event) {
		event.stopPropagation();
		event.preventDefault();

		this.startModal = this.modalService.open(StartModalComponent, { centered: true, backdrop: 'static' });
		this.startModal.componentInstance.visitType = this.visitType;
	}

	showCancelModal(event: Event) {
		event.stopPropagation();
		event.preventDefault();

		this.cancelModalRef = this.modalService.open(CancelVisitModalComponent, { centered: true, backdrop: 'static' });
		this.cancelModalRef.componentInstance.visitType = this.visitType;

		this.cancelModalRef.result
			.then((result) => {
				if (result) {
					this.cancelVisit();
				}
			})
			.catch(() => {});
	}

	async saveVisit() { // TODO: Abfrage für den Grund erstellen
		const visit = this.visit as Visit;
		if (!visit) return;

		const preventionDialogData: PreventionDialogData = {notApplicablePreventionsWithoutReason: this.preventionReasonCheck(visit.workplacePreventions)}

		if(preventionDialogData.notApplicablePreventionsWithoutReason.length > 0){
			this.openDialog(preventionDialogData);
		}

		else{
		let result: any;

		if (this.visitType === 'bto') {
			result = await this.visitService.saveVisit(visit);
		} else if (this.visitType === 'gfb') {
			result = await this.visitService.saveAssessment(visit);
		}
		if (result) this.setVisit({ ...result, hasUnsavedFields: false });
		}
	}

	async sendVisit() {
		const visit = this.visit as Visit;
		if (!visit) return;

		let result: any;

		if (this.visitType === 'bto') {
			result = await this.visitService.sendVisit(visit);
		} else if (this.visitType === 'gfb') {
			result = await this.visitService.sendAssessment(visit);
		}

		if (result) {
			this.visitService.clearVisit();
			this.router.navigate(['/home']);
		}
	}

	async cancelVisit() {
		const visit = this.visit as Visit;
		if (!visit) return;

		let result: any;

		if (this.visitType === 'bto') {
			result = await this.visitService.cancelVisit(visit);
		} else if (this.visitType === 'gfb') {
			result = await this.visitService.cancelAssessment(visit);
		}

		if (result) {
			this.visitService.clearVisit();
		}

		this.router.navigate(['/home']);
	}

		preventionReasonCheck(preventions: Prevention[]): notApplicablePreventionWithoutReason[] {
			let result: notApplicablePreventionWithoutReason[] = [];
			let lastCategoryName: string | null = null;
		  
			preventions.forEach((prevention) => {
			  if (prevention.isApplicable === false && !prevention.note) {
				const rootCategoryName = prevention.prevention.rootCategoryName;
		  
				// Add the root category name as a header if its the first occurrence or a new category
				if (rootCategoryName !== lastCategoryName) {
				  result.push({ name: rootCategoryName, isRootCategoryName: true });
				  lastCategoryName = rootCategoryName;
				}
		  
				// Add the prevention under the current category
				result.push({ name: prevention.prevention.name, isRootCategoryName: false });
			  }
			});

			return result;
		}

	openDialog(preventionDialogData: PreventionDialogData) {
		this.dialog.open(ReasonMissingDialogComponent, {
		  data: {
			notApplicablePreventionsWithoutReason: preventionDialogData.notApplicablePreventionsWithoutReason
		  },
		});
	}
}
