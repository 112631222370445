import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';
import { CommonModule } from '@angular/common';
import { Reason, Visit, VisitService } from 'src/app/services/visit.service';
import { MatSelectModule } from '@angular/material/select';

@Component({
	standalone: true,
	selector: 'app-send-modal',
	templateUrl: './send-gfb-modal.component.html',
	styleUrls: ['./send-gfb-modal.component.scss'],
	imports: [MatInputModule, ReactiveFormsModule, TranslatePipe, CommonModule, MatSelectModule, ReactiveFormsModule]
})
export class SendGfbModalComponent {
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);
	visit: Visit | null = null;
	evaluation: FormControl = new FormControl('');
	reasonForReview: FormControl = new FormControl('');
	result: FormControl = new FormControl('');
	hasUnsavedChanges: boolean = false;

	evaluationOptions: Reason[] = [];

	reasonForReviewOptions: Reason[] = [];

	resultOptions = [
		{
			value: '1'
		},
		{
			value: '2'
		},

		{
			value: '3'
		},
		{
			value: '4'
		},
		{
			value: '5'
		},
		{
			value: '6'
		}
	];

	constructor(public modal: NgbActiveModal, private visitService: VisitService, private translateService: TranslateService) {
		this.visit = this.visitService.getStoredVisit();

		this.getData();
	}

	async getData() {
		const evaluation = await this.visitService.getFieldEvaluation();
		this.evaluationOptions = evaluation;
		const reviews = await this.visitService.getFieldReason();
		this.reasonForReviewOptions = reviews;
	}

	leave() {
		this.modal.close(false);
	}

	async submit() {
		if (!this.evaluation.value || !this.reasonForReview.value || !this.result.value) return;

		this.visitService.setVisit({
			...this.visit,
			riskEvaluationScore: this.evaluation.value,
			riskEvaluationReason: {
				primaryKey: this.reasonForReview.value.primaryKey,
				name: this.reasonForReview.value.name
			},
			riskEvaluationResult: {
				primaryKey: this.result.value.primaryKey,
				name: this.result.value.name
			},
			hasUnsavedFields: false
		} as Visit);

		const visit = this.visitService.getStoredVisit();
		if (visit) await this.visitService.saveAssessment(visit);

		this.modal.close(true);
	}
}
