<div class="tj-modal-header">
	<h4 class="tj-modal-header-title">{{ 'description' | translate }}</h4>
</div>
<div class="tj-modal-body tj-risk-description-modal">
	<mat-form-field class="tj-fill-container tj-modal-input" appearance="fill" hideRequiredMarker
		subscriptSizing="dynamic">
		<input type="text" matInput [placeholder]="'value' | translate" [formControl]="description" />
	</mat-form-field>
</div>
<div class="tj-modal-footer">
	<button type="button" class="tj-btn tj-btn__danger" (click)="leave()">{{ 'cancel' | translate }}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus>{{ 'ok' | translate }}</button>
</div>