import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import translations from '../../locale/translations';

@Pipe({
	standalone: true,
	name: 'translate'
})
export class TranslatePipe implements PipeTransform {
	language: string = this.userService.getUser()?.language || localStorage.getItem('language') || 'de';

	constructor(private userService: UserService) {}

	transform(value: string, lang?: 'en' | 'de'): string {
		const language = lang || this.userService.getUser()?.language || 'de';
		const strings = translations[language as 'en' | 'de'];
		const entry = value as keyof typeof strings;

		return strings[entry] || value;
	}
}

@Injectable({ providedIn: 'root' })
export class TranslateService {
	constructor(private userService: UserService) {}

	translate(value: string, lang?: 'en' | 'de'): string {
		const language = lang || this.userService.getUser()?.language || localStorage.getItem('language') || 'de';
		const strings = translations[language as 'en' | 'de'];
		const entry = value as keyof typeof strings;

		return strings[entry] || value;
	}
}
