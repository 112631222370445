import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ModalDescriptionComponent } from './sub-components/modal-description/modal-description.component';
import { ModalHazardComponent } from './sub-components/modal-hazard/modal-hazard.component';
import { Ppe, Risk, Visit, VisitService, WorkplacePrecaution } from 'src/app/services/visit.service';
import { PpeData, RiskData, RiskNode, RiskTableData, WorkplacePrecautionData } from './types';
import { UserService } from 'src/app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTreeModule, NzTreeNode } from 'ng-zorro-antd/tree';
import { NzTreeComponent } from 'ng-zorro-antd/tree';
import { Tree, TreeService } from 'src/app/services/tree.service';
import { RiskService } from '../../services/risk.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Subscription } from 'rxjs';

interface ExampleFlatNode {
	primaryKey?: number;
	name?: string;
	description?: string;
	expandable: boolean;
	level: number;
	probability?: {
		primaryKey: number;
		name: string;
	};
	severeDamage?: {
		primaryKey: number;
		name: string;
		value: number;
	};
	risk?: RiskData;
	workplacePpe?: Ppe[];
	workplacePrecaution?: WorkplacePrecaution[];
}

@Component({
	standalone: true,
	selector: 'app-risks-evaluated',
	templateUrl: './risks-evaluated.component.html',
	styleUrls: ['./risks-evaluated.component.scss'],
	imports: [
		CommonModule,
		MatTreeModule,
		MatIconModule,
		MatExpansionModule,
		ReactiveFormsModule,
		MatInputModule,
		NzDrawerModule,
		NzTreeModule,
		MatCheckboxModule,
		TranslatePipe
	]
})
export class RisksEvaluatedComponent implements OnInit, OnDestroy {
	screenService: Subscription | null = null;

	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	modalType = '';
	drawerType: string = '';
	visible: boolean = false;
	visit: Visit | null = null;
	visitServiceSubscription: Subscription | null = null;
	tree: Tree | null = null;
	treeServiceSubscription: Subscription | null = null;
	startedByMe: boolean = false;
	expandedNodesId: number[] = [];
	riskId?: number | null = null;

	fcPpe = new FormControl<Ppe[]>([]);
	fcWorkplacePrecaution = new FormControl<WorkplacePrecaution[]>([]);

	ppe: PpeData[] = [];
	searchedPpe = this.ppe;
	tempPpe: number[] = [];

	workplacePrecaution: WorkplacePrecautionData[] = [];
	searchedWorkplacePrecaution = this.workplacePrecaution;
	tempWorkplacePrecaution: number[] = [];

	@ViewChild('nzTreeComponent', { static: false })
	nzTreeComponent!: NzTreeComponent;
	checkedKeys: number[] = [];
	risksList: any = [];
	treeSearch = '';

	constructor(
		private visitService: VisitService,
		private userService: UserService,
		private modalService: NgbModal,
		private treeService: TreeService,
		private riskService: RiskService
	) {
		const visit = this.visitService.getStoredVisit();

		this.visit = visit;
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';

		this.startedByMe = visit?.executedByUserId === this.userService.getUser()?.sub && this.visit?.status !== 'StartRequested';
	}

	ngOnInit(): void {
		this.visitServiceSubscription = this.visitService.visitSubject.subscribe((visit) => {
			this.visit = visit;
			const formatedRisks = this.formatRisksToTable(visit?.workplaceRisks || []);
			this.dataSource.data = formatedRisks || [];
			this.expandedNodesId = this.treeService.getTree()?.expandedNodesIds || [];

			this.expandedNodesId.forEach((nodeId) => {
				const node = this.treeControl.dataNodes.find((node) => node.primaryKey === nodeId);
				if (node) this.treeControl.expand(node);
			});
		});

		this.treeServiceSubscription = this.treeService.treeSubject.subscribe((tree) => {
			this.expandedNodesId = tree?.expandedNodesIds || [];

			if (tree === null) {
				this.treeControl.dataNodes.forEach((node) => {
					this.treeControl.collapse(node);
				});
			}
		});

		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			if (
				visit?.executedByUserId === this.userService.getUser()?.sub &&
				visit?.status !== 'StartRequested' &&
				visit?.status !== 'Finished'
			) {
				this.startedByMe = true;
			} else {
				this.startedByMe = false;
			}
		});

		this.getData();
	}

	ngOnDestroy(): void {
		this.visitServiceSubscription?.unsubscribe();
		this.treeServiceSubscription?.unsubscribe();
		this.riskService.clearRisk();
	}

	async getData() {
		const ppe = await this.visitService.getPpe();
		this.ppe = ppe;
		this.searchedPpe = ppe;
		const workplacePrecaution = await this.visitService.getWorkplacePrecaution();
		this.workplacePrecaution = workplacePrecaution;
		this.searchedWorkplacePrecaution = workplacePrecaution;
		const risks = await this.visitService.getRisks();
		this.risksList = this.formatRisksToTree(risks);
	}

	formatRisksToTree(risks: any) {
		const finalData: RiskNode[] = [];

		risks.forEach((risk: RiskData) => {
			const riskInFinalData = finalData.find((category: RiskNode) => category.title === risk.rootCategoryName);

			if (riskInFinalData) {
				const isRiskSubCategoryInRootCategory = riskInFinalData.children!.find(
					(subCategory: RiskNode) => subCategory.title === risk.subCategoryName
				);

				if (isRiskSubCategoryInRootCategory) {
					isRiskSubCategoryInRootCategory.children!.push({
						primaryKey: risk.primaryKey,
						title: risk.name,
						key: risk.primaryKey,
						isLeaf: true,
						selectable: false
					});
				} else {
					riskInFinalData.children!.push({
						primaryKey: risk.subCategoryPrimaryKey,
						title: risk.subCategoryName,
						isLeaf: false,
						selectable: false,
						children: [
							{
								primaryKey: risk.primaryKey,
								title: risk.name,
								key: risk.primaryKey,
								isLeaf: true,
								selectable: false
							}
						],
						key: risk.subCategoryPrimaryKey
					});
				}
			} else {
				finalData.push({
					primaryKey: risk.rootCategoryPrimaryKey,
					title: risk.rootCategoryName,
					isLeaf: false,
					selectable: false,
					children: [
						{
							primaryKey: risk.subCategoryPrimaryKey,
							title: risk.subCategoryName,
							isLeaf: false,
							selectable: false,
							children: [
								{
									primaryKey: risk.primaryKey,
									title: risk.name,
									key: risk.primaryKey,
									isLeaf: true,
									selectable: false
								}
							],
							key: risk.subCategoryPrimaryKey
						}
					],
					key: risk.rootCategoryPrimaryKey
				});
			}
		});

		return finalData;
	}

	formatRisksToTable(risks: Risk[]) {
		const finalData: any[] = [];

		risks.forEach((risk: Risk) => {
			const rootInFinalData = finalData.find((category: RiskTableData) => category.primaryKey === risk.risk?.rootCategoryPrimaryKey);

			if (rootInFinalData) {
				const isRiskSubCategoryInRootCategory = rootInFinalData?.children.find(
					(subCategory: RiskTableData) => subCategory.primaryKey === risk.risk?.subCategoryPrimaryKey
				);

				if (isRiskSubCategoryInRootCategory) {
					isRiskSubCategoryInRootCategory.children!.push({
						primaryKey: risk.risk?.primaryKey,
						risk: {
							primaryKey: risk.risk?.primaryKey,
							name: risk.risk?.name,
							rootCategoryPrimaryKey: risk.risk?.rootCategoryPrimaryKey,
							rootCategoryName: risk.risk?.rootCategoryName,
							subCategoryPrimaryKey: risk.risk?.subCategoryPrimaryKey,
							subCategoryName: risk.risk?.subCategoryName
						},
						key: risk.risk?.primaryKey,
						workplacePpe: risk.workplacePpe,
						workplacePrecaution: risk.workplacePrecaution,
						description: risk?.description,
						probability: risk?.probability,
						severeDamage: risk?.severeDamage
					});
				} else {
					rootInFinalData.children!.push({
						primaryKey: risk.risk?.subCategoryPrimaryKey,
						risk: {
							primaryKey: risk.risk?.subCategoryPrimaryKey,
							name: risk.risk?.subCategoryName,
							rootCategoryPrimaryKey: risk.risk?.rootCategoryPrimaryKey,
							rootCategoryName: risk.risk?.rootCategoryName
						},
						children: [
							{
								primaryKey: risk.risk?.primaryKey,
								risk: {
									primaryKey: risk.risk?.primaryKey,
									name: risk.risk?.name,
									rootCategoryPrimaryKey: risk.risk?.rootCategoryPrimaryKey,
									rootCategoryName: risk.risk?.rootCategoryName,
									subCategoryPrimaryKey: risk.risk?.subCategoryPrimaryKey,
									subCategoryName: risk.risk?.subCategoryName
								},
								key: risk.risk?.primaryKey,
								workplacePpe: risk.workplacePpe,
								workplacePrecaution: risk.workplacePrecaution,
								description: risk?.description,
								probability: risk?.probability,
								severeDamage: risk?.severeDamage
							}
						],
						key: risk.risk?.subCategoryPrimaryKey
					});
				}
			} else {
				finalData.push({
					primaryKey: risk.risk?.rootCategoryPrimaryKey,
					risk: {
						primaryKey: risk.risk?.rootCategoryPrimaryKey,
						name: risk.risk?.rootCategoryName
					},
					children: [
						{
							primaryKey: risk.risk?.subCategoryPrimaryKey,
							risk: {
								primaryKey: risk.risk?.subCategoryPrimaryKey,
								name: risk.risk?.subCategoryName
							},
							children: [
								{
									primaryKey: risk.risk?.primaryKey,
									risk: {
										primaryKey: risk.risk?.primaryKey,
										name: risk.risk?.name
									},
									key: risk.risk?.primaryKey,
									workplacePpe: risk.workplacePpe,
									workplacePrecaution: risk.workplacePrecaution,
									description: risk?.description,
									probability: risk?.probability,
									severeDamage: risk?.severeDamage
								}
							],
							key: risk.risk?.subCategoryPrimaryKey
						}
					],
					key: risk.risk?.rootCategoryPrimaryKey
				});
			}
		});

		return finalData;
	}

	private _transformer = (node: Risk, level: number) => {
		return {
			primaryKey: node.primaryKey,
			expandable: !!node.children && node.children.length > 0,
			name: node.risk?.name,
			level: level,
			description: node.description,
			probability: node.probability,
			severeDamage: node.severeDamage,
			risk: node.risk,
			workplacePpe: node.workplacePpe,
			workplacePrecaution: node.workplacePrecaution
		};
	};

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	treeFlattener = new MatTreeFlattener<Risk, ExampleFlatNode>(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.children
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	pushLeaves(riskId: number) {
		this.treeService.toggleNode(riskId);
	}
	shouldRenderActions = (_: number, node: ExampleFlatNode) => node.level !== 2;

	showModal(modalType: string, risk: Risk, event: Event): void {
		if (!this.startedByMe) return;

		event.preventDefault();
		event.stopPropagation();

		this.riskService.setRisk(structuredClone(risk));

		switch (modalType) {
			case 'description':
				this.modalService.open(ModalDescriptionComponent, { centered: true, backdrop: 'static' });
				break;
			case 'hazard':
				this.modalService.open(ModalHazardComponent, { centered: true, backdrop: 'static' });
				break;
		}
	}

	onKey(event: Event, field: string) {
		const value = (event.target as HTMLInputElement)?.value.toLowerCase();

		switch (field) {
			case 'ppe':
				if (value === null && field === 'ppe') return this.ppe;

				this.searchedPpe = this.ppe.filter((option) => option.name.toLowerCase().includes(value));
				break;
			case 'workplacePrecaution':
				if (value === null && field === 'workplacePrecaution') return this.workplacePrecaution;

				this.searchedWorkplacePrecaution = this.workplacePrecaution.filter((option) => option.name.toLowerCase().includes(value));
				break;
			default:
				this.treeSearch = value;
				break;
		}

		return;
	}

	onCheck(value: number, field: string) {
		switch (field) {
			case 'ppe':
				if (!this.tempPpe.includes(value)) this.tempPpe.push(value);
				else this.tempPpe = this.tempPpe.filter((x) => x !== value);
				break;
			case 'workplacePrecaution':
				if (!this.tempWorkplacePrecaution.includes(value)) this.tempWorkplacePrecaution.push(value);
				else this.tempWorkplacePrecaution = this.tempWorkplacePrecaution.filter((x) => x !== value);
				break;
		}
	}

	replaceRiskPpe(risks: Risk[], data: PpeData[] | WorkplacePrecautionData[], field: string) {
		for (const tempRisk of risks) {
			if (tempRisk.risk?.primaryKey === this.riskId) {
				if (field === 'ppe') {
					const newPpe = tempRisk.workplacePpe?.filter((ppe) => data.some((newPpe) => newPpe.primaryKey === ppe.ppe.primaryKey));

					data.forEach((x) => {
						const ppe = newPpe?.find((y) => y.ppe.primaryKey === x.primaryKey);

						if (!ppe) {
							newPpe?.push({
								primaryKey: 0,
								ppe: {
									primaryKey: x.primaryKey,
									name: x.name
								},
								isProvidedByBorrower: false
							} as Ppe);
						}
					});

					tempRisk.workplacePpe = newPpe;
				}
				if (field === 'workplacePrecaution') {
					const newWorkplacePrecaution = tempRisk.workplacePrecaution?.filter((precaution) =>
						data.some((newPrecaution) => newPrecaution.primaryKey === precaution.precaution.primaryKey)
					);

					data.forEach((x) => {
						const precaution = newWorkplacePrecaution?.find((y) => y.precaution.primaryKey === x.primaryKey);

						if (!precaution) {
							newWorkplacePrecaution?.push({
								primaryKey: 0,
								precaution: {
									primaryKey: x.primaryKey,
									name: x.name
								}
							} as WorkplacePrecaution);
						}
					});

					tempRisk.workplacePrecaution = newWorkplacePrecaution;
				}

				return;
			}

			if (tempRisk.children) {
				this.replaceRiskPpe(tempRisk.children, data, field);
			}
		}
	}

	openDrawer(event: Event, drawer: 'ppe' | 'workplacePrecaution' | 'riskFactors', risk?: Risk): void {
		if (!this.startedByMe) return;
		event.stopPropagation();

		if (risk) this.riskId = risk.primaryKey;

		this.drawerType = drawer;
		this.visible = true;

		switch (drawer) {
			case 'ppe':
				this.tempPpe = risk?.workplacePpe?.map((x: Ppe) => x?.ppe.primaryKey) || [];
				break;
			case 'workplacePrecaution':
				this.tempWorkplacePrecaution = risk?.workplacePrecaution?.map((x: WorkplacePrecaution) => x.precaution.primaryKey) || [];
				break;
			case 'riskFactors':
				this.checkedKeys = this.getSelectedRisksId(this.visit?.workplaceRisks || []);
				break;
		}
	}

	closeDrawer(): void {
		this.visible = false;
		this.drawerType = 'ppe';
		this.searchedPpe = this.ppe;
		this.searchedWorkplacePrecaution = this.workplacePrecaution;
		this.treeSearch = '';
	}

	onSubmit() {
		const risks = this.visit?.workplaceRisks || [];

		switch (this.drawerType) {
			case 'ppe':
				const newPpe: PpeData[] = this.ppe.filter((x) => this.tempPpe.includes(x.primaryKey));

				this.replaceRiskPpe(this.visit?.workplaceRisks || [], newPpe, 'ppe');

				this.visitService.setVisit({
					...this.visit,
					hasUnsavedFields: true
				} as Visit);

				this.visible = false;
				this.riskId = null;
				this.tempPpe = [];

				return;
			case 'workplacePrecaution':
				const newWorkplacePrecaution: WorkplacePrecautionData[] = this.workplacePrecaution.filter((x) =>
					this.tempWorkplacePrecaution.includes(x.primaryKey)
				);
				this.replaceRiskPpe(this.visit?.workplaceRisks || [], newWorkplacePrecaution, 'workplacePrecaution');

				this.visitService.setVisit({
					...this.visit,
					hasUnsavedFields: true
				} as Visit);

				this.visible = false;
				this.riskId = null;
				this.tempWorkplacePrecaution = [];

				return;
			case 'riskFactors':
				let newRisks = structuredClone(risks);
				const risksSelectedDrawer = this.nzTreeComponent.getCheckedNodeList();
				const selectedRisksId = this.getAllIds(risksSelectedDrawer, 'key');

				newRisks = newRisks.filter((risk) => selectedRisksId.includes(risk.risk?.primaryKey!));

				risksSelectedDrawer.forEach((node) => {
					if (node.level === 0) {
						this.getSelectedChildrenFromParent(node, newRisks);
					} else if (node.level !== 0) {
						this.getSelectedChildren(node, newRisks);
					}
				});

				this.visitService.setVisit({
					...this.visit,
					workplaceRisks: newRisks,
					hasUnsavedFields: true
				} as Visit);

				break;
		}

		this.visible = false;
		this.drawerType = 'ppe';
	}

	getAllIds(tree: NzTreeNode[], field: string) {
		let ids: number[] = [];

		tree.forEach((node: NzTreeNode) => {
			const f = field as keyof NzTreeNode;
			ids.push(node[f] as number);

			if (Array.isArray(node.children)) {
				ids.push(...this.getAllIds(node.children, field));
			}
		});

		return ids;
	}

	getSelectedChildren(node: NzTreeNode, risks: Risk[]) {
		if (node.level === 2) {
			const isInRisks = risks.find((risk) => risk.risk?.primaryKey === Number(node.key));

			if (!isInRisks) {
				risks.push({
					primaryKey: 0,
					description: '',
					risk: {
						primaryKey: Number(node.key),
						name: node.title,
						rootCategoryPrimaryKey: Number(node.parentNode?.parentNode?.key),
						rootCategoryName: node.parentNode?.parentNode?.title!,
						subCategoryPrimaryKey: Number(node.parentNode?.key),
						subCategoryName: node.parentNode?.title!
					},
					probability: undefined,
					severeDamage: undefined,
					workplacePpe: [],
					workplacePrecaution: []
				});
			}
		} else if (node.level === 1) {
			node.children?.forEach((risk) => {
				const isInRisks = risks.find((x) => x.risk?.primaryKey === Number(risk.key));

				if (!isInRisks) {
					risks.push({
						primaryKey: 0,
						description: '',
						risk: {
							primaryKey: Number(risk.key),
							name: risk.title,
							rootCategoryPrimaryKey: Number(node.parentNode?.key),
							rootCategoryName: node.parentNode?.title!,
							subCategoryPrimaryKey: Number(node.key),
							subCategoryName: node.title
						},
						probability: undefined,
						severeDamage: undefined,
						workplacePpe: [],
						workplacePrecaution: []
					});
				}
			});
		}
	}

	getSelectedChildrenFromParent(node: NzTreeNode, risks: Risk[]) {
		node.children?.forEach((subCategory) => {
			subCategory.children?.forEach((risk) => {
				const isInRisks = risks.find((x) => x.risk?.primaryKey === Number(risk.key));

				if (!isInRisks) {
					risks.push({
						primaryKey: 0,
						description: '',
						risk: {
							primaryKey: Number(risk.key),
							name: risk.title,
							rootCategoryPrimaryKey: Number(node.key),
							rootCategoryName: node.title,
							subCategoryPrimaryKey: Number(subCategory.key),
							subCategoryName: subCategory.title
						},
						probability: undefined,
						severeDamage: undefined,
						workplacePpe: [],
						workplacePrecaution: []
					});
				}
			});
		});
	}

	getSelectedRisksId(arr: Risk[]): number[] {
		return arr.map((risk) => risk.risk?.primaryKey!);
	}

	removeRisk(event: Event, risk: Risk) {
		if (!this.startedByMe) return;
		event.stopPropagation();

		const visit = this.visitService.getStoredVisit();
		const newRisks = visit?.workplaceRisks?.filter((x) => x.risk?.primaryKey !== risk.primaryKey);

		this.visitService.setVisit({
			...visit,
			workplaceRisks: newRisks,
			hasUnsavedFields: true
		} as Visit);
	}
}
