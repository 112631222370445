import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { User, UserService } from 'src/app/services/user.service';
import { Visit, VisitService } from 'src/app/services/visit.service';
import { DateTime } from 'luxon';

@Component({
	standalone: true,
	selector: 'app-visit-table',
	templateUrl: './visit-table.component.html',
	styleUrls: ['./visit-table.component.scss'],
	imports: [MatTableModule, CommonModule, TranslatePipe]
})
export class VisitTableComponent {
	user: User | null;
	@Input() data: Visit[] = [];
	@Input() type: string = '';
	displayedColumns: string[];

	constructor(private userService: UserService, public router: Router, private visitService: VisitService) {
		this.user = this.userService.getUser();
		this.displayedColumns = ['started', 'customer', 'dueDate', 'dateDiff'];
		this.data = this.visitService.getStoredVisits();
	}

	gotoVisit(visit: Visit) {
		this.visitService.setVisit(visit);
		this.router.navigate([`/${this.type}`, visit.id]);
	}

	formatDate(date: string) {
		return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
	}
}
