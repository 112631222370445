<div class="tj-additional-information">
	<div class="tj-accordion">
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(0)" (opened)="onPaneClick(0)"
				(closed)="onPaneClick(0)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'detectedDangers' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': visit?.detectedDangers || false,
								'tj-rotate-180': step.includes(0)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field id="detected_dangers" class="tj-fill-container tj-accordion-input" appearance="fill"
					hideRequiredMarker subscriptSizing="dynamic">
					<textarea type="text" matInput class="tj-textarea" [formControl]="detectedDangers"
						[placeholder]="'value' | translate" (blur)="setVisit('customInformation.detectedDangersAdditionalActivities')" cdkTextareaAutosize
						maxRows="4" [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(1)" (opened)="onPaneClick(1)"
				(closed)="onPaneClick(1)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'securityQuestions' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': questions.length > 0 || false,
								'tj-rotate-180': step.includes(1)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="tj-additional-information-wrapper" style="border: 0;">
					<div class="tj-additional-information-header">
						<div class="tj-additional-information-header-description"></div>
						<div class="tj-additional-information-header-checkbox"> {{ 'yes' | translate }} </div>
						<div class="tj-additional-information-checkbox"> {{ 'no' | translate }} </div>
					</div>
				</div>

				<div class="tj-additional-information-wrapper" *ngFor="let question of questions">
					<div class="tj-additional-information-row">
						<div class="tj-additional-information-description">{{ question.securityQuestion.question }}</div>
						<div class="tj-additional-information-checkbox">
							<mat-checkbox [checked]="question.isApplicable === true"
								(change)="onCheckChange($event, question.securityQuestion.primaryKey)" name="yes"
								disableRipple></mat-checkbox>
						</div>
						<div class="tj-additional-information-checkbox">
							<mat-checkbox [checked]="question.isApplicable === false"
								(change)="onCheckChange($event, question.securityQuestion.primaryKey)" name="no"
								disableRipple></mat-checkbox>
						</div>
					</div>
					<div *ngIf="question.securityQuestion.isAnswerTextSupported">
						<label class="tj-accordion-label tj-adjust-label-top" style="margin: 0" [htmlFor]="question.securityQuestion.primaryKey">{{ 'answer' |
							translate }}</label>
						<div class="tj-additional-information-row tj-no-borders">
							<mat-form-field [id]="question.securityQuestion.primaryKey" class="tj-fill-container tj-accordion-input" appearance="fill"
								hideRequiredMarker subscriptSizing="dynamic">
								<textarea type="text" matInput class="tj-textarea" value="{{question.answerText}}"
									[placeholder]="'value' | translate" (blur)="setAnswer($event, question.securityQuestion.primaryKey)" cdkTextareaAutosize
									maxRows="4" [readonly]="!startedByMe"></textarea>
							</mat-form-field>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(2)" (opened)="onPaneClick(2)"
				(closed)="onPaneClick(2)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'notes' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': activityShortNote.value || false,
								'tj-rotate-180': step.includes(2)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field id="detected_dangers" class="tj-fill-container tj-accordion-input" appearance="fill"
					hideRequiredMarker subscriptSizing="dynamic">
					<textarea type="text" matInput class="tj-textarea" [formControl]="activityShortNote"
						[placeholder]="'value' | translate" (blur)="setVisit('activityShortNote')" cdkTextareaAutosize
						maxRows="4" [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(3)" (opened)="onPaneClick(3)"
				(closed)="onPaneClick(3)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'shortSummary' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': shortSummary.value || false,
								'tj-rotate-180': step.includes(3)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field id="detected_dangers" class="tj-fill-container tj-accordion-input" appearance="fill"
					hideRequiredMarker subscriptSizing="dynamic">
					<textarea type="text" matInput class="tj-textarea" [formControl]="shortSummary"
						[placeholder]="'value' | translate" (blur)="setVisit('activityInterviewSummary')" cdkTextareaAutosize
						maxRows="4" [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(4)" (opened)="onPaneClick(4)"
				(closed)="onPaneClick(4)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'remarks' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': remarks.value || false,
								'tj-rotate-180': step.includes(4)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field id="detected_dangers" class="tj-fill-container tj-accordion-input" appearance="fill"
					hideRequiredMarker subscriptSizing="dynamic">
					<textarea type="text" matInput class="tj-textarea" [formControl]="remarks"
						[placeholder]="'value' | translate" (blur)="setVisit('customInformation.notes')" cdkTextareaAutosize
						maxRows="4" [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
