import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { User, UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
	standalone: true,
	selector: 'app-login-callback',
	templateUrl: './login-callback.component.html',
	styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent {
	constructor(
		public router: Router,
		private oidcSecurityService: OidcSecurityService,
		private userService: UserService,
		private authService: AuthService
	) {}

	async ngOnInit() {
		try {
			if (await firstValueFrom(this.oidcSecurityService.isAuthenticated()))
				return;

			this.oidcSecurityService.checkAuth().subscribe(async (result) => {
				if (result) {
					const { userData, isAuthenticated } = result;
					if (isAuthenticated) {
						this.userService.setUser({ ...userData, language: this.userService.getUser()?.language || 'de' } as User);
						this.router.navigate(['/home']);
					} else {
						await this.authService.logout();
						 this.router.navigate(['/auth', { logout: false }]);
					}
				}
			});
		} catch (error) {
			console.warn('No user data or valid access token found. Redirecting to login page.');
		}
	}
}
