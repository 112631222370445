import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, firstValueFrom } from 'rxjs';
import { EquipmentData, QualificationData } from '../views/visit/sub-components/work-system/types';
import { WorkplacePrecautionData, PpeData, RiskData } from '../views/visit/sub-components/risks-evaluated/types';
import { DamageSeverityCatalogueData } from '../views/visit/sub-components/risks-evaluated/sub-components/modal-hazard/types';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

export interface Equipment {
	primaryKey: number;
	toolsAndEquipment: {
		primaryKey: number;
		name: string;
	};
	technicalSafetyMeasures?: { primaryKey: number; name: string }[];
}

export interface Qualification {
	primaryKey: number;
	name: string;
}

export interface Ppe {
	primaryKey: number;
	ppe: {
		primaryKey: number;
		name: string;
	};
	isProvidedByBorrower: boolean;
}

export interface WorkplacePrecaution {
	primaryKey: number | null;
	precaution: {
		primaryKey: number;
		name: string;
	};
	isProvidedByBorrower: boolean;
}

export interface Risk {
	primaryKey?: number;
	description?: string;
	probability?: {
		primaryKey: number;
		name: string;
		value: number;
	};
	severeDamage?: {
		primaryKey: number;
		name: string;
		value: number;
	};
	risk: RiskData;
	workplacePpe?: Ppe[];
	workplacePrecaution?: WorkplacePrecaution[];
	children?: Risk[];
}

export interface OrganizationalMeasure {
	primaryKey: number;
	name: string;
}

export interface Prevention {
	primaryKey: number;
	prevention: {
		primaryKey: number;
		name: string;
		rootCategoryName: string;
		rootCategoryPrimaryKey: number;
	};
	isApplicable: boolean | null;
	note: string;
	children?: Prevention[];
}

export interface PreventionDialogData{
	notApplicablePreventionsWithoutReason: notApplicablePreventionWithoutReason[]
}

export interface notApplicablePreventionWithoutReason{
	name: string;
	isRootCategoryName: boolean;
}

export interface CustomInformation {
	primaryKey: number;
	value: string;
}

export interface SecurityQuestion {
	primaryKey: number;
	securityQuestion: {
		primaryKey: number;
		question: string;
		isAnswerTextSupported: boolean;
	};
	isApplicable: boolean | null;
	answerText?: string;
}

export interface Absent {
	id: string;
	title: string;
	name: string;
	present: boolean | null;
	ppe: boolean | null;
}

export interface Absents {
	dangers: Absent[];
}

export interface NearAccident {
	firstAidFacilities: boolean;
	customerInstructions: boolean;
	name?: string;
	dangerousWork: boolean;
	dangerousMaterials: boolean;
	safetyComplience: boolean;
	recognizedDangersEmployee: boolean;
	recognizedDangersCustomer: boolean;
	eliminatingDangers: boolean;
	noExtraWork: boolean;
	noAssignmentWithoutApproval: boolean;
	requiresNewMedicalMeasures: boolean;
}

export interface Person {
	primaryKey: number;
	name: string;
	phoneNumber: string;
}

export interface Employee {
	employeePrimaryKey: number;
	employeeName: string;
	isPresent: boolean | null;
	hasPpeBeenWorn: boolean | null;
}

export interface Reason {
	primaryKey: number;
	name: string;
}

interface ResponseBody {
	value: Visit[];
}

export interface Visit {
	// NEW INTERFACE

	id: string;
	status: string;
	executedByUserName?: string;
	executedByUserId?: string;
	submitDate?: string;
	dueDate?: string;
	dateDiff?: number;
	activityShortNote?: CustomInformation;
	activityInterviewSummary?: CustomInformation;
	workplace: {
		contactPersonList?: Person[];
		cplSafetyExpert?: Person;
		cplFirstAider?: Person;
		cplCompanyDoctor?: Person;
		cplSafetyOfficer?: Person;
		cplInstructorDrivingAssignment?: Person;
		cplInstructor?: Person;
		cplContactPerson?: Person;
		cplProtectionAgainstSabotage?: Person;
		cplSecrecyEconomy?: Person;
		cplWeldingSupervisor?: Person;
		cplRadiationProtection?: Person;
		cplAirCargo?: Person;

		workplaceOrganisationalMeasures?: OrganizationalMeasure[];

		workplaceName?: string;
		workArea?: string;
		riskScore?: number;
		workplaceEnvironment?: string;
		workplaceAssignment?: string;
		workDescription?: string;
		input?: string;
		output?: string;
		workplaceToolsAndEquipment?: Equipment[];
		workingMaterials?: string;
		toolsAndEquipmentNotes?: string;
		workplaceProfessions?: Qualification[];
		neededQualifications?: string;
		isOnsiteMvSupervisor?: boolean;
		isDangerousWorkplace?: boolean;

		specificFeatures?: string;
		healthPromotion?: string;
	};
	workplaceRisks: Risk[];
	workplacePreventions: Prevention[];
	workplaceEmployees: Employee[];
	customer?: string;
	customInformation: {
		detectedDangersAdditionalActivities?: CustomInformation;
		notes?: CustomInformation;
	};
	workplaceSecurityQuestions: SecurityQuestion[];
	riskEvaluationScore?: string;
	riskEvaluationReason?: {
		primaryKey: number;
		name: string;
	};
	riskEvaluationResult?: {
		primaryKey: number;
		name: string;
	};
	hasUnsavedFields?: boolean;

	// OLD INTERFACE
	// Present and absent staff
	staff?: Absents;
	// Additional information
	detectedDangers?: string;
	// Send gfb
}

@Injectable({ providedIn: 'root' })
export class VisitService {
	visit: Visit | null = null;
	public visitSubject = new Subject<Visit | null>();
	token: any;

	call() {
		this.visitSubject.next(this.visit);
		return this.visitSubject;
	}

	constructor(private http: HttpClient) {
		this.visit = localStorage.getItem('visit') ? JSON.parse(localStorage.getItem('visit') || '{}') : null;
	}

	async getVisits() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const [responseBto, responseGfb] = await Promise.all([
				firstValueFrom(this.http.get(`${environment.apiUrl}/Bto/btoOverview`, { headers, observe: 'response' })),
				firstValueFrom(this.http.get(`${environment.apiUrl}/Gfb/gfbOverview`, { headers, observe: 'response' }))
			]);

			if (responseBto?.status === 200 && responseGfb?.status === 200) {
				const bto: Visit[] = (responseBto?.body as ResponseBody).value || [];
				const gfb: Visit[] = (responseGfb?.body as ResponseBody).value || [];

				this.setStoredVisits({ bto, gfb });

				return { bto, gfb };
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the visit');
	}

	setStoredVisits = ({ bto = [], gfb = [] }: { bto: Visit[]; gfb: Visit[] }) => {
		localStorage.setItem('visits', JSON.stringify({ bto, gfb }));
	};

	getStoredVisits = () => {
		return JSON.parse(localStorage.getItem('visits') || '{ "bto": [], "gfb": [] }');
	};

	async getVisit(id: string) {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(this.http.get(`${environment.apiUrl}/visits/${id}`, { headers, observe: 'response' }));

			if (response.status === 200) {
				const visit: Visit = (response.body as any).value || {};
				this.setVisit(visit);

				return visit;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the visit');
	}

	setVisit = (visit: Visit) => {
		localStorage.setItem('visit', JSON.stringify(visit));
		this.visit = visit;
		this.visitSubject.next(this.visit);
	};

	getStoredVisit = () => {
		return this.visit;
	};

	clearVisit = () => {
		this.visit = null;
		localStorage.removeItem('visit');
		this.visitSubject.next(this.visit);
	};

	startVisit = async (date: string) => {
		const visit = this.visit;
		if (!visit) return;

		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.patch(`${environment.apiUrl}/bto/${visit.id}/start?submitDate=${date}`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while starting the visit');
	};
	saveVisit = async (visit: Visit) => {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.put(`${environment.apiUrl}/bto/${visit.id}`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while saving the visit');
	};

	sendVisit = async (visit: Visit) => {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.patch(`${environment.apiUrl}/bto/${visit.id}/finish`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while sending the visit');
	};

	cancelVisit = async (visit: Visit) => {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.patch(`${environment.apiUrl}/bto/${visit.id}/cancel`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while canceling the visit');
	};

	startAssessment = async (date: string) => {
		const visit = this.visit;
		if (!visit) return;

		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.patch(`${environment.apiUrl}/gfb/${visit.id}/start?submitDate=${date}`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while starting the assessment');
	};

	saveAssessment = async (visit: Visit) => {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.put(`${environment.apiUrl}/gfb/${visit.id}`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while saving the assessment');
	};

	sendAssessment = async (visit: Visit) => {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.patch(`${environment.apiUrl}/gfb/${visit.id}/finish`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while sending the assessment');
	};

	cancelAssessment = async (visit: Visit) => {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.patch(`${environment.apiUrl}/gfb/${visit.id}/cancel`, visit, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				return response.body as any;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while canceling the assessment');
	};

	async getEquipments(continuationToken?: string, searchString?: string) {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			let response: HttpResponse<Object>;
			if(searchString == null || searchString == ""){
					response = await firstValueFrom(
					this.http.get(`${environment.apiUrl}/ArbeitsUndBetriebsmittelKatalog?continuationToken=${continuationToken}`, {
						headers,
						observe: 'response'
					})
				);
			}
			else{
				let params = new HttpParams().set('$filter', "contains(tolower(name),\'" + searchString + "\')");
				if(continuationToken)
				{
					params = params.set("continuationToken", continuationToken);
				}
				response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/ArbeitsUndBetriebsmittelKatalog?`, {
					headers,
					params,
					observe: 'response'
				}));
			}
			if (response.status === 200) {
				const equipments: EquipmentData[] = (response.body as any).value || [];
				const continuationToken = (response.body as any).continuationToken || null;
				return { equipments, continuationToken };
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the equipments');
	}

	async getTechnicalSafetyMeasures(continuationToken?: string, searchString?: string) {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			let response: HttpResponse<Object>;
			if(searchString == null || searchString == ""){
				response = await firstValueFrom(
					this.http.get(`${environment.apiUrl}/SicherheitstechnischeMassnahmeKatalog?continuationToken=${continuationToken}`, { 
						headers,
						observe: 'response' 
					})
				);
			}
			else{
				let params = new HttpParams().set('$filter', "contains(tolower(name),\'" + searchString + "\')");
				if(continuationToken)
				{
					params = params.set("continuationToken", continuationToken);
				}
				response = await firstValueFrom(
					this.http.get(`${environment.apiUrl}/SicherheitstechnischeMassnahmeKatalog?`, {
						headers,
						params,
						observe: 'response'
						})
					);
			}
			if (response.status === 200) {
				const technicalSafetyMeasures: EquipmentData[] = (response.body as any).value || [];
				const continuationToken = (response.body as any).continuationToken || null;
				return {technicalSafetyMeasures, continuationToken};
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the technical safety measures');
	}

	async getQualifications(continuationToken?: string, searchString?: string) {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			let response: HttpResponse<Object>;
			if(searchString == null || searchString == "")
			{
				response = await firstValueFrom(
					this.http.get(`${environment.apiUrl}/BerufeKatalog?continuationToken=${continuationToken}`, {
						headers,
						observe: 'response'
					})
				);
			}
			else
			{
				let params = new HttpParams().set('$filter', "contains(tolower(name),\'" + searchString + "\')");
				if(continuationToken)
				{
					params = params.set("continuationToken", continuationToken);
				}
				response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/BerufeKatalog?`, {
					headers,
					params,
					observe: 'response'
					})
				);

			}
			if (response.status === 200) {
				const qualifications: QualificationData[] = (response.body as any).value || [];
				const continuationToken = (response.body as any).continuationToken || null;
				return { qualifications, continuationToken };
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the qualifications');
	}

	async getRisks() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/GefaehrdungKatalog?$top=1000`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const risks: RiskData[] = (response.body as any).value || [];
				return risks;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the qualifications');
	}

	async getPpe() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/PsaKatalog?$top=1000`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const ppe: PpeData[] = (response.body as any).value || [];
				return ppe;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the ppe');
	}

	async getWorkplacePrecaution() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/ArbeitsmedizinKatalog?$top=1000`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const workplacePrecautionData: WorkplacePrecautionData[] = (response.body as any).value || [];
				return workplacePrecautionData;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the workplace precaution');
	}

	async getDamageProbabilityCatalogue() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/EintrittswahrscheinlichkeitKatalog`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const damageProbabilityCatalogue: any[] = (response.body as any).value || [];
				return damageProbabilityCatalogue;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the probability catalogue');
	}

	async getDamageSeverityCatalogue() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/SchadensschwereKatalog`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const damageSeverityCatalogue: DamageSeverityCatalogueData[] = (response.body as any).value || [];
				return damageSeverityCatalogue;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the severity catalogue');
	}

	async getOrganizationalMeasures(continuationToken?: string, searchString?: string) {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			let response: HttpResponse<Object>;
			if(searchString == null || searchString == ""){
				response = await firstValueFrom(
					this.http.get(`${environment.apiUrl}/OrganisatorischeMassnahmenKatalog?continuationToken=${continuationToken}`, { 
						headers, 
						observe: 'response' 
					})
				);
			}
			else{
				let params = new HttpParams().set('$filter', "contains(tolower(name),\'" + searchString + "\')");
				if(continuationToken)
				{
					params = params.set("continuationToken", continuationToken);
				}
				response = await firstValueFrom(
					this.http.get(`${environment.apiUrl}/OrganisatorischeMassnahmenKatalog?`, {
						headers,
						params,
						observe: 'response'
					})
				);
			}
			if (response.status === 200) {
				const organizationalMeasures: OrganizationalMeasure[] = (response.body as any).value || [];
				const continuationToken = (response.body as any).continuationToken || null;
				return {organizationalMeasures, continuationToken};
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the organizational measures');
	}

	async getPreventions() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/PraeventionKatalog`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const preventions: Prevention[] = (response.body as any).value || [];
				return preventions;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the preventions');
	}

	async getFieldEvaluation() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/GefaerdungsbeurteilungsergebnisseKatalog`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const fieldEvaluation: Reason[] = (response.body as any).value || [];
				return fieldEvaluation;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the evaluations');
	}

	async getFieldReason() {
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		try {
			const response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/GefaerdungsbeurteilungsgruendeKatalog`, { headers, observe: 'response' })
			);

			if (response.status === 200) {
				const fieldReason: Reason[] = (response.body as any).value || [];
				return fieldReason;
			}
		} catch (error: any) {
			throw Error(error.message);
		}

		throw Error('An error occurred while getting the reasons');
	}

	// mock data
	async getMockVisits(): Promise<{ bto: Visit[]; gfb: Visit[] }> {
		return new Promise((resolve, reject) => {
			const visits: { bto: Visit[]; gfb: Visit[] } = { bto: [], gfb: [] };

			fetch('assets/mock/visits.json')
				.then((response) => response.json())
				.then((data) => {
					visits.bto = data.bto;
					visits.gfb = data.gfb;

					resolve(visits);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	async getMockVisit(id: string): Promise<Visit> {
		return new Promise((resolve, reject) => {
			fetch('assets/mock/visits.json')
				.then((response) => response.json())
				.then((data) => {
					const visit: Visit =
						data.bto.find((visit: Visit) => visit.id === id) || data.gfb.find((visit: Visit) => visit.id === id);
					if (!visit) reject('Visit not found');
					resolve(visit);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}
