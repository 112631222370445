<div class="tj-visit">
	<div class="tj-visit-header" [class]="scrolled ? 'tj-header__adjustment' : ''">
		<div class="tj-header-navigation" *ngIf="device !== 'desktop'">
			<button class="tj-header-navigation-button" (click)="goHome()">
				<i class="moon-arrow"></i>
			</button>
		</div>
		<h1>{{ visit?.customer }} - {{ visit?.workplace?.workplaceName }}</h1>
		<div class="tj-visit-sub-header__details" *ngIf="scrolled && device === 'desktop'">
			<div class="tj-visit-sub-header__date">
				<span>{{ 'date' | translate }}</span>
				<span>{{ formattedDate }}</span>
			</div>
			<div class="tj-visit-sub-header__risk">
				<span>{{ 'riskAssessment' | translate }}</span>
				<span>{{ visit?.workplace?.riskScore }}</span>
			</div>
		</div>
		<div class="tj-visit-header__actions"
			*ngIf="device === 'desktop' && visit?.status !== 'StartRequested' && visit?.status !== 'Finished'">
			<button class="tj-btn tj-btn__danger"
				*ngIf="visit?.status === 'Started' && startedByMe && visitType==='bto'"
				(click)="showCancelModal($event)">
				<i class="moon-cross-filled"></i>
				<span *ngIf="!scrolled">{{ 'startVisitNewBto' | translate }}</span>
			</button>
			<button class="tj-btn tj-btn__danger"
				*ngIf="visit?.status === 'Started' && startedByMe && visitType==='gfb'"
				(click)="showCancelModal($event)">
				<i class="moon-cross-filled"></i>
				<span *ngIf="!scrolled">{{ 'startVisitNewGfb' | translate }}</span>
			</button>
			<button class="tj-btn tj-btn__success" [disabled]="!hasUnsavedFields"
				*ngIf="visit?.status === 'Started' && startedByMe && visitType === 'bto'" (click)="saveVisit()">
				<i class="moon-check-filled"></i>
				<span *ngIf="!scrolled">{{ 'saveVisitBto' | translate }}</span>
			</button>
			<button class="tj-btn tj-btn__success" [disabled]="!hasUnsavedFields"
				*ngIf="visit?.status === 'Started' && startedByMe && visitType === 'gfb'" (click)="saveVisit()">
				<i class="moon-check-filled"></i>
				<span *ngIf="!scrolled">{{ 'saveVisitGfb' | translate }}</span>
			</button>
			<button class="tj-btn tj-btn__primary" [disabled]="hasUnsavedFields"
				*ngIf="visit?.status === 'Started' && startedByMe && visitType === 'bto'"
				(click)="showSendModal($event)">
				<i class="moon-plane-filled"></i>
				<span *ngIf="!scrolled">{{ 'sendVisitBto' | translate}}</span>
			</button>
			<button class="tj-btn tj-btn__primary" [disabled]="hasUnsavedFields"
				*ngIf="visit?.status === 'Started' && startedByMe && visitType === 'gfb'"
				(click)="showSendModal($event)">
				<i class="moon-plane-filled"></i>
				<span *ngIf="!scrolled">{{ 'sendVisitGfb' | translate}}</span>
			</button>
			<button class="tj-btn tj-btn__success" *ngIf="visit?.status !== 'Started' && visitType === 'bto'"
				(click)="showStartModal($event)">
				<i class="moon-play-filled"></i>
				<span *ngIf="!scrolled">{{ 'startVisitBto' | translate }}</span>
			</button>
			<button class="tj-btn tj-btn__success" *ngIf="visit?.status !== 'Started' && visitType === 'gfb'"
				(click)="showStartModal($event)">
				<i class="moon-play-filled"></i>
				<span *ngIf="!scrolled">{{ 'startAssessment' | translate }}</span>
			</button>
			<div class="tj-visit-alerts" *ngIf="visit?.status === 'Started' && scrolled && !startedByMe">
				<div class="tj-alert tj-alert__warning tj-alert__fullwidth">
					<i class="moon-danger-filled"></i>
					<span>{{ 'visitStartedBy' | translate }}</span>
					<span>{{ visit?.executedByUserName }}</span>
				</div>
			</div>
			<div class="tj-visit-alerts" *ngIf="visit?.status === 'StartRequested' && scrolled && !startedByMe">
				<div class="tj-alert tj-alert__warning tj-alert__fullwidth">
					<i class="moon-danger-filled"></i>
					<span>{{ 'pendingStart' | translate }}</span>
				</div>
			</div>
			<div class="tj-visit-alerts" *ngIf="visit?.status === 'Finished' && scrolled">
				<div class="tj-alert tj-alert__success tj-alert__fullwidth">
					<i class="moon-danger-filled"></i>
					<span>{{ 'visitFinished' | translate }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="tj-visit-sub-header" [class]="device === 'desktop' && !scrolled ? 'tj-hidden' : ''">
		<div class="tj-visit-sub-header__details">
			<div class="tj-visit-sub-header__date">
				<span>{{ 'date' | translate }}</span>
				<span>{{ formattedDate }}</span>
			</div>
			<div class="tj-visit-sub-header__risk">
				<span>{{ 'riskAssessment' | translate }}</span>
				<span>{{ visit?.workplace?.riskScore }}</span>
			</div>
		</div>
		<div class="tj-visit-alerts" *ngIf="visit?.status === 'Started' && !scrolled && !startedByMe">
			<div class="tj-alert tj-alert__warning tj-alert__fullwidth">
				<i class="moon-danger-filled"></i>
				<div [class]="device === 'desktop' ? 'tj-alert__desktop' : 'tj-alert__mobile'">
					<span>{{ 'visitStartedBy' | translate }}</span>
					<span>{{ visit?.executedByUserName }}</span>
				</div>
			</div>
		</div>
		<div class="tj-visit-alerts" *ngIf="!scrolled && visit?.status === 'StartRequested'">
			<div class="tj-alert tj-alert__warning tj-alert__fullwidth">
				<i class="moon-danger-filled"></i>
				<div [class]="device === 'desktop' ? 'tj-alert__desktop' : 'tj-alert__mobile'">
					<span>{{ 'pendingStart' | translate }}</span>
				</div>
			</div>
		</div>
		<div class="tj-visit-alerts" *ngIf="!scrolled && visit?.status === 'Finished'">
			<div class="tj-alert tj-alert__success tj-alert__fullwidth">
				<i class="moon-danger-filled"></i>
				<div [class]="device === 'desktop' ? 'tj-alert__desktop' : 'tj-alert__mobile'">
					<span>{{ 'visitFinished' | translate }}</span>
				</div>
			</div>
		</div>
	</div>
	<mat-tab-group class="tj-visit-tabs" mat-stretch-tabs="false" dynamicHeight animationDuration="0ms"
		(selectedTabChange)="changeTab($event)">
		<mat-tab>
			<ng-template mat-tab-label>
				<span>{{ 'workSystem' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-work-system></app-work-system>
			</div>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>{{ 'specificCharacteristics' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-specific-characteristics></app-specific-characteristics>
			</div>
		</mat-tab>
		<mat-tab label="risks">
			<ng-template mat-tab-label>
				<span>{{ 'risksEvaluated' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-risks-evaluated></app-risks-evaluated>
			</div>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>{{ 'workSiteMeasures' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-worksite-measures></app-worksite-measures>
			</div>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>{{ 'prevention' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-prevention></app-prevention>
			</div>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>{{ 'personalMeasures' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-personal-measures></app-personal-measures>
			</div>
		</mat-tab>
		<mat-tab *ngIf="visitType === 'bto'">
			<ng-template mat-tab-label>
				<span>{{ 'btoCheck' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-additional-information></app-additional-information>
			</div>
		</mat-tab>
		<mat-tab *ngIf="visitType === 'bto'">
			<ng-template mat-tab-label>
				<span>{{ 'presentAndAbsentStaff' | translate }}</span>
			</ng-template>
			<div class="tj-visit-tab">
				<app-present-and-absent-staff></app-present-and-absent-staff>
			</div>
		</mat-tab>
	</mat-tab-group>
	<div class="tj-visit-floating-actions" *ngIf="device !== 'desktop'">
		<div class="tj-visit-floating-menu" [class]="menuOpened ? 'tj-menu-opened' : ''">
			<button class="tj-visit-floating-menu-item" *ngIf="startedByMe" (click)="onMenuToggle()">
				<i [class]="menuOpened ? 'moon-cross' : 'moon-burger-bar-alt'"></i>
			</button>
			<button class="tj-visit-floating-menu-item-play" *ngIf="visit?.status !== 'Started'"
				(click)="showStartModal($event)">
				<i class="moon-play-filled"></i>
			</button>
			<button class="tj-visit-floating-menu-item-cancel" *ngIf="startedByMe" (click)="showCancelModal($event)">
				<i class="moon-cross-filled"></i>
			</button>
			<button class="tj-visit-floating-menu-item-save" [disabled]="!hasUnsavedFields" *ngIf="startedByMe"
				(click)="saveVisit()">
				<i class="moon-check-filled"></i>
			</button>
			<button class="tj-visit-floating-menu-item-send" [disabled]="hasUnsavedFields" *ngIf="startedByMe"
				(click)="showSendModal($event)">
				<i class="moon-plane-filled"></i>
			</button>
		</div>
	</div>
</div>