<div class="tj-modal-header">
	<h4 class="tj-modal-header-title">{{ 'dangerClass' | translate }}</h4>
</div>
<div class="tj-modal-body tj-risk-hazard-modal">
	<div>
		<div class="tj-select-wrapper">
			<p>{{ 'probability' | translate }}</p>
			<mat-form-field class="tj-fill-container tj-modal-input" appearance="fill" hideRequiredMarker
				subscriptSizing="dynamic" style="max-width: 175px;">
				<mat-select name="probability" [formControl]="probability" [placeholder]="'selectRisk' | translate ">
					<mat-option *ngFor="let item of scaleProbability" [value]="item.primaryKey">
						{{item.viewValue}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="tj-select-wrapper">
			<p>{{ 'damageSeverity' | translate }}</p>
			<mat-form-field class="tj-fill-container tj-modal-input" appearance="fill" hideRequiredMarker
				subscriptSizing="dynamic" style="max-width: 175px;">
				<mat-select name="severity" [formControl]="severity" [placeholder]="'selectRisk' | translate">
					<mat-option *ngFor="let item of scaleSevereDamage" [value]="item.primaryKey">
						{{item.viewValue}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</div>
<div class="tj-modal-footer">
	<button type="button" class="tj-btn tj-btn__danger" (click)="leave()">{{ 'cancel' | translate }}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus>{{ 'ok' | translate }}</button>
</div>
