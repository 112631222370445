import { HttpResponse, HttpClient, HttpParams, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface User {
	company?: string;
	email?: string;
	name: string;
	phone?: string;
	photo?: string;
	id: number;
	sub?: string;
	createdAt?: string;
	updatedAt?: string;
	language?: 'en' | 'de';
}

@Injectable({ providedIn: 'root' })
export class UserService {
	user: User | null = null;
	public userSubject = new Subject<User | null>();

	call() {
		this.userSubject.next(this.user);
		return this.userSubject;
	}

	constructor(private http: HttpClient) {
		this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;
	}

	setUser = (user: User) => {
		localStorage.setItem('user', JSON.stringify(user));
		this.user = user;
		this.userSubject.next(this.user);
	};

	getUser = () => {
		return this.user;
	};

	clearUser = () => {
		this.user = null;
		localStorage.removeItem('user');
		this.userSubject.next(this.user);
	};

	setLanguage = async (language: 'en' | 'de') => {
		localStorage.setItem('language', language);
	};

	getLanguage = () => {
		return localStorage.getItem('language') || 'de';
	};

	clearLanguage = () => {
		localStorage.removeItem('language');
	};

	async getMainOfficeName(){
		const headers = {
			'accept-language': 'en',
			'content-type': 'application/json',
			source: 'bto'
		};

		let response: HttpResponse<Object>;
		try {
			response = await firstValueFrom(
				this.http.get(`${environment.apiUrl}/User/management/hauptgeschaeftsstelle`, {
					headers,
					observe: 'response'
				})
			);
			if (response.status === 200) {
				const mainOfficeName: string = (response.body as any).value || "Unknown Main Office"
				return mainOfficeName;
			}
			else{
				throw Error('An error occurred while getting the main office name');
			}
		} catch (error) {
			throw Error('An error occurred while getting the main office name');
		}
	}
}
