import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Prevention, Visit, VisitService } from 'src/app/services/visit.service';
import { UserService } from 'src/app/services/user.service';
import { PreventionService } from '../../services/prevention.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalDescriptionComponent } from './sub-components/modal-description/modal-description.component';
import { NzTreeModule, NzTreeNode } from 'ng-zorro-antd/tree';
import { NzTreeComponent } from 'ng-zorro-antd/tree';
import { TreeService } from 'src/app/services/tree.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Subscription } from 'rxjs';
import { PreventionTableData } from './type';

interface ExampleFlatNode {
	primaryKey: number;
	prevention?: {
		primaryKey: number;
		name: string;
		rootCategoryName: string;
		rootCategoryPrimaryKey: number;
	};
	isApplicable: boolean | null;
	note: string;
	expandable: boolean;
	level: number;
}

@Component({
	standalone: true,
	selector: 'app-prevention',
	templateUrl: './prevention.component.html',
	styleUrls: ['./prevention.component.scss'],
	imports: [
		CommonModule,
		MatExpansionModule,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatCheckboxModule,
		TranslatePipe,
		NzTreeModule,
		MatTreeModule
	]
})
export class PreventionComponent implements OnInit, OnDestroy {
	step: number[] = [];
	device: 'desktop' | 'mobile' = 'desktop';
	visible: boolean = false;
	drawerType: string = '';
	startedByMe: boolean = false;
	visitServiceSubscription: Subscription | null = null;
	treeServiceSubscription: Subscription | null = null;
	workOrganizationFilled: boolean = false;
	firstAidFilled: boolean = false;
	modal: NgbModalRef | null = null;
	visit: Visit | null = null;
	expandedNodesId: number[] = [];

	@ViewChild('nzTreeComponent', { static: false })
	nzTreeComponent!: NzTreeComponent;

	checkedKeys: number[] = [];
	preventionsList: any = [];
	treeSearch = '';

	constructor(
		private userService: UserService,
		private visitService: VisitService,
		private preventionService: PreventionService,
		private modalService: NgbModal,
		private treeService: TreeService,
	) {
		const visit = this.visitService.getStoredVisit();
		this.visit = visit;
		this.device = window.innerWidth <= 768 ? 'mobile' : 'desktop';
		this.startedByMe = visit?.executedByUserId === this.userService.getUser()?.sub && this.visit?.status !== 'StartRequested';
	}

	ngOnInit(): void {
		this.visitServiceSubscription = this.visitService.visitSubject.subscribe((visit) => {
			this.visit = visit;
			const formattedPreventions = this.formatPreventionsToTable(visit?.workplacePreventions || []);
			this.dataSource.data = formattedPreventions;
			this.expandedNodesId = this.treeService.getTree()?.expandedNodesIds || [];

			this.expandedNodesId.forEach((nodeId) => {
				const node = this.treeControl.dataNodes.find((node) => node.primaryKey === nodeId);
				if (node) this.treeControl.expand(node);
			});

			if (visit?.executedByUserId === this.userService.getUser()?.sub && visit?.status !== 'StartRequested' && visit?.status !== 'Finished') {
				this.startedByMe = true;
			} else {
				this.startedByMe = false;
			}
		});

		this.treeServiceSubscription = this.treeService.treeSubject.subscribe((tree) => {
			this.expandedNodesId = tree?.expandedNodesIds || [];

			if (tree === null) {
				this.treeControl.dataNodes.forEach((node) => {
					this.treeControl.collapse(node);
				});
			}
		});

		this.getData();
	}

	ngOnDestroy() {
		this.visitServiceSubscription?.unsubscribe();
		this.preventionService.clearPrevention();
		this.treeServiceSubscription?.unsubscribe();
	}

	async getData() {
		const preventions = await this.visitService.getPreventions();
		this.preventionsList = preventions;
	}

	formatPreventionsToTable(preventions: Prevention[]) {
		const finalData: any[] = [];

		preventions.forEach((prevention: Prevention) => {
			const rootInFinalData = finalData.find(
				(category: PreventionTableData) => category.primaryKey === prevention.prevention?.rootCategoryPrimaryKey
			);

			if (rootInFinalData) {
				rootInFinalData.children!.push({
					primaryKey: prevention.prevention?.primaryKey,
					name: prevention.prevention?.name,
					prevention: {
						primaryKey: prevention.prevention?.primaryKey,
						name: prevention.prevention?.name
					},
					key: prevention.prevention?.primaryKey,
					note: prevention.note,
					isApplicable: prevention.isApplicable
				});
			} else {
				finalData.push({
					primaryKey: prevention.prevention?.rootCategoryPrimaryKey,
					name: prevention.prevention?.rootCategoryName,
					prevention: {
						primaryKey: prevention.prevention?.rootCategoryPrimaryKey,
						name: prevention.prevention?.rootCategoryName
					},
					children: [
						{
							primaryKey: prevention.prevention?.primaryKey,
							name: prevention.prevention?.name,
							prevention: {
								primaryKey: prevention.prevention?.primaryKey,
								name: prevention.prevention?.name
							},
							key: prevention.prevention?.primaryKey,
							note: prevention.note,
							isApplicable: prevention.isApplicable
						}
					],
					key: prevention.prevention?.rootCategoryPrimaryKey
				});
			}
		});

		return finalData;
	}

	private _transformer = (node: Prevention, level: number) => {
		return {
			primaryKey: node.primaryKey,
			prevention: node.prevention,
			name: node.prevention?.name,
			isApplicable: node.isApplicable,
			note: node.note,
			expandable: !!node.children && node.children.length > 0,
			level: level
		};
	};

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	treeFlattener = new MatTreeFlattener<Prevention, ExampleFlatNode>(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.children
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	pushLeaves(riskId: number) {
		this.treeService.toggleNode(riskId);
	}
	shouldRenderActions = (_: number, node: ExampleFlatNode) => node.level !== 1;

	getSelectedPreventionsId(arr: Prevention[], depth: number = 0): number[] {
		if (depth === 1) {
			return arr.map((item) => item.primaryKey);
		}
		return arr.reduce((acc, item) => {
			if (Array.isArray(item.children)) {
				acc.push(...this.getSelectedPreventionsId(item.children, depth + 1));
			}
			return acc;
		}, [] as number[]);
	}

	getAllIds(tree: NzTreeNode[], field: string) {
		let ids: number[] = [];

		tree.forEach((node: NzTreeNode) => {
			const f = field as keyof NzTreeNode;
			ids.push(node[f] as number);

			if (Array.isArray(node.children)) {
				ids.push(...this.getAllIds(node.children, field));
			}
		});

		return ids;
	}

	getSelectedChildren(node: NzTreeNode, preventions: Prevention[]) {
		const isInPreventions = preventions.find((prevention) => prevention.prevention?.primaryKey === Number(node.key));

		if (!isInPreventions) {
			preventions.push({
				primaryKey: 0,
				note: '',
				prevention: {
					primaryKey: Number(node.key),
					name: node.title,
					rootCategoryPrimaryKey: Number(node.parentNode?.parentNode?.key),
					rootCategoryName: node.parentNode?.parentNode?.title!
				},
				isApplicable: false,
			});
		}
	}

	getSelectedChildrenFromParent(node: NzTreeNode, preventions: Prevention[]) {
		node.children?.forEach((subCategory) => {
			subCategory.children?.forEach((prevention) => {
				const isInPreventions = preventions.find((x) => x.prevention?.primaryKey === Number(prevention.key));

				if (!isInPreventions) {
					preventions.push({
						primaryKey: 0,
						note: '',
						prevention: {
							primaryKey: Number(prevention.key),
							name: prevention.title,
							rootCategoryPrimaryKey: Number(node.key),
							rootCategoryName: node.title
						},
						isApplicable: false,
					});
				}
			});
		});
	}

	onKey(event: Event) {
		const value = (event.target as HTMLInputElement)?.value.toLowerCase();

		this.treeSearch = value;

		return;
	}

	findAndReplaceById(arr: Prevention[], id: number, replace: Partial<Prevention>) {
		return arr.map((item) => {
			if (item.prevention.primaryKey === id) {
				return { ...item, ...replace };
			}
			if (Array.isArray(item.children)) {
				item.children = this.findAndReplaceById(item.children, id, replace);
			}
			return item;
		});
	}

	onCheckChange(event: MatCheckboxChange, primaryKey: number, nodePrevention: Prevention) { // primaryKey = prevention.prevention.primaryKey
		
		let prevention = this.manageCheckboxes(primaryKey, event);
		if(!prevention)
		{return;}

		this.preventionService.setPrevention(nodePrevention);
		if(prevention.isApplicable == false && prevention.note == ""){
			if (!this.startedByMe) return;
			
			const modalRef: NgbModalRef = this.modalService.open(ModalDescriptionComponent,{ centered: true, backdrop: 'static' });
			modalRef.componentInstance.prevention = prevention;  // Pass data to the modal component if needed

			modalRef.result.then((result) => {
				if(result == false){
					
					/* could change the event so its fitting the situation but its absolute useless in this situation */
					event.checked = false;
					event.source.checked = false;

					this.manageCheckboxes(primaryKey)
				}
			})
			
		}
	}

	manageCheckboxes(primaryKey: number, event?: MatCheckboxChange){
		if (!this.startedByMe) return;

		const visit = this.visitService.getStoredVisit();
		const preventions = visit?.workplacePreventions;

		if (!preventions) return;

		const prevention = preventions?.find((x) => x.prevention.primaryKey === primaryKey); // prevention = prevention

		if (!prevention) return;

		const previousValue = prevention.isApplicable;
		if(event != null){ // when this method gets called via onCheckChange event
			if (event.source.name === 'no') {
				if (previousValue === false) prevention.isApplicable = null;
				else prevention.isApplicable = false;
			} else {
				if (previousValue) prevention.isApplicable = null;
				else prevention.isApplicable = event.checked;
			}
		}else{ // when i dont have an event -> when the checkbox gets unchecked because of no declared "no-reason"
			if (previousValue === false) prevention.isApplicable = null;
				else prevention.isApplicable = false;
		}

		const newPreventions = visit.workplacePreventions.map((prevention) => {
			if (prevention.prevention.primaryKey === primaryKey) {
				return prevention;
			}
			return prevention;
		});

		this.visitService.setVisit({
			...visit,
			workplacePreventions: newPreventions,
			hasUnsavedFields: true
		});

		return prevention;
	}


	showModal(prevention: Prevention, event: Event) { // prevention = prevention.prevention
		if (!this.startedByMe) return;
		event.stopPropagation();
		event.preventDefault();

		this.preventionService.setPrevention(prevention);
		const modalRef: NgbModalRef = this.modalService.open(ModalDescriptionComponent,{ centered: true, backdrop: 'static' });
			modalRef.componentInstance.prevention = prevention;  // Pass data to the modal component if needed

			modalRef.result.then((result) => {
				if(prevention.isApplicable == false && result == false){
					this.manageCheckboxes(prevention.primaryKey);
				}
			})
	}

}
