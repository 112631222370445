import { Injectable } from '@angular/core';
import { Prevention } from 'src/app/services/visit.service';

@Injectable({
	providedIn: 'root'
})
export class PreventionService {
	prevention: Prevention | null = null;

	constructor() {
		this.prevention = JSON.parse(localStorage.getItem('prevention') || '{}');
	}

	setPrevention = (prevention: Prevention) => {
		localStorage.setItem('prevention', JSON.stringify(prevention));
	};

	getPrevention = () => {
		return JSON.parse(localStorage.getItem('prevention') || '{}');
	};

	clearPrevention = () => {
		localStorage.removeItem('prevention');
	};
}
