<div class="tj-modal-header">
	<h4 class="tj-modal-header-title" i18n>{{ 'sendVisitGfb' | translate }}</h4>
</div>
<div class="tj-modal-body tj-send-gfb-modal">
	<p>{{ 'herebyConfirmGfb' | translate }}</p>
	<div class="tj-select-wrapper">
		<label class="tj-select-label">{{ 'evaluation' | translate }}</label>
		<mat-form-field class="tj-fill-container tj-modal-input" appearance="fill" hideRequiredMarker
			subscriptSizing="dynamic">
			<mat-select name="evaluation" [formControl]="evaluation" [placeholder]="'selectEvaluation' | translate"
				(valueChange)="hasUnsavedChanges = true">

				<mat-option *ngFor="let item of resultOptions" [value]="item.value">
					{{item.value}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="tj-select-wrapper">
		<label class="tj-select-label">{{ 'reasonForReview' | translate }}</label>
		<mat-form-field class="tj-fill-container tj-modal-input" appearance="fill" hideRequiredMarker
			subscriptSizing="dynamic">
			<mat-select name="reason" [formControl]="reasonForReview" [placeholder]="'selectReason' | translate"
				(valueChange)="hasUnsavedChanges = true">
				<mat-option *ngFor="let item of reasonForReviewOptions" [value]="item">
					{{item.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="tj-select-wrapper">
		<label class="tj-select-label">{{ 'result' | translate }}</label>
		<mat-form-field class="tj-fill-container tj-modal-input" appearance="fill" hideRequiredMarker
			subscriptSizing="dynamic">
			<mat-select name="result" [formControl]="result" [placeholder]="'selectResult' | translate"
				(valueChange)="hasUnsavedChanges = true">
				<mat-option *ngFor="let item of evaluationOptions" [value]="item">
					{{item.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>
<div class="tj-modal-footer">
	<button type="button" class="tj-btn tj-btn__danger" (click)="leave()">{{ 'cancel' | translate }}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus>{{
		'sendVisitGfb' | translate
		}}</button>
</div>
