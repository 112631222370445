import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VisitTableComponent } from 'src/app/components/visit-table/visit-table.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { User, UserService } from 'src/app/services/user.service';
import { Visit, VisitService } from 'src/app/services/visit.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { DateTime } from 'luxon';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, interval, startWith, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	standalone: true,
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	imports: [VisitTableComponent, MatGridListModule, CommonModule, MatTabsModule, TranslatePipe]
})
export class HomeComponent implements OnInit, OnDestroy {
	device: string = 'desktop';
	screenHeight: number = window.innerHeight;
	btoList: Visit[] = [];
	gfbList: Visit[] = [];

	private getDataSubscription?: Subscription;

	constructor(
		private breadcrumbService: BreadcrumbService,
		public router: Router,
		private userService: UserService,
		private visitService: VisitService,
		private oidcSecurityService: OidcSecurityService,
		private authService: AuthService
	) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.screenHeight = window.innerHeight;
		this.breadcrumbService.setBreadcrumb({ locations: [] });

		const { bto, gfb } = visitService.getStoredVisits();
		this.btoList = bto;
		this.gfbList = gfb;
	}

	ngOnInit() {
		this.oidcSecurityService
			.isAuthenticated()
			.subscribe(async (isAuthenticated) => {
				if (!isAuthenticated) {
					await this.authService.logout();
				} else {
					this.userService.setUser({ ...this.userService.getUser(), language: this.userService.getUser()?.language || 'de' } as User);
				}
			});

		this.getDataSubscription = interval(20000)
			.pipe(
				startWith(0),
				switchMap(() => this.getData())
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.breadcrumbService.setBreadcrumb({ locations: [] });
		if (this.getDataSubscription) {
			this.getDataSubscription.unsubscribe();
		  }
	}

	async getData() {
		if (!this.userService.getUser() || !this.authService.getToken()) return;

		try {
			const { bto = [], gfb = [] } = await this.visitService.getVisits();

			this.btoList = bto.map((visit: Visit) => {
				visit.dateDiff = visit.dueDate ? ~~DateTime.fromISO(visit.dueDate).diffNow('days').days : 0;
				return visit;
			});
			this.gfbList = gfb.map((visit: Visit) => {
				visit.dateDiff = visit.dueDate ? ~~DateTime.fromISO(visit?.dueDate).diffNow('days').days : 0;
				return visit;
			});

			this.visitService.setStoredVisits({ bto, gfb });
		} catch (error: unknown) {
			console.error(error);
		}
	}
}
