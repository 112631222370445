import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticatedResult, OidcSecurityService } from "angular-auth-oidc-client";
import { UserService } from "./user.service";
import { Observable, first, firstValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class AuthService {
    constructor(
		public router: Router,
		private oidcSecurityService: OidcSecurityService,
		private userService: UserService
	) {	}


	/**
	 * Logs in the user if they are not already authenticated by flag in localStorage.
	 * If the user is already authenticated, this method does nothing.
	 * Otherwise, it calls the `authorize` method of the `oidcSecurityService` to initiate the login process.
	 */
	login() {
		if (this.isAuthenticated())
			return;

		this.oidcSecurityService.authorize('bto-gfb');
	}


	/**
	 * Logs out the user by revoking tokens and clearing session data.
	 * If the user is not authenticated, it redirects to the authentication page.
	 */
	async logout() {
		if (await this.getTokenFromOidc() === null) {
			return;
		}

		this.oidcSecurityService.isAuthenticated().subscribe((isAuthenticated) => {
			if (!isAuthenticated) {
				this.router.navigate(['/auth']);
				return;
			}

			this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
				this.clearSessionData();

				this.oidcSecurityService
				.checkAuthIncludingServer()
				.pipe(first())
				.subscribe(
					loginResponse => {
						localStorage.setItem('isAuth', JSON.stringify(loginResponse.isAuthenticated))
						this.router.navigate(['/auth']);
					}
				);
			});
		});

		// try {
		// 	this.oidcSecurityService
		// 		.checkAuthIncludingServer()
		// 		.pipe(first())
		// 		.subscribe(
		// 			x => localStorage.setItem('isAuth', JSON.stringify(x.isAuthenticated))
		// 		);
		// }
		// catch (error) {
		// 	this.router.navigate(['/auth']);
		// 	return;
		// }

		// if (!this.isAuthenticated())
		// {
		// 	this.router.navigate(['/auth']);
		// 	return;
		// }

		// this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
		// 	this.clearSessionData();
		// 	this.router.navigate(['/auth']);
		// });
	}


/**
 * Checks if the user is authenticated by reading a corresponding flag from the localStorage.
 * @returns {boolean} True if the user is authenticated, false otherwise.
 */
    isAuthenticated(): boolean {
        return localStorage.getItem('isAuth') !== null && localStorage.getItem('isAuth') === 'true';
    }


    /**
     * Retrieves the token from the OpenID Connect (OIDC) provider.
     *
     * @returns A promise that resolves to the token string or null if the token is not available.
     */
	getToken(): Promise<string | null> {
		const token = this.getTokenFromOidc();
		return token;
	}

    /**
     * Clears the token from the local storage and logs off the user by revoking tokens.
     * @returns A promise that resolves when the tokens are successfully revoked.
     */
	private clearSessionData(): void {
        this.userService?.clearUser();
        localStorage.clear();
	};


    /**
     * Retrieves the access token from the OIDC security service.
     * If the user is not authenticated, it logs out the user and returns null.
     * @returns A promise that resolves to the access token or null.
     */
	private async getTokenFromOidc(): Promise<string | null> {
		if (!await firstValueFrom(this.oidcSecurityService.isAuthenticated()))
		{
            console.warn('getTokenFromOidc: User is not authenticated. Session cleared.');
			this.clearSessionData();
			this.router.navigate(['/auth']);
			return null;
		}
		const accessToken = await firstValueFrom(this.oidcSecurityService.getAccessToken());
		return accessToken;
	}

}
