import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor, AuthModule } from 'angular-auth-oidc-client';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { AuthComponent } from './views/auth/auth.component';
import { HomeComponent } from './views/home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { VisitTableComponent } from './components/visit-table/visit-table.component';
import { ProfileComponent } from './views/profile/profile.component';
import { VisitComponent } from './views/visit/visit.component';
import { WorkSystemComponent } from './views/visit/sub-components/work-system/work-system.component';
import { SpecificCharacteristicsComponent } from './views/visit/sub-components/specific-characteristics/specific-characteristics.component';
import { RisksEvaluatedComponent } from './views/visit/sub-components/risks-evaluated/risks-evaluated.component';
import { ModalDescriptionComponent as RisksModalDescription } from './views/visit/sub-components/risks-evaluated/sub-components/modal-description/modal-description.component';
import { ModalHazardComponent } from './views/visit/sub-components/risks-evaluated/sub-components/modal-hazard/modal-hazard.component';
import { PreventionComponent } from './views/visit/sub-components/prevention/prevention.component';
import { ModalDescriptionComponent as PreventionModalDescription } from './views/visit/sub-components/prevention/sub-components/modal-description/modal-description.component';
import { WorksiteMeasuresComponent } from './views/visit/sub-components/worksite-measures/worksite-measures.component';
import { PersonalMeasuresComponent } from './views/visit/sub-components/personal-measures/personal-measures.component';
import { PresentAndAbsentStaffComponent } from './views/visit/sub-components/present-and-absent-staff/present-and-absent-staff.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { SendModalComponent } from './views/visit/sub-components/send-modal/send-modal.component';
import { CancelVisitModalComponent } from './views/visit/sub-components/cancel-visit/cancel-visit.component';
import { SendGfbModalComponent } from './views/visit/sub-components/send-gfb-modal/send-gfb-modal.component';
import { AdditionalInformationComponent } from './views/visit/sub-components/additional-information/additional-information.component';
import { LoginCallbackComponent } from './views/login-callback/login-callback.component';
import { StartModalComponent } from './views/visit/sub-components/start-modal/start-modal.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReasonMissingDialogComponent } from './views/visit/sub-components/prevention/sub-components/reason-missing-dialog/reason-missing-dialog.component';

// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
	declarations: [AppComponent, 
		ReasonMissingDialogComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HeaderComponent,
		AuthComponent,
		BreadcrumbComponent,
		ProfileComponent,
		HomeComponent,
		VisitTableComponent,
		VisitComponent,
		WorkSystemComponent,
		SpecificCharacteristicsComponent,
		RisksEvaluatedComponent,
		RisksModalDescription,
		ModalHazardComponent,
		WorksiteMeasuresComponent,
		PersonalMeasuresComponent,
		PreventionComponent,
		PreventionModalDescription,
		PresentAndAbsentStaffComponent,
		SendModalComponent,
		CancelVisitModalComponent,
		TranslatePipe,
		SendGfbModalComponent,
		StartModalComponent,
		AdditionalInformationComponent,
		LoginCallbackComponent,

		// Material
		MatDialogModule,
		MatTabsModule,

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		AuthModule.forRoot({
			config: {
				configId: 'bto-gfb',
				clientId: 'bto-ui',
				authority: environment.authority,
				responseType: 'code',
				redirectUrl: window.location.origin + '/login-callback',
				postLogoutRedirectUri: window.location.origin + '',
				postLoginRoute: 'home',
				scope: 'bto.read bto.write openid profile offline_access',
				silentRenew: true,
				silentRenewUrl: window.location.origin + '/silent-renew.html',
				ngswBypass: true, //if you should use a service worker
				secureRoutes: [environment.apiUrl],
				logLevel: Number(4),
				useRefreshToken: true,
				renewTimeBeforeTokenExpiresInSeconds: 30,
				historyCleanupOff: false,
				ignoreNonceAfterRefresh: true,
				triggerRefreshWhenIdTokenExpired: false
			}
		})
	],
	providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
})
export class AppModule {}
