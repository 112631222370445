import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { Prevention, Visit, VisitService } from 'src/app/services/visit.service';
import { PreventionService } from 'src/app/views/visit/services/prevention.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
	standalone: true,
	selector: 'app-modal-description',
	templateUrl: './modal-description.component.html',
	styleUrls: ['./modal-description.component.scss'],
	imports: [MatInputModule, ReactiveFormsModule, TranslatePipe]
})
export class ModalDescriptionComponent {
	description = new FormControl('');
	oldDescription: string = "";
	constructor(public modal: NgbActiveModal, private visitService: VisitService, private preventionService: PreventionService) {
		const prevention = this.preventionService.getPrevention();
		this.description.setValue(prevention.note);
		this.oldDescription = prevention.note;
	}

	leave() {
		if(this.oldDescription != ""){
			this.modal.close(true);
		}else{
			this.modal.close(false);
		}
	}

	submit() {
		const visit = this.visitService.getStoredVisit();
		if (!visit) return;
		
		const preventionId = this.preventionService.getPrevention()?.primaryKey;
		const newPreventions = visit.workplacePreventions.map((prevention) => {
			if (prevention.prevention.primaryKey === preventionId) {
				return {
					...prevention,
					note: this.description.value
				} as Prevention;
			}
			return prevention;
		});
		this.visitService.setVisit({
			...visit,
			workplacePreventions: newPreventions,
			hasUnsavedFields: true
		} as Visit);

		this.preventionService.clearPrevention();
		if(this.description.value != ""){
			this.modal.close(true);
		}
		else{
			this.modal.close(false);
		}
		
	}

}
