import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { Equipment, Qualification, Visit, VisitService } from 'src/app/services/visit.service';
import { EquipmentData, QualificationData } from './types';
import { UserService } from 'src/app/services/user.service';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	standalone: true,
	selector: 'app-work-system',
	templateUrl: './work-system.component.html',
	styleUrls: ['./work-system.component.scss'],
	imports: [
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatCheckboxModule,
		ReactiveFormsModule,
		CommonModule,
		NzDrawerModule,
		TranslatePipe
	]
})
export class WorkSystemComponent implements OnInit, OnDestroy {
	screenService: Subscription | null = null;
	qualificationRef: HTMLElement | null = null;
	equipmentsRef: HTMLElement | null = null;
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);

	continuationTokenQual: string = '';
	defaultTokenQual: string = '';
	continuationTokenEquip: string = '';
	defaultTokenEquip: string = '';
	fetching: boolean = false;
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	step: number[] = [];
	workplaceName = new FormControl('');
	workArea = new FormControl('');
	workplaceEnvironment = new FormControl('');
	workplaceAssignment = new FormControl('');
	workDescription = new FormControl('');
	input = new FormControl('');
	output = new FormControl('');
	workplaceToolsAndEquipment = new FormControl<Equipment[]>([]);
	workingMaterials = new FormControl('');
	toolsAndEquipmentNotes = new FormControl('');
	workplaceProfessions = new FormControl<Qualification[]>([]);
	neededQualifications = new FormControl('');
	isOnsiteMvSupervisor = new FormControl<boolean | undefined>(undefined);
	isDangerousWorkplace = new FormControl<boolean | undefined>(undefined);
	searchFilter = new FormControl('');

	matcher = new MyErrorStateMatcher();

	visible: boolean = false;
	drawerType: 'equipments' | 'qualifications' = 'equipments';
	startedByMe: boolean = false;

	defaultEquipments: EquipmentData[] = [];
	equipmentsDrawerEntries: EquipmentData[] = [];
	matchedEquipments: EquipmentData[] = [];

	defaultQualifications: QualificationData[] = [];
	qualificationsDrawerEntries: QualificationData[] = [];
	matchedQualifications: QualificationData[] = [];

	constructor(
		private visitService: VisitService,
		private userService: UserService,
		private notification: NzNotificationService,
		private translateService: TranslateService
	) {
		const visit = this.visitService.getStoredVisit();
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.startedByMe =
			visit?.executedByUserId === this.userService.getUser()?.sub &&
			visit?.status !== 'StartRequested' &&
			visit?.status !== 'Finished';

		if (!this.startedByMe) this.disableFields();
	}

	ngOnInit(): void {
		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			this.workplaceName.setValue(visit?.workplace?.workplaceName || '');
			this.workArea.setValue(visit?.workplace?.workArea || '');
			this.workplaceEnvironment.setValue(visit?.workplace?.workplaceEnvironment || '');
			this.workplaceAssignment.setValue(visit?.workplace?.workplaceAssignment || '');
			this.workDescription.setValue(visit?.workplace?.workDescription || '');
			this.input.setValue(visit?.workplace?.input || '');
			this.output.setValue(visit?.workplace?.output || '');
			this.workplaceToolsAndEquipment.setValue(visit?.workplace?.workplaceToolsAndEquipment || []);
			this.workingMaterials.setValue(visit?.workplace?.workingMaterials || '');
			this.toolsAndEquipmentNotes.setValue(visit?.workplace?.toolsAndEquipmentNotes || '');
			this.workplaceProfessions.setValue(visit?.workplace?.workplaceProfessions || []);
			this.neededQualifications.setValue(visit?.workplace?.neededQualifications || '');
			this.isOnsiteMvSupervisor.setValue(visit?.workplace?.isOnsiteMvSupervisor || false);
			this.isDangerousWorkplace.setValue(visit?.workplace?.isDangerousWorkplace || false);

			if (
				visit?.executedByUserId === this.userService.getUser()?.sub &&
				visit?.status !== 'StartRequested' &&
				visit?.status !== 'Finished'
			) {
				this.enableFields();
			} else {
				this.disableFields();
			}
		});

		this.getData();
	}

	ngAfterViewInit(): void {
		const qualificationList = document.getElementById('tj-qualification-list')?.parentElement;
		if (qualificationList) {
			this.qualificationRef = qualificationList;
			this.qualificationRef.addEventListener('scroll', (event) => {
				const element = event.target as HTMLInputElement;
				const scrollPosition = element.scrollTop + element.clientHeight;
				const maxScroll = element.scrollHeight;
				if (scrollPosition && scrollPosition >= maxScroll) {
					this.fetching = true;
					this.fetchQualifications();
				}
			});
		}

		const equipmentList = document.getElementById('tj-workplaceToolsAndEquipment-list')?.parentElement;
		if (equipmentList) {
			this.equipmentsRef = equipmentList;
			this.equipmentsRef.addEventListener('scroll', (event) => {
				const element = event.target as HTMLInputElement;
				const scrollPosition = element.scrollTop + element.clientHeight;
				const maxScroll = element.scrollHeight;
				if (scrollPosition && scrollPosition >= maxScroll) {
					this.fetching = true;
					this.fetchEquipments();
				}
			});
		}
	}

	async fetchQualifications() {
		if (!this.fetching || this.continuationTokenQual == null) {
			return;}

		const searchFilterValue: string | undefined = this.searchFilter.value?.toLowerCase();
		const { qualifications, continuationToken } = await this.visitService.getQualifications(this.continuationTokenQual, searchFilterValue);
		this.qualificationsDrawerEntries = this.qualificationsDrawerEntries.concat(qualifications);
		this.continuationTokenQual = continuationToken;
		
		if (continuationToken === '') this.fetching = false;
	}

	async fetchEquipments() {
		if (!this.fetching || this.continuationTokenEquip == null){
			return;} 

		const searchFilterValue: string | undefined = this.searchFilter.value?.toLowerCase();
		const { equipments, continuationToken } = await this.visitService.getEquipments(this.continuationTokenEquip, searchFilterValue);
		this.equipmentsDrawerEntries = this.equipmentsDrawerEntries.concat(equipments);
		this.continuationTokenEquip = continuationToken;

		if (continuationToken === '') this.fetching = false;
	}

	async getData() {
		const { equipments, continuationToken: equipToken } = await this.visitService.getEquipments('');
		this.continuationTokenEquip = equipToken || '';
		this.defaultTokenEquip = equipToken;
		this.defaultEquipments = equipments;
		this.equipmentsDrawerEntries = structuredClone(this.defaultEquipments);

		const { qualifications, continuationToken: qualToken } = await this.visitService.getQualifications('');
		this.continuationTokenQual = qualToken || '';
		this.defaultTokenQual = qualToken;
		this.defaultQualifications = qualifications;
		this.qualificationsDrawerEntries = structuredClone(this.defaultQualifications);
	}

	ngOnDestroy(): void {
		if (this.qualificationRef) {
			this.qualificationRef.removeEventListener('scroll', () => {});
		}
		if (this.equipmentsRef) {
			this.equipmentsRef.removeEventListener('scroll', () => {});
		}

		this.screenService?.unsubscribe();
	}

	enableFields() {
		this.startedByMe = true;
		this.workplaceName.enable();
		this.workArea.enable();
		this.workplaceEnvironment.enable();
		this.workplaceAssignment.enable();
		this.workDescription.enable();
		this.input.enable();
		this.output.enable();
		this.workplaceToolsAndEquipment.enable();
		this.workingMaterials.enable();
		this.toolsAndEquipmentNotes.enable();
		this.workplaceProfessions.enable();
		this.neededQualifications.enable();
		this.isDangerousWorkplace.enable();
		this.isOnsiteMvSupervisor.enable();
	}

	disableFields() {
		this.startedByMe = false;
		this.workplaceName.disable();
		this.workArea.disable();
		this.workplaceEnvironment.disable();
		this.workplaceAssignment.disable();
		this.workDescription.disable();
		this.input.disable();
		this.output.disable();
		this.workplaceToolsAndEquipment.disable();
		this.workingMaterials.disable();
		this.toolsAndEquipmentNotes.disable();
		this.workplaceProfessions.disable();
		this.neededQualifications.disable();
		this.isDangerousWorkplace.disable();
		this.isOnsiteMvSupervisor.disable();
	}

	async applyFilter(field: 'workplaceToolsAndEquipment' | 'qualifications') {
		if (!this.searchFilter.value) {
			this.qualificationsDrawerEntries = structuredClone(this.defaultQualifications);
			this.equipmentsDrawerEntries = structuredClone(this.defaultEquipments);

			return;
		}
		const value = this.searchFilter.value?.toLowerCase();

		switch (field) {
			case 'workplaceToolsAndEquipment':
				const { equipments, continuationToken: equipToken } = await this.visitService.getEquipments(undefined, value);
				this.continuationTokenQual = equipToken;
				this.equipmentsDrawerEntries = structuredClone(equipments);
				break;
			case 'qualifications':
				const { qualifications, continuationToken: qualToken } = await this.visitService.getQualifications(undefined, value);
				this.continuationTokenQual = qualToken;
				this.qualificationsDrawerEntries = structuredClone(qualifications);
				break;
			default:
				break;
		}
	}

	onDrawerCheck(value: EquipmentData | QualificationData, field: string, event?: MatCheckboxChange) {
		if (field === 'workplaceToolsAndEquipment') {
			const exists = this.matchedEquipments.find((x) => x.primaryKey === value.primaryKey);
			if (exists) this.matchedEquipments = this.matchedEquipments.filter((x) => x.primaryKey !== value.primaryKey);
			else this.matchedEquipments.push(value as EquipmentData);
		} else {
			const exists = this.matchedQualifications.find((x) => x.primaryKey === value.primaryKey);
			if (exists) {
				this.matchedQualifications = this.matchedQualifications.filter((x) => x.primaryKey !== value.primaryKey);
			} else if (this.matchedQualifications.length < 5) {
				this.matchedQualifications.push(value as Qualification);
			} else if (this.matchedQualifications.length === 5 && !exists) {
				event?.source.toggle();
				this.notification.create('warning', this.translate('warning'), this.translate('maxQualifications'), {
					nzPlacement: 'bottomRight'
				});
			}
		}
	}

	onSubmit() {
		let field = '';
		switch (this.drawerType) {
			case 'equipments':
				const newEquipments = this.matchedEquipments.map((x) => {
					const equipmentObj = this.workplaceToolsAndEquipment.value!.find((y) => {
						return y.toolsAndEquipment.primaryKey === x.primaryKey;
					});

					return {
						primaryKey: equipmentObj?.primaryKey || 0,
						toolsAndEquipment: {
							primaryKey: x.primaryKey,
							name: x.name
						},
						techincalSafetyMeasures: equipmentObj?.technicalSafetyMeasures || undefined
					};
				});
				this.workplaceToolsAndEquipment.setValue(newEquipments);
				field = 'workplace.workplaceToolsAndEquipment';
				break;
			case 'qualifications':
				const newQualifications = this.matchedQualifications.map((x) => ({
					primaryKey: x.primaryKey,
					name: x.name
				}));
				this.workplaceProfessions.setValue(newQualifications);
				field = 'workplace.workplaceProfessions';
				break;
		}

		this.setVisit(field);
		this.visible = false;
		this.drawerType = 'equipments';
		this.continuationTokenQual = this.defaultTokenQual;
		this.continuationTokenEquip = this.defaultTokenEquip;
	}

	onRemove(element: Equipment | Qualification, field: string) {
		if (!this.startedByMe) return;

		switch (field) {
			case 'workplace.workplaceToolsAndEquipment':
				const equipmentsArray: Equipment[] = this.workplaceToolsAndEquipment.value!;
				this.workplaceToolsAndEquipment.setValue(equipmentsArray.filter((x) => x.primaryKey !== element.primaryKey));
				this.setVisit(field);
				break;
			case 'workplace.workplaceProfessions':
				const qualificationsArray: Qualification[] = this.workplaceProfessions.value!;
				this.workplaceProfessions.setValue(qualificationsArray.filter((x) => x.primaryKey !== element.primaryKey) || []);
				this.setVisit(field);
				break;
		}
	}

	onCheckChange(event: MatCheckboxChange, field: string) {
		switch (field) {
			case 'workplace.isDangerousWorkplace':
				this.isDangerousWorkplace.setValue(event.checked);
				break;
			case 'workplace.isOnsiteMvSupervisor':
				this.isOnsiteMvSupervisor.setValue(event.checked);
				break;
			default:
				break;
		}

		this.setVisit(field);
	}

	onPaneClick(num: number) {
		if (this.step.includes(num)) {
			this.step = this.step.filter((x) => x !== num);
		} else {
			this.step.push(num);
		}
	}

	updateNestedField(obj: Visit, field: string, newValue: any): Visit {
		let keys = field.split('.');

		let newObj = JSON.parse(JSON.stringify(obj));

		function recursiveUpdate(obj: any, keys: string[], newValue: any): any {
			let key = keys.shift();

			if (key) {
				if (keys.length === 0) {
					obj[key] = newValue;
				} else {
					recursiveUpdate(obj[key], keys, newValue);
				}
			}
		}

		recursiveUpdate(newObj, keys, newValue);

		return newObj;
	}

	setVisit(field: string) {
		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		let visitField: FormControl | undefined;

		switch (field) {
			case 'workplace.workplaceName':
				visitField = this.workplaceName;
				break;
			case 'workplace.workArea':
				visitField = this.workArea;
				break;
			case 'workplace.workplaceEnvironment':
				visitField = this.workplaceEnvironment;
				break;
			case 'workplace.workplaceAssignment':
				visitField = this.workplaceAssignment;
				break;
			case 'workplace.workDescription':
				visitField = this.workDescription;
				break;
			case 'workplace.input':
				visitField = this.input;
				break;
			case 'workplace.output':
				visitField = this.output;
				break;
			case 'workplace.workplaceToolsAndEquipment':
				visitField = this.workplaceToolsAndEquipment;
				break;
			case 'workplace.workingMaterials':
				visitField = this.workingMaterials;
				break;
			case 'workplace.toolsAndEquipmentNotes':
				visitField = this.toolsAndEquipmentNotes;
				break;
			case 'workplace.workplaceProfessions':
				visitField = this.workplaceProfessions;
				break;
			case 'workplace.neededQualifications':
				visitField = this.neededQualifications;
				break;
			case 'workplace.isDangerousWorkplace':
				visitField = this.isDangerousWorkplace;
				break;
			case 'workplace.isOnsiteMvSupervisor':
				visitField = this.isOnsiteMvSupervisor;
				break;
			default:
				break;
		}

		const newVisit = this.updateNestedField(visit, field, visitField?.value);

		this.visitService.setVisit({ ...newVisit, hasUnsavedFields: true } as Visit);
	}

	openDrawer(event: Event, drawer: 'equipments' | 'qualifications'): void {
		if (!this.startedByMe) return;
		event.stopPropagation();

		this.drawerType = drawer;
		this.visible = true;

		if (drawer === 'equipments') {
			this.equipmentsDrawerEntries = structuredClone(this.defaultEquipments);
			this.matchedEquipments =
				this.workplaceToolsAndEquipment.value!.map((equipment) => ({
					primaryKey: equipment.toolsAndEquipment.primaryKey,
					name: equipment.toolsAndEquipment.name
				})) || [];
		} else if (drawer === 'qualifications') {
			this.fetching = true;
			this.qualificationsDrawerEntries = structuredClone(this.defaultQualifications);
			this.matchedQualifications = structuredClone(this.workplaceProfessions.value) || [];
		}
	}

	isChecked(entry: number, field: string): boolean {
		if (field === 'workplaceToolsAndEquipment')
			return !!this.workplaceToolsAndEquipment.value?.find((x) => x.toolsAndEquipment.primaryKey === entry);
		return !!this.workplaceProfessions.value?.find((x) => x.primaryKey === entry);
	}

	close(): void {
		this.visible = false;
		this.searchFilter.setValue('');
		this.drawerType = 'equipments';
		this.continuationTokenQual = this.defaultTokenQual;
		this.continuationTokenEquip = this.defaultTokenEquip;

		this.equipmentsDrawerEntries = structuredClone(this.defaultEquipments);
		this.matchedEquipments =
			this.workplaceToolsAndEquipment.value!.map((equipment) => ({
				primaryKey: equipment.primaryKey,
				name: equipment.toolsAndEquipment.name
			})) || [];

		this.qualificationsDrawerEntries = structuredClone(this.defaultQualifications);
		this.matchedQualifications = structuredClone(this.workplaceProfessions.value) || [];

		if (this.qualificationRef) this.qualificationRef.scrollTo(0, 0);
		if (this.equipmentsRef) this.equipmentsRef.scrollTo(0, 0);
	}
}
