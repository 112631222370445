import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { Visit, VisitService } from 'src/app/services/visit.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	standalone: true,
	selector: 'app-specific-characteristics',
	templateUrl: './specific-characteristics.component.html',
	styleUrls: ['./specific-characteristics.component.scss'],
	imports: [MatExpansionModule, MatIconModule, MatInputModule, CommonModule, ReactiveFormsModule, TranslatePipe]
})
export class SpecificCharacteristicsComponent implements OnInit, OnDestroy {
	step: number[] = [];
	specificFeatures = new FormControl('');
	healthPromotion = new FormControl('');
	screenService: Subscription | null = null;

	startedByMe: boolean = false;

	constructor(private visitService: VisitService, private userService: UserService) {
		const visit = this.visitService.getStoredVisit();
		this.startedByMe = visit?.executedByUserId === this.userService.getUser()?.sub;

		if (!this.startedByMe) this.disableFields();
	}

	ngOnInit(): void {
		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			this.specificFeatures.setValue(visit?.workplace?.specificFeatures || '');
			this.healthPromotion.setValue(visit?.workplace?.healthPromotion || '');

			if (
				visit?.executedByUserId === this.userService.getUser()?.sub &&
				visit?.status !== 'StartRequested' &&
				visit?.status !== 'Finished'
			) {
				this.enableFields();
			} else {
				this.disableFields();
			}
		});
	}

	ngOnDestroy(): void {
		this.screenService?.unsubscribe();
	}

	enableFields() {
		this.startedByMe = true;
		this.specificFeatures.enable();
		this.healthPromotion.enable();
	}

	disableFields() {
		this.startedByMe = false;
		this.specificFeatures.disable();
		this.healthPromotion.disable();
	}

	updateNestedField(obj: Visit, field: string, newValue: any): Visit {
		let keys = field.split('.');

		let newObj = JSON.parse(JSON.stringify(obj));

		function recursiveUpdate(obj: any, keys: string[], newValue: any): any {
			let key = keys.shift();

			if (key) {
				if (keys.length === 0) {
					obj[key] = newValue;
				} else {
					recursiveUpdate(obj[key], keys, newValue);
				}
			}
		}

		recursiveUpdate(newObj, keys, newValue);

		return newObj;
	}

	setVisit(field: string) {
		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		let visitField: FormControl | undefined;

		switch (field) {
			case 'workplace.specificFeatures':
				visitField = this.specificFeatures;
				break;
			case 'workplace.healthPromotion':
				visitField = this.healthPromotion;
				break;
		}

		const newVisit = this.updateNestedField(visit, field, visitField?.value);

		this.visitService.setVisit({ ...newVisit, hasUnsavedFields: true } as Visit);
	}

	onPaneClick(num: number) {
		if (this.step.includes(num)) {
			this.step = this.step.filter((x) => x !== num);
		} else {
			this.step.push(num);
		}
	}
}
