import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'app-send-modal',
	templateUrl: './send-modal.component.html',
	styleUrls: ['./send-modal.component.scss'],
	imports: [MatInputModule, ReactiveFormsModule, TranslatePipe, CommonModule]
})
export class SendModalComponent {
	constructor(public modal: NgbActiveModal) {}

	leave() {
		this.modal.close(false);
	}

	submit() {
		this.modal.close(true);
	}
}
