import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';
import { User, UserService } from 'src/app/services/user.service';
import { Equipment, OrganizationalMeasure, Person, Visit, VisitService } from 'src/app/services/visit.service';
import { Subscription } from 'rxjs';
import { EquipmentData } from '../work-system/types';

export interface WorksiteEquipment {
	id: string;
	name: string;
}

@Component({
	standalone: true,
	selector: 'app-worksite-measures',
	templateUrl: './worksite-measures.component.html',
	styleUrls: ['./worksite-measures.component.scss'],
	imports: [
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		CommonModule,
		ReactiveFormsModule,
		NzDrawerModule,
		MatCheckboxModule,
		TranslatePipe
	]
})
export class WorksiteMeasuresComponent implements OnInit, OnDestroy {
	screenService: Subscription | null = null;
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);
	step: number[] = [];
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	startedByMe: boolean = false;
	visible: boolean = false;
	drawerType: 'equipments' | 'organizationalMeasures' | 'technicalSafetyMeasures' | 'users' | '' = '';
	drawerUserField: string = '';
	drawerTitle: string = '';
	searchFilter = new FormControl('');

	fetching: boolean = false;

	// Equip | Arbeits-/Betriebsmittel
	continuationTokenEquip: string = '';
	defaultTokenEquip: string = '';
	defaultEquipments: EquipmentData[] = [];
	equipmentsDrawerEntries: EquipmentData[] = [];
	matchedEquipments: EquipmentData[] = [];
	equipmentsRef: HTMLElement | null = null;
	
	// Organization Measures | Organisatorische Maßnahmen
	continuationTokenOrganizationalMeasures: string = '';
	organizationalMeasuresRef: HTMLElement | null = null;
	defaultTokenOrganizationalMeasures: string = '';
	defaultOrganizationalMeasures: OrganizationalMeasure[] = [];
	organizationalMeasuresEntries: OrganizationalMeasure[] = [];
	matchedOrganizationMeasures: OrganizationalMeasure[] = [];

	// Technical Safety Measures | Technische Sicherheitsmaßnahmen
	defaultContinuationTokenTechnicalSafetyMeasures: string = ''; // Default token, gets filled after receiving first 50 data objects
	continuationTokenTechnicalSafetyMeasures: string = ""; // token wich gets filled every time, you receive new technical safety measures
	tempEquipment: number | null = null; // primkey to connect technical safety measures to the corresponding equipment.
	technicalSafetyMeasuresList: EquipmentData[] = []; // first 50 data objects of technical safety measures. acts as default data.
	technicalSafetyMeasuresEntries: EquipmentData[] = this.technicalSafetyMeasuresList; // technical safety measures wich are displayed in the drawer.
	tempTechnicalSafetyMeasures: number[] = []; // array of primkeys, wich tell if the technical safety measure is used or not
	technicalSafetyMeasuresRef: HTMLElement | null = null; // html reference
	technicalSafetyMeasuresCollection: EquipmentData[] = []; // contains all the data wich are loaded, searched or used

	userList: Partial<Person>[] = [];
	defaultUsers: Partial<Person>[] = [];
	tempUser: number | null = null;

	worksiteEquipment = new FormControl<Equipment[]>([]);
	organizationalMeasures = new FormControl<OrganizationalMeasure[]>([]);
	cplContactPerson = new FormControl<Partial<Person> | null>(null);
	cplSafetyExpert = new FormControl<Partial<Person> | null>(null);
	cplFirstAider = new FormControl<Partial<Person> | null>(null);
	cplCompanyDoctor = new FormControl<Partial<Person> | null>(null);
	cplSafetyOfficer = new FormControl<Partial<Person> | null>(null);
	cplInstructorDrivingAssignment = new FormControl<Partial<Person> | null>(null);
	cplInstructor = new FormControl<Partial<Person> | null>(null);
	cplProtectionAgainstSabotage = new FormControl<Partial<Person> | null>(null);
	cplSecrecyEconomy = new FormControl<Partial<Person> | null>(null);
	cplWeldingSupervisor = new FormControl<Partial<Person> | null>(null);
	cplRadiationProtection = new FormControl<Partial<Person> | null>(null);
	cplAirCargo = new FormControl<Partial<Person> | null>(null);

	constructor(private userService: UserService, private visitService: VisitService, private translateService: TranslateService) {
		const visit = this.visitService.getStoredVisit();
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.startedByMe =
			visit?.executedByUserId === this.userService.getUser()?.sub &&
			visit?.status !== 'StartRequested' &&
			visit?.status !== 'Finished';

		if (!this.startedByMe) this.disableFields();
	}

	ngOnInit(): void {
		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			this.userList = visit?.workplace?.contactPersonList || [];
			this.userList = visit?.workplace?.contactPersonList || [];
			this.defaultUsers = visit?.workplace?.contactPersonList || [];
			this.worksiteEquipment.setValue(visit?.workplace?.workplaceToolsAndEquipment || []);
			this.organizationalMeasures.setValue(visit?.workplace?.workplaceOrganisationalMeasures || []);

			this.cplContactPerson.setValue(visit?.workplace?.cplContactPerson || null);
			this.cplSafetyExpert.setValue(visit?.workplace?.cplSafetyExpert || null);
			this.cplFirstAider.setValue(visit?.workplace?.cplFirstAider || null);
			this.cplCompanyDoctor.setValue(visit?.workplace?.cplCompanyDoctor || null);
			this.cplSafetyOfficer.setValue(visit?.workplace?.cplSafetyOfficer || null);
			this.cplInstructorDrivingAssignment.setValue(visit?.workplace?.cplInstructorDrivingAssignment || null);
			this.cplInstructor.setValue(visit?.workplace?.cplInstructor || null);
			this.cplProtectionAgainstSabotage.setValue(visit?.workplace?.cplProtectionAgainstSabotage || null);
			this.cplSecrecyEconomy.setValue(visit?.workplace?.cplSecrecyEconomy || null);
			this.cplWeldingSupervisor.setValue(visit?.workplace?.cplWeldingSupervisor || null);
			this.cplRadiationProtection.setValue(visit?.workplace?.cplRadiationProtection || null);
			this.cplAirCargo.setValue(visit?.workplace?.cplAirCargo || null);

			if (
				visit?.executedByUserId === this.userService.getUser()?.sub &&
				visit?.status !== 'StartRequested' &&
				visit?.status !== 'Finished'
			) {
				this.enableFields();
			} else {
				this.disableFields();
			}
		});

		this.getData();
	}

	ngAfterViewInit() {
		const equipmentList = document.getElementById('tj-worksiteEquipment-list')?.parentElement;
		if (equipmentList) {
			this.equipmentsRef = equipmentList;
			this.equipmentsRef.addEventListener('scroll', (event) => {
				const element = event.target as HTMLInputElement;
				const scrollPosition = element.scrollTop + element.clientHeight;
				const maxScroll = element.scrollHeight;
				if (scrollPosition && scrollPosition >= maxScroll) {	
					this.fetching = true;
					this.fetchEquipments();
				}
			});
		}

		const organizationalMeasuresList = document.getElementById('tj-organizationalMeasures-list')?.parentElement;
		if (organizationalMeasuresList) {
			this.organizationalMeasuresRef = organizationalMeasuresList;
			this.organizationalMeasuresRef.addEventListener('scroll', (event) => {
				const element = event.target as HTMLInputElement;
				const scrollPosition = element.scrollTop + element.clientHeight;
				const maxScroll = element.scrollHeight;
				if (scrollPosition && scrollPosition >= maxScroll) {	
					this.fetching = true;
					this.fetchOrganizationalMeasures();
				}
			});
		}

		const technicalSafetyMeasuresList = document.getElementById('tj-technicalSafetyMeasures-list')?.parentElement;
		if (technicalSafetyMeasuresList) {
			this.technicalSafetyMeasuresRef = technicalSafetyMeasuresList;
			this.technicalSafetyMeasuresRef.addEventListener('scroll', (event) => {
				const element = event.target as HTMLInputElement;
				const scrollPosition = element.scrollTop + element.clientHeight;
				const maxScroll = element.scrollHeight;
				if (scrollPosition && scrollPosition >= maxScroll) {	
					this.fetching = true;
					this.fetchTechicalSafetyMeasures();
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.screenService?.unsubscribe();
	}

	async fetchEquipments() {
		if (!this.fetching || this.continuationTokenEquip == null) return;
		const searchFilterValue: string | undefined = this.searchFilter.value?.toLowerCase();
		const { equipments, continuationToken } = await this.visitService.getEquipments(this.continuationTokenEquip, searchFilterValue);
		this.equipmentsDrawerEntries = this.equipmentsDrawerEntries.concat(equipments);
		this.continuationTokenEquip = continuationToken;

		if (continuationToken === '') this.fetching = false;
	}

	async fetchOrganizationalMeasures() {
		if (!this.fetching || this.continuationTokenOrganizationalMeasures == null) return;
		const searchFilterValue: string | undefined = this.searchFilter.value?.toLowerCase();
		const { organizationalMeasures, continuationToken } = await this.visitService.getOrganizationalMeasures (this.continuationTokenOrganizationalMeasures, searchFilterValue);
		this.organizationalMeasuresEntries = this.organizationalMeasuresEntries.concat(organizationalMeasures);
		this.continuationTokenOrganizationalMeasures = continuationToken;
		if (continuationToken === '') this.fetching = false;
	}

	async fetchTechicalSafetyMeasures(){
		if (!this.fetching || this.continuationTokenTechnicalSafetyMeasures == null) return;
		const searchFilterValue: string | undefined = this.searchFilter.value?.toLowerCase();
		const {technicalSafetyMeasures, continuationToken  } = await this.visitService.getTechnicalSafetyMeasures(this.continuationTokenTechnicalSafetyMeasures, searchFilterValue);
		this.technicalSafetyMeasuresEntries = this.technicalSafetyMeasuresEntries.concat(technicalSafetyMeasures);
		this.continuationTokenTechnicalSafetyMeasures = continuationToken;
		this.distinctAddNewTechnicalSafetyMeasures(technicalSafetyMeasures);
		if (continuationToken === '') this.fetching = false;
	}

	private distinctAddNewTechnicalSafetyMeasures(technicalSafetyMeasures: EquipmentData[]) {
		technicalSafetyMeasures.forEach(newTechnicalSafetyMeasure => {
			if (!this.technicalSafetyMeasuresCollection.some(technicalSafetyMeasure => technicalSafetyMeasure.primaryKey === newTechnicalSafetyMeasure.primaryKey)) {
				this.technicalSafetyMeasuresCollection.push(newTechnicalSafetyMeasure);
			}
		});
	}

	async getData() {
		const {organizationalMeasures, continuationToken: continuationTokenOrganizationalMeasures } = await this.visitService.getOrganizationalMeasures('');
		this.defaultTokenOrganizationalMeasures = continuationTokenOrganizationalMeasures;
		this.continuationTokenOrganizationalMeasures = continuationTokenOrganizationalMeasures || '';
		this.defaultOrganizationalMeasures = organizationalMeasures;
		this.organizationalMeasuresEntries = structuredClone(this.defaultOrganizationalMeasures);
		this.matchedOrganizationMeasures = this.defaultOrganizationalMeasures;

		const { equipments, continuationToken: equipToken } = await this.visitService.getEquipments('');
		this.defaultTokenEquip = equipToken;
		this.continuationTokenEquip = equipToken;
		this.defaultEquipments = equipments;
		this.matchedEquipments = this.defaultEquipments;

		const {technicalSafetyMeasures, continuationToken: ContinuationTokenTechnicalSafetyMeasures  } = await this.visitService.getTechnicalSafetyMeasures('');
		this.defaultContinuationTokenTechnicalSafetyMeasures = structuredClone(ContinuationTokenTechnicalSafetyMeasures);
		this.continuationTokenTechnicalSafetyMeasures = ContinuationTokenTechnicalSafetyMeasures;
		this.technicalSafetyMeasuresList = technicalSafetyMeasures;
		this.technicalSafetyMeasuresEntries = technicalSafetyMeasures;
		this.technicalSafetyMeasuresCollection = structuredClone(technicalSafetyMeasures);
	}

	enableFields() {
		this.startedByMe = true;
		this.worksiteEquipment.enable();
		this.organizationalMeasures.enable();
		this.cplSafetyExpert.enable();
		this.cplFirstAider.enable();
		this.cplCompanyDoctor.enable();
		this.cplSafetyOfficer.enable();
		this.cplInstructorDrivingAssignment.enable();
		this.cplInstructor.enable();
		this.cplProtectionAgainstSabotage.enable();
		this.cplSecrecyEconomy.enable();
		this.cplWeldingSupervisor.enable();
		this.cplRadiationProtection.enable();
		this.cplAirCargo.enable();
		this.cplContactPerson.enable();
	}

	disableFields() {
		this.startedByMe = false;
		this.worksiteEquipment.disable();
		this.organizationalMeasures.disable();
		this.cplSafetyExpert.disable();
		this.cplFirstAider.disable();
		this.cplCompanyDoctor.disable();
		this.cplSafetyOfficer.disable();
		this.cplInstructorDrivingAssignment.disable();
		this.cplInstructor.disable();
		this.cplProtectionAgainstSabotage.disable();
		this.cplSecrecyEconomy.disable();
		this.cplWeldingSupervisor.disable();
		this.cplRadiationProtection.disable();
		this.cplAirCargo.disable();
		this.cplContactPerson.disable();
	}

	onPaneClick(num: number) {
		if (this.step.includes(num)) {
			this.step = this.step.filter((x) => x !== num);
		} else {
			this.step.push(num);
		}
	}

	autoGrowTextZone(e: KeyboardEvent) {
		if (!e.target) return;
		(e.target as HTMLElement).style.height = '0px';
		(e.target as HTMLElement).style.height = (e.target as HTMLElement).scrollHeight + 0 + 'px';
	}

	openDrawer(
		event: Event,
		drawer: 'equipments' | 'organizationalMeasures' | 'technicalSafetyMeasures' | 'users',
		field?: string,
		id?: number
	): void {
		if (!this.startedByMe) return;
		event.stopPropagation();

		this.drawerType = drawer;
		this.visible = true;
		this.resetAllContinuationTokens();
		if (drawer === 'equipments') {
			this.equipmentsDrawerEntries = structuredClone(this.defaultEquipments);
			this.matchedEquipments =
				this.worksiteEquipment.value!.map((equipment) => ({
					primaryKey: equipment.toolsAndEquipment.primaryKey,
					name: equipment.toolsAndEquipment.name
				})) || [];
			this.drawerTitle = this.translate('workOperatingEquipment');
		} else if (drawer === 'organizationalMeasures') {
				this.organizationalMeasuresEntries = structuredClone(this.defaultOrganizationalMeasures);
				this.matchedOrganizationMeasures = this.organizationalMeasures.value!.map((orgaMeasures) => ({
					primaryKey: orgaMeasures.primaryKey,
					name: orgaMeasures.name
				})) || [];
		} else if (drawer === 'technicalSafetyMeasures') {
			this.technicalSafetyMeasuresEntries = structuredClone(this.technicalSafetyMeasuresList);
			const equipmentSafetyMeasures =
				this.worksiteEquipment.value?.find((x) => x.toolsAndEquipment.primaryKey === id)?.technicalSafetyMeasures || [];
			this.tempTechnicalSafetyMeasures = equipmentSafetyMeasures.map((x) => x.primaryKey);
			this.distinctAddNewTechnicalSafetyMeasures(equipmentSafetyMeasures);
			this.drawerTitle = this.translate('technicalSafetyMeasures');
			this.tempEquipment = id!;
		} else if (drawer === 'users') {
			this.userList = structuredClone(this.defaultUsers);
			switch (field) {
				case 'cplSafetyExpert':
					this.tempUser = this.cplSafetyExpert.value?.primaryKey || null;
					this.drawerUserField = 'cplSafetyExpert';
					this.drawerTitle = this.translate('safetySpecialist');
					break;
				case 'cplFirstAider':
					this.tempUser = this.cplFirstAider.value?.primaryKey || null;
					this.drawerUserField = 'cplFirstAider';
					this.drawerTitle = this.translate('firstAider');
					break;
				case 'cplCompanyDoctor':
					this.tempUser = this.cplCompanyDoctor.value?.primaryKey || null;
					this.drawerUserField = 'cplCompanyDoctor';
					this.drawerTitle = this.translate('companyMedicalOfficer');
					break;
				case 'cplSafetyOfficer':
					this.tempUser = this.cplSafetyOfficer.value?.primaryKey || null;
					this.drawerUserField = 'cplSafetyOfficer';
					this.drawerTitle = this.translate('safetyOfficer');
					break;
				case 'cplInstructorDrivingAssignment':
					this.tempUser = this.cplInstructorDrivingAssignment.value?.primaryKey || null;
					this.drawerUserField = 'cplInstructorDrivingAssignment';
					this.drawerTitle = this.translate('preceptorDriving');
					break;
				case 'cplInstructor':
					this.tempUser = this.cplInstructor.value?.primaryKey || null;
					this.drawerUserField = 'cplInstructor';
					this.drawerTitle = this.translate('cplInstructor');
					break;
				case 'cplProtectionAgainstSabotage':
					this.tempUser = this.cplProtectionAgainstSabotage.value?.primaryKey || null;
					this.drawerUserField = 'cplProtectionAgainstSabotage';
					this.drawerTitle = this.translate('protectionAgainstSabotage');
					break;
				case 'cplSecrecyEconomy':
					this.tempUser = this.cplSecrecyEconomy.value?.primaryKey || null;
					this.drawerUserField = 'cplSecrecyEconomy';
					this.drawerTitle = this.translate('secrecyEconomy');
					break;
				case 'cplWeldingSupervisor':
					this.tempUser = this.cplWeldingSupervisor.value?.primaryKey || null;
					this.drawerUserField = 'cplWeldingSupervisor';
					this.drawerTitle = this.translate('weldingSupervisor');
					break;
				case 'cplRadiationProtection':
					this.tempUser = this.cplRadiationProtection.value?.primaryKey || null;
					this.drawerUserField = 'cplRadiationProtection';
					this.drawerTitle = this.translate('radiationProtection');
					break;
				case 'cplAirCargo':
					this.tempUser = this.cplAirCargo.value?.primaryKey || null;
					this.drawerUserField = 'cplAirCargo';
					this.drawerTitle = this.translate('airCargo');
					break;
				case 'cplContactPerson':
					this.tempUser = this.cplContactPerson.value?.primaryKey || null;
					this.drawerUserField = 'cplContactPerson';
					this.drawerTitle = this.translate('contactPerson');
					break;
				default:
					break;
			}
		}
	}

	async applyFilter(field: string) {
		if (!this.searchFilter.value) {
			this.equipmentsDrawerEntries = structuredClone(this.defaultEquipments);
			this.organizationalMeasuresEntries = structuredClone(this.defaultOrganizationalMeasures);
			this.userList = structuredClone(this.defaultUsers);
			this.technicalSafetyMeasuresEntries = structuredClone(this.technicalSafetyMeasuresList);
			this.resetAllContinuationTokens();
			return;
		}

		const value = this.searchFilter.value.toLowerCase();

		switch (field) {
			case 'worksiteEquipment':
				const { equipments, continuationToken: equipToken } = await this.visitService.getEquipments(undefined, value);
				this.continuationTokenEquip = equipToken;
				this.equipmentsDrawerEntries = structuredClone(equipments);
				break;
			case 'organizationalMeasures':
				const {organizationalMeasures, continuationToken: orgaMeasuresToken} = await this.visitService.getOrganizationalMeasures(undefined, value);
				this.continuationTokenOrganizationalMeasures = orgaMeasuresToken;
				this.organizationalMeasuresEntries = structuredClone(organizationalMeasures);
				break;
			case 'users':
				const users = this.defaultUsers;
				const filteredUsers = users.filter((item: Partial<Person>) => item.name?.toLowerCase().includes(value));
				this.userList = structuredClone(filteredUsers);
				break;
			case 'technicalSafetyMeasures':
				const {technicalSafetyMeasures, continuationToken: continuationTokenTechnicalSafetyMeasures} = await this.visitService.getTechnicalSafetyMeasures(undefined, value);
				this.continuationTokenTechnicalSafetyMeasures = continuationTokenTechnicalSafetyMeasures;
				this.technicalSafetyMeasuresEntries = structuredClone(technicalSafetyMeasures);
				this.distinctAddNewTechnicalSafetyMeasures(technicalSafetyMeasures);

				break;
			default:
				break;
		}
	}

	private resetAllContinuationTokens() {
		this.continuationTokenEquip = structuredClone(this.defaultTokenEquip);
		this.continuationTokenOrganizationalMeasures = structuredClone(this.defaultTokenOrganizationalMeasures);
		this.continuationTokenTechnicalSafetyMeasures = structuredClone(this.defaultContinuationTokenTechnicalSafetyMeasures);
	}

	onDrawerCheck(value: string | number | EquipmentData | OrganizationalMeasure, field: string) {
		switch (field) {
			case 'equipments':
				const obj = value as EquipmentData;
				const exists = this.matchedEquipments.find((x) => x.primaryKey === obj.primaryKey);
				if (exists) this.matchedEquipments = this.matchedEquipments.filter((x) => x.primaryKey !== obj.primaryKey);
				else this.matchedEquipments.push(value as EquipmentData);

				break;
			case 'organizationalMeasures':
				const orgaMeasureValue = value as OrganizationalMeasure;
				const orgaMeasuresExists = this.matchedOrganizationMeasures.find((x) => x.primaryKey === orgaMeasureValue.primaryKey);
				if(orgaMeasuresExists){
					this.matchedOrganizationMeasures = this.matchedOrganizationMeasures.filter((x) => x.primaryKey !== orgaMeasureValue.primaryKey);
				} 
				else{this.matchedOrganizationMeasures.push(value as OrganizationalMeasure);}
				break;
			case 'technicalSafetyMeasures':
				if (!this.tempTechnicalSafetyMeasures.includes(value as number)) this.tempTechnicalSafetyMeasures.push(value as number);
				else this.tempTechnicalSafetyMeasures = this.tempTechnicalSafetyMeasures.filter((x) => x !== value);
				break;
			case 'users':
				this.tempUser = value as number;
				break;
			default:
				break;
		}
	}

	isChecked(entry: number, field: string): boolean {
		switch (field){
			case 'equipments':
				return !!this.worksiteEquipment.value?.find((x) => x.toolsAndEquipment.primaryKey === entry);
			case 'organisationalMeasures':
				return !!this.organizationalMeasures.value?.find((x) => x.primaryKey === entry);
			default:
				return false;
		}
		
	}

	closeDrawer(): void {
		this.visible = false;
		this.drawerType = '';
		this.drawerTitle = '';
		this.searchFilter.setValue('');
		this.organizationalMeasuresEntries = structuredClone(this.defaultOrganizationalMeasures);
		this.matchedOrganizationMeasures = structuredClone(this.defaultOrganizationalMeasures);
		this.technicalSafetyMeasuresEntries = structuredClone(this.technicalSafetyMeasuresList);
	}

	onSubmit(field?: string) {
		if (this.drawerType === 'equipments') {
			const newEquipments = this.matchedEquipments.map((x) => {
				const equipmentObj = this.worksiteEquipment.value!.find((y) => {
					return y.toolsAndEquipment.primaryKey === x.primaryKey;
				});

				return {
					primaryKey: equipmentObj?.primaryKey || 0,
					toolsAndEquipment: {
						primaryKey: x.primaryKey,
						name: x.name
					},
					technicalSafetyMeasures: equipmentObj?.technicalSafetyMeasures || undefined
				};
			});

			this.worksiteEquipment.setValue(newEquipments);

			this.setVisit('workplaceToolsAndEquipment');
		} else if (this.drawerType === 'organizationalMeasures') {
			const newOrgaMeasures = this.matchedOrganizationMeasures.map((x) => ({
				primaryKey: x.primaryKey,
				name: x.name
			}));
			this.organizationalMeasures.setValue(newOrgaMeasures);
			field = "workplaceOrganisationalMeasures";
			this.setVisit('workplaceOrganisationalMeasures');
		} else if (this.drawerType === 'technicalSafetyMeasures') {
			const newTechnicalSafety: any[] = this.technicalSafetyMeasuresCollection.filter((x) =>
				this.tempTechnicalSafetyMeasures.includes(x.primaryKey)
			);
			const newWorksiteEquipment = this.worksiteEquipment.value!.map((x) => {
				if (x.toolsAndEquipment.primaryKey === this.tempEquipment) {
					return {
						...x,
						technicalSafetyMeasures: newTechnicalSafety
					};
				}
				return x;
			});

			this.worksiteEquipment.setValue(newWorksiteEquipment);
			this.setVisit('workplaceToolsAndEquipment');
		} else if (this.drawerType === 'users') {
			switch (this.drawerUserField) {
				case 'cplSafetyExpert':
					const cplSafetyExpert = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplSafetyExpert.setValue(cplSafetyExpert || null);
					this.tempUser = null;
					this.setVisit('cplSafetyExpert');
					break;
				case 'cplFirstAider':
					const cplFirstAider = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplFirstAider.setValue(cplFirstAider || null);
					this.tempUser = null;
					this.setVisit('cplFirstAider');
					break;
				case 'cplCompanyDoctor':
					const cplCompanyDoctor = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplCompanyDoctor.setValue(cplCompanyDoctor || null);
					this.tempUser = null;
					this.setVisit('cplCompanyDoctor');
					break;
				case 'cplSafetyOfficer':
					const cplSafetyOfficer = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplSafetyOfficer.setValue(cplSafetyOfficer || null);
					this.tempUser = null;
					this.setVisit('cplSafetyOfficer');
					break;
				case 'cplInstructorDrivingAssignment':
					const cplInstructorDrivingAssignment = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplInstructorDrivingAssignment.setValue(cplInstructorDrivingAssignment || null);
					this.tempUser = null;
					this.setVisit('cplInstructorDrivingAssignment');
					break;
				case 'cplInstructor':
					const cplInstructor = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplInstructor.setValue(cplInstructor || null);
					this.tempUser = null;
					this.setVisit('cplInstructor');
					break;
				case 'cplProtectionAgainstSabotage':
					const cplProtectionAgainstSabotage = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplProtectionAgainstSabotage.setValue(cplProtectionAgainstSabotage || null);
					this.tempUser = null;
					this.setVisit('cplProtectionAgainstSabotage');
					break;
				case 'cplSecrecyEconomy':
					const cplSecrecyEconomy = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplSecrecyEconomy.setValue(cplSecrecyEconomy || null);
					this.tempUser = null;
					this.setVisit('cplSecrecyEconomy');
					break;
				case 'cplWeldingSupervisor':
					const cplWeldingSupervisor = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplWeldingSupervisor.setValue(cplWeldingSupervisor || null);
					this.tempUser = null;
					this.setVisit('cplWeldingSupervisor');
					break;
				case 'cplRadiationProtection':
					const cplRadiationProtection = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplRadiationProtection.setValue(cplRadiationProtection || null);
					this.tempUser = null;
					this.setVisit('cplRadiationProtection');
					break;
				case 'cplAirCargo':
					const cplAirCargo = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplAirCargo.setValue(cplAirCargo || null);
					this.tempUser = null;
					this.setVisit('cplAirCargo');
					break;
				case 'cplContactPerson':
					const cplContactPerson = this.userList.find((x) => x.primaryKey === this.tempUser);
					this.cplContactPerson.setValue(cplContactPerson || null);
					this.tempUser = null;
					this.setVisit('cplContactPerson');
					break;
				default:
					break;
			}
		}

		this.visible = false;
		this.drawerType = '';
		this.drawerTitle = '';
		this.searchFilter.setValue('');
	}

	onDeleteItem(element: Equipment | OrganizationalMeasure, field: 'equipments' | 'organizationalMeasures' | 'technicalSafetyMeasures') {
		if (!this.startedByMe) return;

		if (field === 'equipments') {
			const tempEquipment: Equipment = element as Equipment;
			const equipmentsArray: Equipment[] = this.worksiteEquipment.value!;
			this.worksiteEquipment.setValue(
				equipmentsArray.filter((x) => x.toolsAndEquipment.primaryKey !== tempEquipment.toolsAndEquipment.primaryKey)
			);
			this.setVisit('workplaceToolsAndEquipment');
		} else {
			const tempOrganizationalMeasures: OrganizationalMeasure = element as OrganizationalMeasure;
			const organizationalMeasuresArray: OrganizationalMeasure[] = this.organizationalMeasures.value!;
			this.organizationalMeasures.setValue(
				organizationalMeasuresArray.filter((x) => x.primaryKey !== tempOrganizationalMeasures.primaryKey)
			);
			this.setVisit('workplaceOrganisationalMeasures');
		}
	}

	onDeleteTechnicalSafetyMeasure(equipmentId: number, technicalSafetyMeasureId: number) {
		if (!this.startedByMe) return;

		const newWorksiteEquipment = this.worksiteEquipment.value!.map((x) => {
			if (x.toolsAndEquipment.primaryKey === equipmentId) {
				const newTechnicalSafetyMeasures = x.technicalSafetyMeasures
					? x.technicalSafetyMeasures.filter((y) => y.primaryKey !== technicalSafetyMeasureId)
					: [];
				return {
					...x,
					technicalSafetyMeasures: newTechnicalSafetyMeasures
				};
			}
			return x;
		});

		this.worksiteEquipment.setValue(newWorksiteEquipment);
		this.setVisit('workplaceToolsAndEquipment');
	}

	onDeleteUser(field: string) {
		if (!this.startedByMe) return;

		switch (field) {
			case 'cplSafetyExpert':
				this.cplSafetyExpert.setValue(null);
				this.setVisit('cplSafetyExpert');
				break;
			case 'cplFirstAider':
				this.cplFirstAider.setValue(null);
				this.setVisit('cplFirstAider');
				break;
			case 'cplCompanyDoctor':
				this.cplCompanyDoctor.setValue(null);
				this.setVisit('cplCompanyDoctor');
				break;
			case 'cplSafetyOfficer':
				this.cplSafetyOfficer.setValue(null);
				this.setVisit('cplSafetyOfficer');
				break;
			case 'cplInstructorDrivingAssignment':
				this.cplInstructorDrivingAssignment.setValue(null);
				this.setVisit('cplInstructorDrivingAssignment');
				break;
			case 'instructor':
				this.cplInstructor.setValue(null);
				this.setVisit('cplInstructor');
				break;
			case 'cplProtectionAgainstSabotage':
				this.cplProtectionAgainstSabotage.setValue(null);
				this.setVisit('cplProtectionAgainstSabotage');
				break;
			case 'cplSecrecyEconomy':
				this.cplSecrecyEconomy.setValue(null);
				this.setVisit('cplSecrecyEconomy');
				break;
			case 'cplWeldingSupervisor':
				this.cplWeldingSupervisor.setValue(null);
				this.setVisit('cplWeldingSupervisor');
				break;
			case 'cplRadiationProtection':
				this.cplRadiationProtection.setValue(null);
				this.setVisit('cplRadiationProtection');
				break;
			case 'cplAirCargo':
				this.cplAirCargo.setValue(null);
				this.setVisit('cplAirCargo');
				break;
			case 'cplContactPerson':
				this.cplContactPerson.setValue(null);
				this.setVisit('cplContactPerson');
				break;
			default:
				break;
		}
	}

	setVisit(field: string) {
		const visit = this.visitService.getStoredVisit();
		let visitField: FormControl | undefined;

		switch (field) {
			case 'workplaceToolsAndEquipment':
				visitField = this.worksiteEquipment;
				break;
			case 'workplaceOrganisationalMeasures':
				visitField = this.organizationalMeasures;
				break;
			case 'cplSafetyExpert':
				visitField = this.cplSafetyExpert;
				break;
			case 'cplFirstAider':
				visitField = this.cplFirstAider;
				break;
			case 'cplCompanyDoctor':
				visitField = this.cplCompanyDoctor;
				break;
			case 'cplCompanyDoctor':
				visitField = this.cplCompanyDoctor;
				break;
			case 'cplInstructorDrivingAssignment':
				visitField = this.cplInstructorDrivingAssignment;
				break;
			case 'cplInstructor':
				visitField = this.cplInstructor;
				break;
			case 'workplaceToolsAndEquipment':
				visitField = this.worksiteEquipment;
				break;
			case 'cplProtectionAgainstSabotage':
				visitField = this.cplProtectionAgainstSabotage;
				break;
			case 'cplSecrecyEconomy':
				visitField = this.cplSecrecyEconomy;
				break;
			case 'cplWeldingSupervisor':
				visitField = this.cplWeldingSupervisor;
				break;
			case 'cplRadiationProtection':
				visitField = this.cplRadiationProtection;
				break;
			case 'cplAirCargo':
				visitField = this.cplAirCargo;
				break;
			case 'cplContactPerson':
				visitField = this.cplContactPerson;
				break;
			default:
				break;
		}

		this.visitService.setVisit({
			...visit,
			workplace: {
				...visit?.workplace,
				[field]: visitField?.value
			},
			hasUnsavedFields: true
		} as Visit);
	}
}
