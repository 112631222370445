<div class="tj-personal-measures">
	<div class="tj-accordion">
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(0)" (opened)="onPaneClick(0)"
				(closed)="onPaneClick(0)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'riskFactors' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': risks.length > 0,
								'tj-rotate-180': step.includes(0)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div *ngFor="let risk of risks">
					<p style="font-weight: bold; font-size: 1.025rem;">{{risk.risk.name}}</p>
					<p style="font-size: 0.75rem;">{{risk.description ? risk.description : ""}}
					</p>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(1)" (opened)="onPaneClick(1)"
				(closed)="onPaneClick(1)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'ppe' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': ppe.length > 0,
								'tj-rotate-180': step.includes(1)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="tj-row-align-right">
					<span>{{ 'ppeBorrower' | translate }}</span>
				</div>
				<div class="tj-checklist">
					<div class="tj-checkbox-row" *ngFor="let ppe of ppe">
						<p>{{ppe.ppe.name}}</p>
						<mat-checkbox class="tj-checkbox" [checked]="ppe.isProvidedByBorrower"
							(change)="onCheckChange($event, ppe.ppe.primaryKey, 'ppe')" [disabled]="!startedByMe"
							disableRipple></mat-checkbox>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion class="tj-adjust-bottom">
			<mat-expansion-panel hideToggle [expanded]="step.includes(2)" (opened)="onPaneClick(2)"
				(closed)="onPaneClick(2)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'occupationalMedicine' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': workplacePrecautions.length > 0,
								'tj-rotate-180': step.includes(2)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="tj-row-align-right">
					<span>{{ 'occupationalMedicineBorrower' | translate }}</span>
				</div>
				<div class="tj-checklist">
					<div class="tj-checkbox-row" *ngFor="let precaution of workplacePrecautions">
						<p>{{precaution.precaution.name}}</p>
						<mat-checkbox class="tj-checkbox" [checked]="precaution.isProvidedByBorrower"
							(change)="onCheckChange($event, precaution.precaution.primaryKey, 'precaution')"
							[disabled]="!startedByMe" disableRipple></mat-checkbox>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
