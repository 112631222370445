<h2 mat-dialog-title class="header">Es fehlen Gründe für nicht vorhandene Präventionen</h2>
<mat-dialog-content>
<div *ngFor="let item of data.notApplicablePreventionsWithoutReason">
    <div *ngIf="item.isRootCategoryName==true;else noRootCategoryName">
        <div class="spacer">{{item.name}}</div> 
    </div>
    <ng-template #noRootCategoryName>
        <ul>
            <li>
                {{ item.name }}
            </li>
        </ul>
    </ng-template>
</div>
</mat-dialog-content>

