import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { UserService } from 'src/app/services/user.service';
import { Ppe, Risk, Visit, VisitService, WorkplacePrecaution } from 'src/app/services/visit.service';
import { Subscription } from 'rxjs';

@Component({
	standalone: true,
	selector: 'app-personal-measures',
	templateUrl: './personal-measures.component.html',
	styleUrls: ['./personal-measures.component.scss'],
	imports: [MatExpansionModule, MatInputModule, CommonModule, ReactiveFormsModule, MatCheckboxModule, TranslatePipe]
})
export class PersonalMeasuresComponent implements OnInit, OnDestroy {
	screenService: Subscription | null = null;
	step: number[] = [];
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	startedByMe: boolean = false;

	risks: Risk[] = [];
	ppe: Ppe[] = [];
	workplacePrecautions: WorkplacePrecaution[] = [];

	constructor(private visitService: VisitService, private userService: UserService) {
		const visit = this.visitService.getStoredVisit();
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.startedByMe = visit?.executedByUserId === this.userService.getUser()?.sub && visit?.status !== 'StartRequested' && visit?.status !== 'Finished';
	}

	ngOnInit(): void {
		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			this.risks = visit?.workplaceRisks || [];

			let ppeMap: Map<number, Ppe> = new Map();
			let workplacePrecautionMap: Map<number, WorkplacePrecaution> = new Map();

			this.risks.forEach((risk) => {
				if (risk.workplacePpe) {
					risk.workplacePpe.forEach((ppe) => {
						ppeMap.set(ppe.ppe.primaryKey, {
							primaryKey: ppe.primaryKey,
							ppe: ppe.ppe,
							isProvidedByBorrower: ppe.isProvidedByBorrower
						});
					});
				}
				if (risk.workplacePrecaution) {
					risk.workplacePrecaution.forEach((precaution) => {
						workplacePrecautionMap.set(precaution.precaution.primaryKey, {
							primaryKey: precaution.primaryKey,
							precaution: precaution.precaution,
							isProvidedByBorrower: precaution.isProvidedByBorrower
						});
					});
				}
			});

			this.ppe = Array.from(ppeMap.values());
			this.workplacePrecautions = Array.from(workplacePrecautionMap.values());
		});
	}

	ngOnDestroy(): void {
		this.screenService?.unsubscribe();
	}

	onPaneClick(num: number) {
		if (this.step.includes(num)) {
			this.step = this.step.filter((x) => x !== num);
		} else {
			this.step.push(num);
		}
	}

	onCheckChange(event: MatCheckboxChange, primaryKey: number, field: string) {
		if (!this.startedByMe) return;

		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		let newRisks: Risk[] = [];

		if (field === 'ppe') {
			newRisks = visit.workplaceRisks.map((risk) => {
				const newPpe = risk.workplacePpe?.map((ppe) => {
					if (ppe.ppe.primaryKey === primaryKey) {
						ppe.isProvidedByBorrower = event.checked;
					}
					return ppe;
				});
				return { ...risk, workplacePpe: newPpe };
			});
		}

		if (field === 'precaution') {
			newRisks = visit.workplaceRisks.map((risk) => {
				const newPrecautions = risk.workplacePrecaution?.map((precaution) => {
					if (precaution.precaution.primaryKey === primaryKey) {
						precaution.isProvidedByBorrower = event.checked;
					}
					return precaution;
				});
				return { ...risk, workplacePrecaution: newPrecautions };
			});
		}

		this.visitService.setVisit({
			...visit,
			workplaceRisks: newRisks,
			hasUnsavedFields: true
		} as Visit);
	}
}
