<header *ngIf="shouldRender">
	<div class="tj-header">
		<div class="tj-header-inner">
			<div class="tj-header-logo">
				<a href="/"><img src={{logo}} alt="Time Job" /></a>
			</div>
			<button [matMenuTriggerFor]="beforeMenu" class="tj-header-menu">{{ initials }}</button>
			<mat-menu #beforeMenu="matMenu" xPosition="before">
				<button mat-menu-item (click)="goto('/profile')">
					<div class="tj-header-menu__item">
						<i class="moon-user"></i>
						<span>{{ 'profile' | translate }}</span>
					</div>
				</button>
				<mat-divider></mat-divider>
				<button mat-menu-item (click)="signout()">
					<div class="tj-header-menu__item">
						<i class="moon-home"></i>
						<span>{{ 'logout' | translate }}</span>
					</div>
				</button>
			</mat-menu>
		</div>
	</div>
</header>