<!-- TO-Maßnahmen -->
<div class="tj-worksite">
	<!-- Mat Accordions -->
	<div class="tj-accordion">
		<!-- Arbeits und Betriebsmittel -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(0)" (opened)="onPaneClick(0)"
				(closed)="onPaneClick(0)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workOperatingEquipment' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'equipments')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': worksiteEquipment.value?.length! > 0 || false,
								'tj-rotate-180': step.includes(0)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-list-item" *ngFor="let item of worksiteEquipment.value">
					<div class="tj-list-item__item">
						<p class="tj-item-name">{{item.toolsAndEquipment.name}}</p>
						<i class="moon-bin" style="font-size: 1.5rem;" (click)="onDeleteItem(item, 'equipments')"></i>
					</div>
					<div class="tj-list-item__actions">
						<button class="tj-btn tj-btn__icon"
							(click)="openDrawer($event, 'technicalSafetyMeasures', undefined, item.toolsAndEquipment.primaryKey)">
							<i class="moon-plus-button"></i>
							<div>{{ 'measures' | translate}}</div>
						</button>
					</div>
					<div class="tj-list-item__children" *ngFor="let children of item.technicalSafetyMeasures">
						<div class="tj-list-item__item">
							<p class="tj-item-name ml-1">{{children.name}}</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteTechnicalSafetyMeasure(item.toolsAndEquipment.primaryKey, children.primaryKey)"></i>
						</div>
					</div>
				</div>
				<span *ngIf="worksiteEquipment.value?.length === 0">{{ 'noEquipmentSelected' | translate }}</span>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Organisatorische Maßnahmen -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(1)" (opened)="onPaneClick(1)"
				(closed)="onPaneClick(1)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'organizationalMeasures' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'organizationalMeasures')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': organizationalMeasures.value?.length! > 0 || false,
								'tj-rotate-180': step.includes(1)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-list-item" *ngFor="let item of organizationalMeasures.value">
					<div class="tj-list-item__item">
						<p class="tj-item-name">{{item.name}}</p>
						<i class="moon-bin" style="font-size: 1.5rem;"
							(click)="onDeleteItem(item, 'organizationalMeasures')"></i>
					</div>
				</div>
				<span *ngIf="organizationalMeasures.value?.length === 0">{{ 'noOrganizationalMeasuresSelected' |
					translate }}</span>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Ansprechperson -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(2)" (opened)="onPaneClick(2)"
				(closed)="onPaneClick(2)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplContactPerson' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplContactPerson')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplContactPerson.value,
								'tj-rotate-180': step.includes(2)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplContactPerson.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplContactPerson.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplContactPerson')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplContactPerson.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Betriebsarzt -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(5)" (opened)="onPaneClick(5)"
				(closed)="onPaneClick(5)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'companyMedicalOfficer' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplCompanyDoctor')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplCompanyDoctor.value,
								'tj-rotate-180': step.includes(5)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplCompanyDoctor.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplCompanyDoctor.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplCompanyDoctor')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplCompanyDoctor.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Ersthelfer -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(4)" (opened)="onPaneClick(4)"
				(closed)="onPaneClick(4)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'firstAider' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplFirstAider')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplFirstAider.value,
								'tj-rotate-180': step.includes(4)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplFirstAider.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplFirstAider.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;" (click)="onDeleteUser('cplFirstAider')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplFirstAider.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelectedd' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Unterweiser Fahrbeauftragung -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(7)" (opened)="onPaneClick(7)"
				(closed)="onPaneClick(7)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'preceptorDriving' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplInstructorDrivingAssignment')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
										'tj-color__primary': cplInstructorDrivingAssignment.value,
										'tj-rotate-180': step.includes(7)
									}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplInstructorDrivingAssignment.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplInstructorDrivingAssignment.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplInstructorDrivingAssignment')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplInstructorDrivingAssignment.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Unterweiser -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(8)" (opened)="onPaneClick(8)"
				(closed)="onPaneClick(8)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplInstructor' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplInstructor')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
										'tj-color__primary': cplInstructor.value,
										'tj-rotate-180': step.includes(8)
									}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplInstructor.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplInstructor.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;" (click)="onDeleteUser('cplInstructor')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplInstructor.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Sicherheitsfachkraft -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(3)" (opened)="onPaneClick(3)"
				(closed)="onPaneClick(3)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'safetySpecialist' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplSafetyExpert')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplSafetyExpert.value,
								'tj-rotate-180': step.includes(3)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplSafetyExpert.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplSafetyExpert.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplSafetyExpert')"></i>
						</div>
						<div class="tj-list-item">
							<div class="tj-list-item__item">
								<p class="tj-item-name">
									<i class="moon-phone-filled"></i>
									{{cplSafetyExpert.value.phoneNumber}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Sicherheitsbeauftragter -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(6)" (opened)="onPaneClick(6)"
				(closed)="onPaneClick(6)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'safetyOfficer' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplSafetyOfficer')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplSafetyOfficer.value,
								'tj-rotate-180': step.includes(6)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplSafetyOfficer.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplSafetyOfficer.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplSafetyOfficer')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplSafetyOfficer.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Schweißaufsicht -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(11)" (opened)="onPaneClick(11)"
				(closed)="onPaneClick(11)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplWeldingSupervisor' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplWeldingSupervisor')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplWeldingSupervisor.value,
								'tj-rotate-180': step.includes(11)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplWeldingSupervisor.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplWeldingSupervisor.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplWeldingSupervisor')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplWeldingSupervisor.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Strahlenschutzbeauftragter -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(12)" (opened)="onPaneClick(12)"
				(closed)="onPaneClick(12)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplRadiationProtection' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplRadiationProtection')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
										'tj-color__primary': cplRadiationProtection.value,
										'tj-rotate-180': step.includes(12)
									}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplRadiationProtection.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplRadiationProtection.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplRadiationProtection')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplRadiationProtection.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- SIB Luftfrachtsicherheit -->
		<mat-accordion class="tj-adjust-bottom">
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(13)" (opened)="onPaneClick(13)"
				(closed)="onPaneClick(13)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplAirCargo' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplAirCargo')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
										'tj-color__primary': cplAirCargo.value,
										'tj-rotate-180': step.includes(13)
									}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplAirCargo.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplAirCargo.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;" (click)="onDeleteUser('cplAirCargo')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplAirCargo.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Sabotageschutz -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(9)" (opened)="onPaneClick(9)"
				(closed)="onPaneClick(9)">
				<!-- Expansion Panel Header -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplProtectionAgainstSabotage' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplProtectionAgainstSabotage')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplProtectionAgainstSabotage.value,
								'tj-rotate-180': step.includes(9)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplProtectionAgainstSabotage.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplProtectionAgainstSabotage.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplProtectionAgainstSabotage')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplProtectionAgainstSabotage.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
		<!-- Geheimhaltung i. d. Wirtschaft -->
		<mat-accordion>
			<!-- Expansion Panel -->
			<mat-expansion-panel hideToggle [expanded]="step.includes(10)" (opened)="onPaneClick(10)"
				(closed)="onPaneClick(10)">
				<!-- Expansion Panel -->
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'cplSecrecyEconomy' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'users', 'cplSecrecyEconomy')"
							class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': cplSecrecyEconomy.value,
								'tj-rotate-180': step.includes(10)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- Expansion Panel Content -->
				<div class="tj-user-block" *ngIf="cplSecrecyEconomy.value; else noUser">
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-user-filled"></i>
								{{cplSecrecyEconomy.value.name}}
							</p>
							<i class="moon-bin" style="font-size: 1.5rem;"
								(click)="onDeleteUser('cplSecrecyEconomy')"></i>
						</div>
					</div>
					<div class="tj-list-item">
						<div class="tj-list-item__item">
							<p class="tj-item-name">
								<i class="moon-phone-filled"></i>
								{{cplSecrecyEconomy.value.phoneNumber}}
							</p>
						</div>
					</div>
				</div>
				<!-- if no user is selected -->
				<ng-template #noUser>
					<span>{{ 'noUserSelected' | translate }}</span>
				</ng-template>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
	<!-- Drawers -->
	<!-- Arbeits-/Betriebsmittel -->
	<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType==='equipments'" [nzFooter]="footerTpl"
		nzPlacement="right" [nzTitle]="drawerTitle" (nzOnClose)="closeDrawer()"
		[nzWidth]="device === 'mobile' ? '100%' : 400">
		<ng-container *nzDrawerContent>
			<div id="tj-worksiteEquipment-list">
				<div class="tj-search-bar">
					<label class="tj-search-icon">{{ 'searchByTitle' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" [formControl]="searchFilter"
							(keydown.enter)="applyFilter('worksiteEquipment')" matInput
							[placeholder]="'searchByTitle' | translate">
						<button type="button" (click)="applyFilter('worksiteEquipment')" mat-icon-button matPrefix
							[attr.aria-label]="'Search equipments'">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
				</div>
				<!-- Checkboxen -->
				<mat-checkbox [checked]="isChecked(item.primaryKey, 'equipments')" *ngFor="let item of equipmentsDrawerEntries"
					(change)="onDrawerCheck(item, 'equipments')" style="display: block;"
					disableRipple>{{item.name}}</mat-checkbox>
			</div>
		</ng-container>
		<ng-template #footerTpl>
			<div style="display: flex;gap: 1rem;justify-content: center;">
				<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
					<i class="moon-cross-filled"></i>
					<span>{{ 'cancel' | translate }}</span>
				</button>
				<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit(undefined)">
					<i class="moon-check-filled"></i>
					<span>{{ 'submit' | translate }}</span>
				</button>
			</div>
		</ng-template>
	</nz-drawer>
	<!-- Organisatorische Maßnahmen -->
	<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType==='organizationalMeasures'"
		[nzFooter]="footerTpl" nzPlacement="right" [nzTitle]="drawerTitle" (nzOnClose)="closeDrawer()"
		[nzWidth]="device === 'mobile' ? '100%' : 400">
		<ng-container *nzDrawerContent>
			<!-- Drawer Content -->
			<div class="tj-drawer-content" id="tj-organizationalMeasures-list">
				<!-- SearchBar -->
				<div class="tj-search-bar">
					<label class="tj-search-icon">{{ 'searchByTitle' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" [formControl]="searchFilter"
							(keydown.enter)="applyFilter('organizationalMeasures')" matInput
							[placeholder]="'searchByTitle' | translate">
						<button type="button" (click)="applyFilter('organizationalMeasures')" mat-icon-button matPrefix
							[attr.aria-label]="'Search equipments'">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
				</div>
				<!-- Checkboxen -->
				<mat-checkbox [checked]="isChecked(item.primaryKey, 'organisationalMeasures')"
					*ngFor="let item of organizationalMeasuresEntries"
					(change)="onDrawerCheck(item, 'organizationalMeasures')" style="display: block;"
					disableRipple>{{item.name}}</mat-checkbox>
			</div>
		</ng-container>
		<!-- Footer -->
		<ng-template #footerTpl>
			<div style="display: flex;gap: 1rem;justify-content: center;">
				<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
					<i class="moon-cross-filled"></i>
					<span>{{ 'cancel' | translate }}</span>
				</button>
				<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit(drawerType === 'users' ?
					drawerUserField : undefined)">
					<i class="moon-check-filled"></i>
					<span>{{ 'submit' | translate }}</span>
				</button>
			</div>
		</ng-template>
	</nz-drawer>
	<!-- Technical Measures for Arbeits und Betriebsmittel -->
	<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType==='technicalSafetyMeasures'"
		[nzFooter]="footerTpl" nzPlacement="right" [nzTitle]="drawerTitle" (nzOnClose)="closeDrawer()"
		[nzWidth]="device === 'mobile' ? '100%' : 400">
		<ng-container *nzDrawerContent>
			<!-- Content -->
			<div class="tj-drawer-content" id='tj-technicalSafetyMeasures-list'>
				<!-- Searchbar -->
				<div class="tj-search-bar">
					<label class="tj-search-icon">{{ 'searchByTitle' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" [formControl]="searchFilter"
							(keydown.enter)="applyFilter('technicalSafetyMeasures')" matInput
							[placeholder]="'searchByTitle' | translate">
						<button type="button" (click)="applyFilter('technicalSafetyMeasures')" mat-icon-button matPrefix
							[attr.aria-label]="'Search equipments'">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
				</div>
				<!-- Checkboxen -->
				<mat-checkbox [checked]="tempTechnicalSafetyMeasures.includes(item.primaryKey)"
					*ngFor="let item of technicalSafetyMeasuresEntries"
					(change)="onDrawerCheck(item.primaryKey, 'technicalSafetyMeasures')" style="display: block;"
					disableRipple>{{item.name}}</mat-checkbox>
			</div>
		</ng-container>
		<!-- Footer -->
		<ng-template #footerTpl>
			<div style="display: flex;gap: 1rem;justify-content: center;">
				<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
					<i class="moon-cross-filled"></i>
					<span>{{ 'cancel' | translate }}</span>
				</button>
				<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit(drawerType === 'users' ?
					drawerUserField : undefined)">
					<i class="moon-check-filled"></i>
					<span>{{ 'submit' | translate }}</span>
				</button>
			</div>
		</ng-template>
	</nz-drawer>
	<!-- All User Drawer -->
	<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType==='users'" [nzFooter]="footerTpl"
		nzPlacement="right" [nzTitle]="drawerTitle" (nzOnClose)="closeDrawer()"
		[nzWidth]="device === 'mobile' ? '100%' : 400">
		<ng-container *nzDrawerContent>
			<!-- Content -->
			<div class="tj-drawer-content">
				<!-- Searchbar -->
				<div class="tj-search-bar">
					<label class="tj-search-icon">{{ 'searchByName' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" [formControl]="searchFilter" (keydown.enter)="applyFilter('users')" matInput
							[placeholder]="'searchByName' | translate">
						<button type="button" (click)="applyFilter('users')" mat-icon-button matPrefix
							[attr.aria-label]="'Search users'">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
					<!-- Checkboxen -->
					<mat-checkbox [checked]="tempUser === item.primaryKey" *ngFor="let item of userList"
						(change)="onDrawerCheck(item.primaryKey!, 'users')" style="display: block;" disableRipple>
						<div class="tj-drawer-user">
							<div class="tj-drawer-user__avatar">
								<span>{{ item.name!.split(" ")[0].charAt(0) }}{{ item.name!.split(" ").length > 1 ?
									item.name!.split(" ")[item.name!.split(" ").length - 1].charAt(0) : "" }}</span>
							</div>
							<div class="tj-drawer-user__info">
								<span class="tj-drawer-user__name">{{item.name}}</span>
							</div>
						</div>
					</mat-checkbox>
				</div>
			</div>
		</ng-container>
		<!-- Footer -->
		<ng-template #footerTpl>
			<div style="display: flex;gap: 1rem;justify-content: center;">
				<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
					<i class="moon-cross-filled"></i>
					<span>{{ 'cancel' | translate }}</span>
				</button>
				<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit(drawerUserField)">
					<i class="moon-check-filled"></i>
					<span>{{ 'submit' | translate }}</span>
				</button>
			</div>
		</ng-template>
	</nz-drawer>
</div>