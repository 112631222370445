import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { User, UserService } from 'src/app/services/user.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	standalone: true,
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	imports: [CommonModule, MatTabsModule, NzGridModule, MatInputModule, MatSelectModule, ReactiveFormsModule, TranslatePipe]
})
export class ProfileComponent implements OnInit {
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);
	user: User | null = this.userService.getUser();
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	language: FormControl = new FormControl<'en' | 'de'>(this.userService.getUser()?.language || 'en');
	hasUnsavedChanges: boolean = false;

	languages = [
		{ value: 'en', label: this.translate('english') },
		{ value: 'de', label: this.translate('german') }
	];

	constructor(
		private breadcrumbService: BreadcrumbService,
		public router: Router,
		private userService: UserService,
		private translateService: TranslateService,
		private oidcSecurityService: OidcSecurityService,
		private authService: AuthService
	) {
		this.breadcrumbService.setBreadcrumb({ locations: [{ name: this.translate('profile'), path: '/profile', icon: 'moon-user' }] });
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.user = this.userService.getUser();
	}

	ngOnInit(): void {
		this.oidcSecurityService.isAuthenticated().subscribe((isAuthenticated) => {
			if (!isAuthenticated) {
				this.router.navigate(['/auth']);
			}
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
	}

	goHome() {
		this.router.navigate(['/home']);
	}

	save() {
		this.userService.setUser({ ...this.user, language: this.language.value } as User);
		this.userService.setLanguage(this.language.value);
		location.reload();
		this.hasUnsavedChanges = false;
	}

	cancel() {
		this.language.setValue(this.user?.language || 'de');
		this.hasUnsavedChanges = false;
	}

	logout = () => {
		try {
			this.authService.logout();
		} catch (error: unknown) {}
	};
}
