import { Injectable } from '@angular/core';
import { Risk } from 'src/app/services/visit.service';

@Injectable({
	providedIn: 'root'
})
export class RiskService {
	risk: Risk | null = null;

	constructor() {
		this.risk = JSON.parse(localStorage.getItem('risk') || '{}');
	}

	setRisk = (risk: Risk) => {
		localStorage.setItem('risk', JSON.stringify(risk));
	};

	getRisk = () => {
		return JSON.parse(localStorage.getItem('risk') || '{}');
	};

	clearRisk = () => {
		localStorage.removeItem('risk');
	};
}
