<div class="tj-profile">
	<div class="tj-profile-header">
		<div class="tj-header-navigation" *ngIf="device !== 'desktop'">
			<button class="tj-header-navigation-button" (click)="goHome()">
				<i class="moon-arrow"></i>
			</button>
		</div>
		<h1>{{ 'profile' | translate }}</h1>
	</div>
	<mat-tab-group class="tj-profile-tabs" mat-stretch-tabs="false" dynamicHeight animationDuration="0ms">
		<mat-tab>
			<ng-template mat-tab-label>
				<i class="moon-user-filled"></i>
				<span>{{ 'account' | translate }}</span>
			</ng-template>
			<div class="tj-profile-tab">
				<div nz-row>
					<div nz-col [nzXs]="24" [nzLg]="12">
						<div nz-row [nzGutter]="[0, 20]">
							<div nz-col [nzXs]="24">
								<mat-form-field appearance="fill" hideRequiredMarker class="tj-profile-input"
									subscriptSizing="dynamic">
									<mat-label>{{ 'name' | translate }}</mat-label>
									<input type="text" matInput [value]="user?.name" disabled />
								</mat-form-field>
							</div>
							<div nz-col [nzXs]="24">
								<mat-form-field appearance="fill" hideRequiredMarker class="tj-profile-input"
									subscriptSizing="dynamic">
									<mat-label>{{ 'email' | translate }}</mat-label>
									<input type="text" matInput [value]="user?.email" disabled />
								</mat-form-field>
							</div>
							<div nz-col [nzXs]="24">
								<mat-form-field class="tj-fill-container" appearance="fill" hideRequiredMarker
									subscriptSizing="dynamic">
									<mat-label>{{ 'chooseLanguage' | translate }}</mat-label>
									<mat-select name="severity" [formControl]="language"
										[placeholder]="'selectLanguage' | translate"
										(valueChange)="hasUnsavedChanges = true">
										<mat-option *ngFor="let item of languages" [value]="item.value">
											{{item.label}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div nz-col [nzXs]="24" *ngIf="hasUnsavedChanges">
								<div class="tj-profile-actions">
									<button mat-raised-button (click)="cancel()" [disabled]="!hasUnsavedChanges"
										class="tj-profile-action-cancel">{{ 'cancel' | translate }}</button>
									<button mat-raised-button (click)="save()" [disabled]="!hasUnsavedChanges"
										class="tj-profile-action-save">{{ 'save' | translate }}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div nz-row>
					<div nz-col [nzXs]="24" [nzLg]="12">
						<div class="tj-profile-logout">
							<button mat-raised-button (click)="logout()" class="tj-profile-logout-button">
								<i class="moon-logout-filled"></i>
								<span>{{ 'logout' | translate }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="test">
			<ng-template mat-tab-label>
				<i class="moon-shield-check-filled"></i>
				<span>{{ 'privacyPolicy' | translate }}</span>
			</ng-template>
			<div class="tj-profile-tab">
				<h4>Title</h4>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ante, bibendum nec
					condimentum a, ultricies non arcu. Vestibulum tristique scelerisque quam sit amet pellentesque.
					Vestibulum ut lorem diam. Proin at tristique quam, ac
					rutrum sem. Mauris convallis viverra pulvinar. Fusce dui quam, vulputate non convallis eget,
					fringilla ut erat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
					curae;
					Vivamus non neque erat. Vivamus non gravida augue. Nulla tempus mi et nisl dictum sodales. Curabitur
					pellentesque id nunc non laoreet. Nam auctor aliquet turpis, eget gravida leo ultrices et. Integer
					tempus, ipsum a laoreet mattis, est urna gravida nulla, id malesuada massa ligula a risus. Sed
					dignissim massa dolor, eu euismod ligula tempor id. Etiam ultrices rhoncus turpis id facilisis.
					Phasellus non turpis vel magna faucibus finibus. Integer non placerat urna, sit amet consectetur
					velit. In hac habitasse platea dictumst. Suspendisse efficitur sit amet elit
					accumsan dignissim. Cras
					nisi felis, ornare a pulvinar a, varius vitae tellus. Vivamus nunc quam, euismod eget accumsan non,
					ullamcorper laoreet
					eros. Aliquam lobortis felis quis consectetur lacinia.
					Integer massa quam, dignissim sit amet suscipit eget, scelerisque ac enim. Nulla eget velit sed nunc
					viverra varius et
					sed risus. Quisque eget enim ut velit efficitur interdum. Sed condimentum velit arcu, nec ultrices
					ex laoreet eget. Nam
					aliquam enim porta mi posuere blandit. Sed vel quam ut ante lobortis maximus ut id lectus. Sed
					rutrum ipsum sit amet
					arcu feugiat mattis. In id arcu vel sem rutrum lacinia et non tortor. Nunc sed fermentum risus. Sed
					et metus neque.
					Etiam pharetra ultricies quam vel convallis. Nullam sit amet tincidunt ligula. In fermentum mattis
					mattis.</p>
				<h4>Title</h4>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ante, bibendum nec
					condimentum a, ultricies non arcu. Vestibulum tristique scelerisque quam sit amet pellentesque.
					Vestibulum ut lorem diam. Proin at tristique quam, ac
					rutrum sem. Mauris convallis viverra pulvinar. Fusce dui quam, vulputate non convallis eget,
					fringilla ut erat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
					curae;
					Vivamus non neque erat. Vivamus non gravida augue. Nulla tempus mi et nisl dictum sodales. Curabitur
					pellentesque id nunc non laoreet. Nam auctor aliquet turpis, eget gravida leo ultrices et. Integer
					tempus, ipsum a laoreet mattis, est urna gravida nulla, id malesuada massa ligula a risus. Sed
					dignissim massa dolor, eu euismod ligula tempor id. Etiam ultrices rhoncus turpis id facilisis.
					Phasellus non turpis vel magna faucibus finibus. Integer non placerat urna, sit amet consectetur
					velit. In hac habitasse platea dictumst. Suspendisse efficitur sit amet elit
					accumsan dignissim. Cras
					nisi felis, ornare a pulvinar a, varius vitae tellus. Vivamus nunc quam, euismod eget accumsan non,
					ullamcorper laoreet
					eros. Aliquam lobortis felis quis consectetur lacinia.
					Integer massa quam, dignissim sit amet suscipit eget, scelerisque ac enim. Nulla eget velit sed nunc
					viverra varius et
					sed risus. Quisque eget enim ut velit efficitur interdum. Sed condimentum velit arcu, nec ultrices
					ex laoreet eget. Nam
					aliquam enim porta mi posuere blandit. Sed vel quam ut ante lobortis maximus ut id lectus. Sed
					rutrum ipsum sit amet
					arcu feugiat mattis. In id arcu vel sem rutrum lacinia et non tortor. Nunc sed fermentum risus. Sed
					et metus neque.
					Etiam pharetra ultricies quam vel convallis. Nullam sit amet tincidunt ligula. In fermentum mattis
					mattis.</p>
			</div>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<i class="moon-document-text-filled"></i>
				<span>{{ 'termsAndConditions' | translate }}</span>
			</ng-template>
			<div class="tj-profile-tab">
				<h4>Title</h4>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ante, bibendum nec
					condimentum a, ultricies non arcu. Vestibulum tristique scelerisque quam sit amet pellentesque.
					Vestibulum ut lorem diam. Proin at tristique quam, ac
					rutrum sem. Mauris convallis viverra pulvinar. Fusce dui quam, vulputate non convallis eget,
					fringilla ut erat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
					curae;
					Vivamus non neque erat. Vivamus non gravida augue. Nulla tempus mi et nisl dictum sodales. Curabitur
					pellentesque id nunc non laoreet. Nam auctor aliquet turpis, eget gravida leo ultrices et. Integer
					tempus, ipsum a laoreet mattis, est urna gravida nulla, id malesuada massa ligula a risus. Sed
					dignissim massa dolor, eu euismod ligula tempor id. Etiam ultrices rhoncus turpis id facilisis.
					Phasellus non turpis vel magna faucibus finibus. Integer non placerat urna, sit amet consectetur
					velit. In hac habitasse platea dictumst. Suspendisse efficitur sit amet elit
					accumsan dignissim. Cras
					nisi felis, ornare a pulvinar a, varius vitae tellus. Vivamus nunc quam, euismod eget accumsan non,
					ullamcorper laoreet
					eros. Aliquam lobortis felis quis consectetur lacinia.
					Integer massa quam, dignissim sit amet suscipit eget, scelerisque ac enim. Nulla eget velit sed nunc
					viverra varius et
					sed risus. Quisque eget enim ut velit efficitur interdum. Sed condimentum velit arcu, nec ultrices
					ex laoreet eget. Nam
					aliquam enim porta mi posuere blandit. Sed vel quam ut ante lobortis maximus ut id lectus. Sed
					rutrum ipsum sit amet
					arcu feugiat mattis. In id arcu vel sem rutrum lacinia et non tortor. Nunc sed fermentum risus. Sed
					et metus neque.
					Etiam pharetra ultricies quam vel convallis. Nullam sit amet tincidunt ligula. In fermentum mattis
					mattis.</p>
				<h4>Title</h4>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ante, bibendum nec
					condimentum a, ultricies non arcu. Vestibulum tristique scelerisque quam sit amet pellentesque.
					Vestibulum ut lorem diam. Proin at tristique quam, ac
					rutrum sem. Mauris convallis viverra pulvinar. Fusce dui quam, vulputate non convallis eget,
					fringilla ut erat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
					curae;
					Vivamus non neque erat. Vivamus non gravida augue. Nulla tempus mi et nisl dictum sodales. Curabitur
					pellentesque id nunc non laoreet. Nam auctor aliquet turpis, eget gravida leo ultrices et. Integer
					tempus, ipsum a laoreet mattis, est urna gravida nulla, id malesuada massa ligula a risus. Sed
					dignissim massa dolor, eu euismod ligula tempor id. Etiam ultrices rhoncus turpis id facilisis.
					Phasellus non turpis vel magna faucibus finibus. Integer non placerat urna, sit amet consectetur
					velit. In hac habitasse platea dictumst. Suspendisse efficitur sit amet elit
					accumsan dignissim. Cras
					nisi felis, ornare a pulvinar a, varius vitae tellus. Vivamus nunc quam, euismod eget accumsan non,
					ullamcorper laoreet
					eros. Aliquam lobortis felis quis consectetur lacinia.
					Integer massa quam, dignissim sit amet suscipit eget, scelerisque ac enim. Nulla eget velit sed nunc
					viverra varius et
					sed risus. Quisque eget enim ut velit efficitur interdum. Sed condimentum velit arcu, nec ultrices
					ex laoreet eget. Nam
					aliquam enim porta mi posuere blandit. Sed vel quam ut ante lobortis maximus ut id lectus. Sed
					rutrum ipsum sit amet
					arcu feugiat mattis. In id arcu vel sem rutrum lacinia et non tortor. Nunc sed fermentum risus. Sed
					et metus neque.
					Etiam pharetra ultricies quam vel convallis. Nullam sit amet tincidunt ligula. In fermentum mattis
					mattis.</p>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>