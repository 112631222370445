import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { UserService } from 'src/app/services/user.service';
import { CustomInformation, SecurityQuestion, Visit, VisitService } from 'src/app/services/visit.service';
import { Subscription } from 'rxjs';

@Component({
	standalone: true,
	selector: 'app-additional-information',
	templateUrl: './additional-information.component.html',
	styleUrls: ['./additional-information.component.scss'],
	imports: [MatExpansionModule, MatInputModule, CommonModule, ReactiveFormsModule, MatCheckboxModule, TranslatePipe]
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {
	screenService: Subscription | null = null;
	visit: Visit | null = this.visitService.getStoredVisit();
	step: number[] = [];
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	startedByMe: boolean = false;
	hasChanges: boolean = false;

	detectedDangers: FormControl = new FormControl<string>('');
	activityShortNote: FormControl = new FormControl<string>(''); //activityShortNote
	shortSummary: FormControl = new FormControl<string>(''); //activityInterviewSummary
	remarks: FormControl = new FormControl<string>(''); //notes
	questions: SecurityQuestion[] = [];

	name: FormControl = new FormControl<string>('');

	constructor(private visitService: VisitService, private userService: UserService) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.startedByMe = this.visit?.executedByUserId === this.userService.getUser()?.sub && this.visit?.status !== 'StartRequested';

		this.detectedDangers.setValue(this.visit?.customInformation?.detectedDangersAdditionalActivities?.value || '');
		this.activityShortNote.setValue(this.visit?.activityShortNote || '');
		this.shortSummary.setValue(this.visit?.activityInterviewSummary || '');
		this.remarks.setValue(this.visit?.customInformation?.notes?.value || '');
		this.questions = this.visit?.workplaceSecurityQuestions || [];

		if (!this.startedByMe) this.disableFields();
	}

	ngOnInit(): void {
		this.screenService = this.visitService.visitSubject.subscribe((visit) => {
			this.questions = visit?.workplaceSecurityQuestions || [];
			this.detectedDangers.setValue(visit?.customInformation?.detectedDangersAdditionalActivities?.value || '');
			this.activityShortNote.setValue(visit?.activityShortNote || '');
			this.shortSummary.setValue(visit?.activityInterviewSummary || '');
			this.remarks.setValue(visit?.customInformation?.notes?.value || '');

			if (
				visit?.executedByUserId === this.userService.getUser()?.sub &&
				visit?.status !== 'StartRequested' &&
				visit?.status !== 'Finished'
			) {
				this.enableFields();
			} else {
				this.disableFields();
			}
		});
	}

	ngOnDestroy(): void {
		this.screenService?.unsubscribe();
	}

	enableFields() {
		this.startedByMe = true;
		this.detectedDangers.enable();
		this.activityShortNote.enable();
		this.shortSummary.enable();
		this.remarks.enable();
		this.name.enable();
	}

	disableFields() {
		this.startedByMe = false;
		this.detectedDangers.disable();
		this.activityShortNote.disable();
		this.shortSummary.disable();
		this.remarks.disable();
		this.name.disable();
	}

	onPaneClick(num: number) {
		if (this.step.includes(num)) {
			this.step = this.step.filter((x) => x !== num);
		} else {
			this.step.push(num);
		}
	}

	onCheckChange(event: MatCheckboxChange, primaryKey: number) {
		if (!this.startedByMe) return;

		const visit = this.visitService.getStoredVisit();
		const securityQuestions = visit?.workplaceSecurityQuestions || [];

		const questionObj = securityQuestions.find((x) => x.securityQuestion.primaryKey === primaryKey);

		if (!questionObj) return;

		const previousValue = questionObj.isApplicable;

		if (event.source.name === 'no') {
			if (previousValue === false) questionObj.isApplicable = null;
			else questionObj.isApplicable = false;
		} else {
			if (previousValue) questionObj.isApplicable = null;
			else questionObj.isApplicable = event.checked;
		}

		const newQuestions = securityQuestions.map((question) => {
			if (question.securityQuestion.primaryKey === primaryKey) {
				return questionObj;
			}
			return question;
		});

		this.visitService.setVisit({
			...visit,
			workplaceSecurityQuestions: newQuestions,
			hasUnsavedFields: true
		} as Visit);
	}

	updateNestedField(obj: Visit, field: string, newValue: any): Visit {
		let keys = field.split('.');

		let newObj = JSON.parse(JSON.stringify(obj));

		function recursiveUpdate(obj: any, keys: string[], newValue: any): any {
			let key = keys.shift();

			if (key) {
				if (keys.length === 0) {
					obj[key] = newValue;
				} else {
					recursiveUpdate(obj[key], keys, newValue);
				}
			}
		}

		recursiveUpdate(newObj, keys, newValue);

		return newObj;
	}

	setVisit(field: string) {
		if (!this.startedByMe) return;

		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		let visitField: FormControl | undefined;
		let fieldValue: CustomInformation | undefined;

		switch (field) {
			case 'customInformation.detectedDangersAdditionalActivities':
				visitField = this.detectedDangers;
				fieldValue = visit.customInformation?.detectedDangersAdditionalActivities;
				break;
			case 'activityShortNote':
				visitField = this.activityShortNote;
				break;
			case 'activityInterviewSummary':
				visitField = this.shortSummary;
				break;
			case 'customInformation.notes':
				visitField = this.remarks;
				fieldValue = visit.customInformation?.notes;
				break;
		}

		let newVisit: Visit | undefined;

		if (fieldValue) {
			newVisit = this.updateNestedField(visit, field, {
				primaryKey: fieldValue?.primaryKey ? fieldValue.primaryKey : 0,
				value: visitField?.value
			});
		} else {
			newVisit = this.updateNestedField(visit, field, visitField?.value);
		}

		this.visitService.setVisit({ ...newVisit, hasUnsavedFields: true } as Visit);
	}

	setAnswer(event: Event, primaryKey: number) {
		if (!this.startedByMe) return;

		const visit = this.visitService.getStoredVisit();
		const securityQuestions = visit?.workplaceSecurityQuestions || [];

		const questionObj = securityQuestions.find((x) => x.securityQuestion.primaryKey === primaryKey);

		if (!questionObj) return;

		questionObj.answerText = (event.target as HTMLInputElement).value;

		const newQuestions = securityQuestions.map((question) => {
			if (question.securityQuestion.primaryKey === primaryKey) {
				return questionObj;
			}
			return question;
		});

		this.visitService.setVisit({
			...visit,
			workplaceSecurityQuestions: newQuestions,
			hasUnsavedFields: true
		} as Visit);
	}
}
