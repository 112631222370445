import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReactiveFormsModule } from '@angular/forms';
import { Absent, Employee, Visit, VisitService } from 'src/app/services/visit.service';
import { UserService } from 'src/app/services/user.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Subscription } from 'rxjs';

@Component({
	standalone: true,
	selector: 'app-present-and-absent-staff',
	templateUrl: './present-and-absent-staff.component.html',
	styleUrls: ['./present-and-absent-staff.component.scss'],
	imports: [CommonModule, MatExpansionModule, MatIconModule, ReactiveFormsModule, MatCheckboxModule, TranslatePipe]
})
export class PresentAndAbsentStaffComponent implements OnInit, OnDestroy {
	step: number[] = [];
	device: 'desktop' | 'mobile' = 'desktop';
	startedByMe: boolean = false;
	visitServiceSubscription: Subscription | null = null;
	changes: boolean = false;

	employees: Employee[] = [];
	visit: Visit | null = null;

	constructor(private userService: UserService, private visitService: VisitService) {
		const visit = this.visitService.getStoredVisit();

		this.visit = visit;
		this.employees = visit?.workplaceEmployees ?? [];
		this.device = window.innerWidth <= 768 ? 'mobile' : 'desktop';
		this.startedByMe =
			visit?.executedByUserId === this.userService.getUser()?.sub &&
			visit?.status !== 'StartRequested' &&
			visit?.status !== 'Finished';
		this.changes = visit?.staff?.dangers?.some((x) => x.present === true || x.ppe === true) ?? false;
	}

	ngOnInit(): void {
		this.visitServiceSubscription = this.visitService.visitSubject.subscribe((visit) => {
			this.visit = visit;
			this.employees = visit?.workplaceEmployees ?? [];

			if (
				visit?.executedByUserId === this.userService.getUser()?.sub &&
				visit?.status !== 'StartRequested' &&
				visit?.status !== 'Finished'
			) {
				this.startedByMe = true;
			} else {
				this.startedByMe = false;
			}
		});
	}

	ngOnDestroy() {
		this.visitServiceSubscription?.unsubscribe();
	}

	onPaneClick(num: number) {
		if (this.step.includes(num)) {
			this.step = this.step.filter((x) => x !== num);
		} else {
			this.step.push(num);
		}
	}

	onCheckField(event: MatCheckboxChange, item: Employee, field: keyof Employee) {
		if (!this.startedByMe) return;
		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		const previousValue = item[field] as boolean | null;

		if (event.source.name === 'no') {
			if (previousValue === false) (item[field] as boolean | null) = null;
			else (item[field] as boolean | null) = false;
		} else {
			if (previousValue) (item[field] as boolean | null) = null;
			else (item[field] as boolean | null) = event.checked;
		}

		const newEmployees = visit?.workplaceEmployees.map((x) => {
			if (x.employeePrimaryKey === item.employeePrimaryKey) {
				return item;
			}
			return x;
		});

		this.visitService.setVisit({ ...visit, workplaceEmployees: newEmployees, hasUnsavedFields: true });
	}
}
