<div class="tj-prevention">
	<div class="tj-table-wrapper tj-adjust-bottom">

		<div class="tj-prevention-header">
			<div class="tj-prevention-description">{{ 'subject' | translate }}</div>
			<div>{{ 'yes' | translate }}</div>
			<div>{{ 'no' | translate }}</div>
			<div>{{ 'reason' | translate }}</div>
			<div></div>
		</div>

		<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
			<!-- This is the tree node template for leaf nodes -->
			<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="tj-prevention-row leaf">
				<!-- use a disabled button to provide padding for tree leaf -->
				<button mat-icon-button disabled style="border: 0"></button>
				<div class="tj-prevention-information">
					<p>
						{{node.name}}
					</p>
					<p class="tj-prevention-description">
						{{node.note}}
					</p>
				</div>
				<div class="tj-prevention-cell">
					<mat-checkbox 
						[checked]="node.isApplicable"
						(change)="onCheckChange($event, node.primaryKey, node)" name="yes" disableRipple
						[disabled]="!startedByMe"></mat-checkbox>
				</div>
				<div class="tj-prevention-cell">
					<mat-checkbox 
						[checked]="node.isApplicable === false"
						(change)="onCheckChange($event, node.primaryKey, node)" name="no" disableRipple
						[disabled]="!startedByMe"></mat-checkbox>
				</div>
				<div class="tj-prevention-cell">
					<button class="tj-btn tj-btn__invisible" (click)="showModal( node, $event)">
						<i class="moon-pen-filled"></i>
					</button>
				</div>
				<div>
					&nbsp;
				</div>
			</mat-tree-node>
			<!-- This is the tree node template for expandable nodes -->
			<mat-tree-node *matTreeNodeDef="let node;when: shouldRenderActions" matTreeNodePadding class="tj-prevention-row">
				<button class="tj-btn tj-btn__invisible" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
					(click)="pushLeaves(node.primaryKey)">
					<mat-icon class="mat-icon-rtl-mirror">
						{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
					</mat-icon>
				</button>

				<div class="tj-prevention-information">
					<p>
						{{node.name}}
					</p>
				</div>
				<div class="tj-prevention">
					&nbsp;
				</div>
				<div class="tj-prevention">
					&nbsp;
				</div>
				<div class="tj-prevention">
					&nbsp;
				</div>
			</mat-tree-node>
		</mat-tree>
		<div class="tj-table-no-data" *ngIf="!dataSource.data.length">
			<p>{{ 'noDataSelected' | translate }}</p>
		</div>
	</div>
</div>
