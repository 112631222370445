import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
	standalone: true,
	selector: 'app-deactivate-confirm',
	styleUrls: ['./deactivate-confirm.component.scss'],
	templateUrl: './deactivate-confirm.component.html',
	imports: [TranslatePipe]
})
export class DeactivateConfirmComponent {
	constructor(public modal: NgbActiveModal) { }

	leave() {
		this.modal.close(true);
	}

	stay() {
		this.modal.close(false);
	}
}
