import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Breadcrumb {
	locations: {
		name: string;
		path?: string;
		icon?: string;
	}[];
}

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
	breadcrumb: Breadcrumb = { locations: [] };
	public breadcrumbSubject = new Subject<Breadcrumb>();

	call() {
		this.breadcrumbSubject.next(this.breadcrumb);
		return this.breadcrumbSubject;
	}

	constructor() {
		this.breadcrumb = { locations: [] };
	}

	setBreadcrumb = (breacrumb: Breadcrumb) => {
		this.breadcrumb = breacrumb;
		this.breadcrumbSubject.next(this.breadcrumb);
	};

	getBreacrumb = () => {
		return this.breadcrumb;
	};

	clearBreadcrumb = () => {
		this.breadcrumb = { locations: [] };
		this.breadcrumbSubject.next(this.breadcrumb);
	};
}
