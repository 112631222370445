import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { AuthService } from 'src/app/services/auth.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	standalone: true,
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	imports: [
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		NgIf,
		CommonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		TranslatePipe
	]
})
export class AuthComponent implements OnInit {
	device: string = 'desktop';
	logo: string = '../assets/images/logo.png';
	logoGedat: string = '../assets/images/logo_gedat.png';
	hide: boolean = true;
	authenticating: boolean = false;
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);

	username = new FormControl('', Validators.required);
	password = new FormControl('', Validators.required);
	matcher = new MyErrorStateMatcher();

	constructor(
		public router: Router,
		private notification: NzNotificationService,
		private translateService: TranslateService,
		private authService: AuthService
	) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
	}

	ngOnInit(): void {}

	createSignedInNotification(): void {
		this.notification.create('success', this.translate('authentication'), this.translate('authenticationSuccessful'), {
			nzPlacement: 'bottomLeft'
		});
	}

	createSignInErrorNotification(): void {
		this.notification.create('error', this.translate('authentication'), this.translate('authenticationFailed'), {
			nzPlacement: 'bottomLeft'
		});
	}

	gotoForgotPassword(e: Event) {
		e.preventDefault();
		this.router.navigate(['/forgot-password']);
	}

	login() {
		this.authService.login();
	}
}
