<div class="tj-modal-header">
	<h4 class="tj-modal-header-title" i18n *ngIf="visitType === 'gfb'">{{ 'startVisitGfb' | translate }}</h4>
	<h4 class="tj-modal-header-title" i18n *ngIf="visitType === 'bto'">{{ 'startVisitBto' | translate }}</h4>
</div>
<div class="tj-modal-body">
	<mat-form-field>
		<mat-label>{{ 'chooseDate' | translate}}</mat-label>
		<input matInput [matDatepicker]="picker" (dateInput)="onChange($event)">
		<mat-hint>{{ 'MM_DD_YYYY' | translate}}</mat-hint>
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>
</div>
<div class="tj-modal-footer">
	<button type="button" class="tj-btn tj-btn__danger" (click)="leave()">{{ 'cancel' | translate }}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus *ngIf="visitType === 'gfb'">{{
		'startVisitGfb' | translate
		}}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus *ngIf="visitType === 'bto'">{{
		'startVisitBto' | translate
		}}</button>
</div>