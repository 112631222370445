import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Tree {
	expandedNodesIds: number[];
}

@Injectable({
	providedIn: 'root'
})
export class TreeService {
	tree: Tree | null = null;
	public treeSubject = new Subject<Tree | null>();

	call() {
		this.treeSubject.next(this.tree);
		return this.treeSubject;
	}

	toggleNode(id: number) {
		if (this.tree) {
			if (this.tree.expandedNodesIds.includes(id)) {
				this.tree.expandedNodesIds = this.tree.expandedNodesIds.filter((x) => x !== id);
			} else {
				this.tree.expandedNodesIds.push(id);
			}
			localStorage.setItem('tree', JSON.stringify(this.tree));
		} else {
			this.tree = { expandedNodesIds: [id] };
			localStorage.setItem('tree', JSON.stringify(this.tree));
		}
		this.treeSubject.next(this.tree);
	}

	getTree() {
		this.tree = localStorage.getItem('tree') ? JSON.parse(localStorage.getItem('tree') || '{}') : null;
		return this.tree;
	}

	clearTree() {
		this.tree = null;
		localStorage.removeItem('tree');
		this.treeSubject.next(this.tree);
	}

	constructor() {
		this.tree = localStorage.getItem('tree') ? JSON.parse(localStorage.getItem('tree') || '{}') : null;
	}
}
