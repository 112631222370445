<div class="tj-staff">
	<div class="tj-staff-header">
		<div class="tj-staff-description">{{ 'name' | translate }}</div>
		<div>{{ 'present' | translate }}</div>
		<div>{{ 'ppeNotWorn' | translate }}</div>
		<div></div>
	</div>
	<div class="tj-accordion">
		<mat-accordion class="tj-adjust-bottom">
			<mat-expansion-panel hideToggle [expanded]="step.includes(0)" (opened)="onPaneClick(0)"
				(closed)="onPaneClick(0)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'employees' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': changes || false,
								'tj-rotate-180': step.includes(0)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="tj-staff-list">
					<div *ngFor="let item of employees">
						<div class="tj-staff-entry">
							<div class="tj-staff-row">
								<div class="tj-staff-name">{{item.employeeName}}</div>
								<div class="tj-staff-information">
									<mat-checkbox [checked]="item.isPresent"
										(change)="onCheckField($event, item, 'isPresent')" [disabled]="!startedByMe"
										disableRipple></mat-checkbox>
								</div>
								<div class="tj-staff-information">
									<mat-checkbox [checked]="item.hasPpeBeenWorn"
										(change)="onCheckField($event, item, 'hasPpeBeenWorn')"
										[disabled]="!startedByMe" disableRipple></mat-checkbox>
								</div>
								<div>&nbsp;</div>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>