import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { User, UserService } from '../../services/user.service';
import { nameInitials } from '../../utils/misc';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	standalone: true,
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [MatButtonModule, MatDividerModule, MatMenuModule, CommonModule, TranslatePipe]
})
export class HeaderComponent implements OnInit, OnDestroy {
	logo: string = '../assets/images/logo_white.png';
	logoMobile: string = '../assets/images/logo_no_text.png';
	device: 'desktop' | 'tablet' | 'mobile' = 'desktop';
	user: User | null = null;
	authed: boolean = false;
	initials: string = '';
	componentService: Subscription | null = null;
	shouldRender: boolean = true;
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);

	constructor(
		private userService: UserService,
		private router: Router,
		private notification: NzNotificationService,
		private translateService: TranslateService,
		private authService: AuthService,
		private location: Location
	) {
		this.device = window.innerWidth <= 480 ? 'mobile' : window.innerWidth <= 768 ? 'tablet' : 'desktop';
		this.user = this.userService.getUser();
		this.initials = nameInitials(this.user?.name);

		this.shouldRender = this.location.path() !== '/auth';

		router.events.subscribe(() => {
			this.shouldRender = this.location.path() !== '/auth';
		});
	}

	ngOnInit(): void {
		this.componentService = this.userService.userSubject.subscribe((user) => {
			this.user = user;
			this.initials = nameInitials(this.user?.name);
		});
	}

	ngOnDestroy(): void {
		this.componentService?.unsubscribe();
	}

	goto(path: string) {
		this.router.navigate([path]);
	}

	async signout() {
		try {
			this.authService.logout();
			// this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
			// 	this.router.navigate(['/auth', { logout: true }]);
			// 	this.userService.clearUser();
			// 	this.tokenService.clearToken();
			// });
		} catch (error: unknown) {
			this.createSignOutErrorNotification();
		}
	}

	createSignOutErrorNotification(): void {
		this.notification.create('error', this.translate('authentication'), this.translate('signOutFailed'), { nzPlacement: 'bottomLeft' });
	}
}
