import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { notApplicablePreventionWithoutReason, PreventionDialogData } from 'src/app/services/visit.service';

@Component({
  selector: 'app-reason-missing-dialog',
  templateUrl: './reason-missing-dialog.component.html',
  styleUrls: ['./reason-missing-dialog.component.scss']
})
export class ReasonMissingDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: PreventionDialogData) {}
}
