export const nameInitials = (name?: string) => {
	if (!name) return '';
	const nameInitials = name.split(' ').map((name) => name[0]);
	const firstAndLastInitials = nameInitials?.length
		? nameInitials?.length === 1
			? nameInitials[0]
			: nameInitials[0] + nameInitials[nameInitials.length - 1]
		: '';

	return firstAndLastInitials.toUpperCase();
};
