import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LuxonDateAdapter, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { VisitService } from 'src/app/services/visit.service';

const MY_DATE_FORMAT = {
	parse: {
		dateInput: 'dd.MM.YYYY'
	},

	display: {
		dateInput: 'dd.MM.yyyy',
		monthYearLabel: 'MMMM yyyy',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM yyyy'
	}
};

@Component({
	standalone: true,
	selector: 'app-start-modal',
	templateUrl: './start-modal.component.html',
	styleUrls: ['./start-modal.component.scss'],
	imports: [TranslatePipe, MatInputModule, MatDatepickerModule, MatLuxonDateModule, CommonModule],
	providers: [
		{ provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
		{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
	]
})
export class StartModalComponent implements OnInit {
	@Input() visitType: 'bto' | 'gfb' = 'bto';
	picker: any = null;
	date = '';

	constructor(
		public modal: NgbActiveModal,
		private visitService: VisitService,
		private router: Router,
		private dateAdapter: DateAdapter<Date>
	) {}

	ngOnInit(): void {
		this.dateAdapter.getFirstDayOfWeek = () => 1; //beginnt die Woche am Montag
	}

	onChange(event: any) {
		let date: DateTime;

		if (event.value instanceof DateTime) {
			date = event.value;
		} else if (event.value instanceof Date) {
			date = DateTime.fromJSDate(event.value);
		} else if (typeof event.value === 'string') {
			date = DateTime.fromISO(event.value);
		}

		if (date! && date.isValid) {
			date = date.setZone('utc');
		} else {
			console.error('Invalid date:', event.value);
		}

		this.date = date!.toFormat("yyyy-LL-dd'T'HH:mm:ss.0000000'Z'");
	}

	leave() {
		this.modal.close(true);
	}

	async submit() {
		if (!this.date) {
			return;
		}

		let result: any;

		if (this.visitType === 'bto') {
			result = await this.visitService.startVisit(this.date);
		} else if (this.visitType === 'gfb') {
			result = await this.visitService.startAssessment(this.date);
		}

		this.modal.close(true);

		if (result.status === 'StartRequested') {
			this.router.navigate(['/home']);
		} else if (result.status === 'Started') {
			this.visitService.clearVisit();
			location.reload();
		}
	}
}
