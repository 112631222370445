<div class="tj-work-system">
	<div class="tj-accordion">
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(0)" (opened)="onPaneClick(0)"
				(closed)="onPaneClick(0)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workPlace' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': workplaceName.value,
								'tj-rotate-180': step.includes(0)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="workplaceName" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.workplaceName')" cdkTextareaAutosize [readonly]="true"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(1)" (opened)="onPaneClick(1)"
				(closed)="onPaneClick(1)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workAndDrivingArea' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary':
									workArea.value,
								'tj-rotate-180': step.includes(1)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="workArea" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.workArea')" cdkTextareaAutosize
						[readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(2)" (opened)="onPaneClick(2)"
				(closed)="onPaneClick(2)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workEnvironment' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': workplaceEnvironment.value,
								'tj-rotate-180': step.includes(2)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="workplaceEnvironment"
						[placeholder]="'value' | translate" (blur)="setVisit('workplace.workplaceEnvironment')"
						cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(3)" (opened)="onPaneClick(3)"
				(closed)="onPaneClick(3)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'jobAssignment' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary':
									workplaceAssignment.value,
								'tj-rotate-180': step.includes(3)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="workplaceAssignment"
						[placeholder]="'value' | translate" (blur)="setVisit('workplace.workplaceAssignment')"
						cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(4)" (opened)="onPaneClick(4)"
				(closed)="onPaneClick(4)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workFlowProcess' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': workDescription.value,
								'tj-rotate-180': step.includes(4)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="workDescription" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.workDescription')" cdkTextareaAutosize
						[readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(5)" (opened)="onPaneClick(5)"
				(closed)="onPaneClick(5)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'input' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': input.value,
								'tj-rotate-180': step.includes(5)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="input" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.input')" cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(6)" (opened)="onPaneClick(6)"
				(closed)="onPaneClick(6)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'output' | translate }}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': output.value,
								'tj-rotate-180': step.includes(6)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="output" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.output')" cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(7)" (opened)="onPaneClick(7)"
				(closed)="onPaneClick(7)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workEquipmentAndUtilities' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'equipments')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
									'tj-color__primary': workplaceToolsAndEquipment.value?.length! > 0 || false,
									'tj-rotate-180': step.includes(7)
								}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType === 'equipments'"
					[nzFooter]="footerTpl" nzPlacement="right" [nzTitle]="'workEquipmentAndUtilities' | translate"
					(nzOnClose)="close()" [nzWidth]="device === 'mobile' ? '100%' : 400">
					<ng-container *nzDrawerContent>
						<div id="tj-workplaceToolsAndEquipment-list">
							<div class="tj-search-bar">
								<label class="tj-search-icon">{{ 'workEquipmentAndUtilities' | translate
									}}</label>
								<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
									subscriptSizing="dynamic">
									<input type="text" [formControl]="searchFilter" matInput
										[placeholder]="'searchByTitle' | translate"
										(keydown.enter)="applyFilter('workplaceToolsAndEquipment')">
									<button type="button" (click)="applyFilter('workplaceToolsAndEquipment')"
										mat-icon-button matPrefix [attr.aria-label]="'searchEquipments' | translate
						">
										<i class="moon-search"></i>
									</button>
								</mat-form-field>
							</div>
							<mat-checkbox [checked]="isChecked(item.primaryKey, 'workplaceToolsAndEquipment')"
								*ngFor="let item of equipmentsDrawerEntries"
								(change)="onDrawerCheck(item, 'workplaceToolsAndEquipment')" style="display: block;"
								disableRipple>{{item.name}}</mat-checkbox>
						</div>
					</ng-container>
					<ng-template #footerTpl>
						<div style="display: flex;gap: 1rem;justify-content: center;">
							<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="close()">
								<i class="moon-cross-filled"></i>
								<span>{{ 'cancel' | translate }}</span>
							</button>
							<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit()">
								<i class="moon-check-filled"></i>
								<span>{{ 'submit' | translate }}</span>
							</button>
						</div>
					</ng-template>
				</nz-drawer>
				<div class="tj-list-item" *ngFor="let item of workplaceToolsAndEquipment.value">
					<div class="tj-list-item__item">
						<p class="tj-item-name">{{item.toolsAndEquipment.name}}</p>
						<i class="moon-bin" style="font-size: 1.5rem;"
							(click)="onRemove(item, 'workplace.workplaceToolsAndEquipment')"></i>
					</div>
				</div>
				<span *ngIf="workplaceToolsAndEquipment.value?.length === 0">No equipments or utilities selected!</span>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(8)" (opened)="onPaneClick(8)"
				(closed)="onPaneClick(8)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'workingMaterials' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': workingMaterials.value,
								'tj-rotate-180': step.includes(8)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="workingMaterials" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.workingMaterials')" cdkTextareaAutosize
						[readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(9)" (opened)="onPaneClick(9)"
				(closed)="onPaneClick(9)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'additionalInformation' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': toolsAndEquipmentNotes.value,
								'tj-rotate-180': step.includes(9)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="toolsAndEquipmentNotes"
						[placeholder]="'value' | translate" (blur)="setVisit('workplace.toolsAndEquipmentNotes')"
						cdkTextareaAutosize [readonly]="true"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(10)" (opened)="onPaneClick(10)"
				(closed)="onPaneClick(10)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'qualifications' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button (click)="openDrawer($event, 'qualifications')" class="tj-btn tj-btn__invisible">
							<i class="moon-plus-button"></i>
						</button>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
									'tj-color__primary': workplaceProfessions.value?.length! > 0 || false,
									'tj-rotate-180': step.includes(10)
								}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType === 'qualifications'" 
					[nzFooter]="footerTpl" nzPlacement="right" [nzTitle]="'qualifications' | translate"
					(nzOnClose)="close()" [nzWidth]="device === 'mobile' ? '100%' : 400">
					<ng-container *nzDrawerContent>                                               
						<div id="tj-qualification-list">
							<div class="tj-search-bar">
								<label class="tj-search-icon">{{ 'searchByTitle' | translate
									}}</label>
								<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
									subscriptSizing="dynamic">
									<input type="text" [formControl]="searchFilter" matInput
										[placeholder]="'searchByTitle' | translate"
										(keydown.enter)="applyFilter('qualifications')"
										(click)="applyFilter('qualifications')">
									<button type="button" mat-icon-button matPrefix
										[attr.aria-label]="'searchQualifications' | translate">
										<i class="moon-search"></i>
									</button>
								</mat-form-field>
							</div>
							<mat-checkbox [checked]="isChecked(item.primaryKey, 'qualifications')"
								*ngFor="let item of qualificationsDrawerEntries"
								(change)="onDrawerCheck(item, 'qualifications', $event)" style="display: block;"
								disableRipple>{{item.name}}</mat-checkbox>
						</div>
					</ng-container>
					<ng-template #footerTpl>
						<div style="display: flex;gap: 1rem;justify-content: center;">
							<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="close()">
								<i class="moon-cross-filled"></i>
								<span>{{ 'cancel' | translate
									}}</span>
							</button>
							<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit()">
								<i class="moon-check-filled"></i>
								<span>{{ 'submit' | translate
									}}</span>
							</button>
						</div>
					</ng-template>
				</nz-drawer>                                                                      
				<div class="tj-list-item" *ngFor="let item of workplaceProfessions.value">
					<div class="tj-list-item__item">
						<p class="tj-item-name">{{item.name}}</p>
						<i class="moon-bin" style="font-size: 1.5rem;"
							(click)="onRemove(item, 'workplace.workplaceProfessions')"></i>
					</div>
				</div>
				<span *ngIf="workplaceProfessions.value?.length === 0">{{ 'noQualificationsSelected' | translate
					}}</span>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(11)" (opened)="onPaneClick(11)"
				(closed)="onPaneClick(11)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'humanNecessaryQualifications' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': neededQualifications.value,
								'tj-rotate-180': step.includes(11)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<!-- <mat-checkbox [formControl]="neededQualifications"
					(change)="onCheckChange($event, 'workplace.neededQualifications')"
					(blur)="setVisit('workplace.neededQualifications')" disableRipple>{{
					'humanNecessaryQualifications' | translate }}</mat-checkbox> -->
				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="neededQualifications"
						[placeholder]="'value' | translate" (blur)="setVisit('workplace.neededQualifications')"
						cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion class="tj-adjust-bottom">
			<mat-expansion-panel hideToggle [expanded]="step.includes(13)" (opened)="onPaneClick(13)"
				(closed)="onPaneClick(13)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'onsiteSuperVisor' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': isOnsiteMvSupervisor.value || isDangerousWorkplace.value,
								'tj-rotate-180': step.includes(13)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div class="tj-checkbox-group">
					<mat-checkbox [formControl]="isOnsiteMvSupervisor"
						(change)="onCheckChange($event, 'workplace.isOnsiteMvSupervisor')"
						(blur)="setVisit('workplace.isOnsiteMvSupervisor')" disableRipple>{{
						'onsiteSuperVisor' | translate }}</mat-checkbox>
					<mat-checkbox [formControl]="isDangerousWorkplace"
						(change)="onCheckChange($event, 'workplace.isDangerousWorkplace')"
						(blur)="setVisit('workplace.isDangerousWorkplace')" disableRipple>{{ 'dangerousWorkplace' |
						translate}}</mat-checkbox>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>