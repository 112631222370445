import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
	standalone: true,
	selector: 'app-cancel-visit',
	templateUrl: './cancel-visit.component.html',
	styleUrls: ['./cancel-visit.component.scss'],
	imports: [
		MatInputModule,
		ReactiveFormsModule,
		TranslatePipe
	]
})
export class CancelVisitModalComponent {
	constructor(public modal: NgbActiveModal) { }

	leave() {
		this.modal.close(false);
	}

	submit() {
		this.modal.close(true);
	}
}
