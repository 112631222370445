<div class="tj-modal-header">
	<h4 class="tj-modal-header-title" i18n>{{ 'cancelVisit' | translate }}</h4>
</div>
<div class="tj-modal-body tj-risk-description-modal">
	<p>{{ 'cancelDescription' | translate }}</p>
</div>
<div class="tj-modal-footer">
	<button type="button" class="tj-btn tj-btn__danger" (click)="leave()">{{ 'cancel' | translate }}</button>
	<button type="button" class="tj-btn tj-btn__primary" (click)="submit()" ngbAutoFocus>{{ 'okCancelVisit' | translate
		}}</button>
</div>