<section>
	<table mat-table [dataSource]="data">
		<!-- Position Column -->
		<ng-container matColumnDef="started">
			<th mat-header-cell *matHeaderCellDef class="table-header-visit">{{ 'visit' | translate }}</th>
			<td mat-cell class="table-cell-visit" *matCellDef="let element">
				<i class="moon-clipboard-remove-filled tj-badge"
					*ngIf="element.status === 'New' || element.status ==='Unknown'"></i>
				<i class="moon-user-block-filled tj-badge__yellow"
					*ngIf="element.status === 'Started' && element.executedByUserId !== user?.sub"></i>
				<i class="moon-clipboard-list-filled tj-badge__blue"
					*ngIf="element.status === 'Started' && element.executedByUserId === user?.sub"></i>
				<i class="moon-clipboard-checked-filled tj-badge__green" *ngIf="element.status === 'Finished'"></i>
				<div class="tj-rotate-animation" *ngIf="element.status === 'StartRequested'">
					<i class="moon-loading tj-badge__blue"></i>
				</div>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="customer">
			<th mat-header-cell *matHeaderCellDef class="table-header-follow-up">{{ 'customer' | translate }}</th>
			<td mat-cell *matCellDef="let element">
				<div class="table-header-information">
					<span class="bold">{{element.customer}}</span>
					<span>{{element.workplace.workplaceName}}</span>
				</div>
			</td>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="dueDate">
			<th mat-header-cell *matHeaderCellDef class="table-header-date">{{ 'date' | translate }}</th>
			<td mat-cell *matCellDef="let element">{{formatDate(element.dueDate)}}</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="dateDiff">
			<th mat-header-cell *matHeaderCellDef class="table-header-align-right table-header-days">{{ 'days' |
				translate }} </th>
			<td mat-cell class="table-header-align-right" *matCellDef="let element">
				<div class="table-row-block">
					{{element.dateDiff}}
					<div class="table-header-date-badge tj-badge__green" *ngIf="element.dateDiff > 0"></div>
					<div class="table-header-date-badge tj-badge__yellow" *ngIf="element.dateDiff === 0"></div>
					<div class="table-header-date-badge tj-badge__red" *ngIf="element.dateDiff < 0"></div>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row class="table-row-clickable" *matRowDef="let row; columns: displayedColumns;"
			(click)="gotoVisit(row)"></tr>
	</table>
	<div *ngIf="data.length == 0" class="table-no-data">{{ 'noDataAvailable' | translate }}</div>
</section>