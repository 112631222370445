<div class="tj-home-tables" *ngIf="device === 'desktop'; else tableMobile">
	<div class="tj-table">
		<div class="tj-table-header">{{ 'btoTableTitle' | translate }}</div>
		<div class="tj-table-content">
			<app-visit-table [data]="btoList" [type]="'bto'"></app-visit-table>
		</div>
	</div>
	<div class="tj-table">
		<div class="tj-table-header">{{ 'gfbTableTitle' | translate }}</div>
		<div class="tj-table-content">
			<app-visit-table [data]="gfbList" [type]="'gfb'"></app-visit-table>
		</div>
	</div>
</div>
<ng-template #tableMobile>
	<mat-tab-group class="tj-home__mobile">
		<mat-tab label="BTO">
			<div class="tj-table tj-table__mobile">
				<div class="tj-table-header" *ngIf="device === 'desktop'">{{ 'btoTableTitle' | translate }}</div>
				<div class="tj-table-content">
					<app-visit-table [data]="btoList" [type]="'bto'"></app-visit-table>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="GFB">
			<div class="tj-table tj-table__mobile">
				<div class="tj-table-header" *ngIf="device === 'desktop'">{{ 'gfbTableTitle' | translate }}</div>
				<div class="tj-table-content">
					<app-visit-table [data]="gfbList" [type]="'gfb'"></app-visit-table>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</ng-template>
