<div class="tj-risks-evaluated">
	<div class="tj-table-wrapper tj-adjust-bottom">
		<div class="tj-risk-header">
			<div class="tj-risk-factors">{{ 'riskFactors' | translate }}</div>
			<div>{{ 'comment' | translate }}</div>
			<div>{{ 'risk' | translate }}</div>
			<div>{{ 'ppe' | translate }}</div>
			<div>{{ 'occupationalMedicine' | translate }}</div>
			<div class="tj-header-icon">
				<button (click)="openDrawer($event, 'riskFactors')">
					<i class="moon-plus-button"></i>
				</button>
			</div>
		</div>
		<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
			<!-- This is the tree node template for leaf nodes -->
			<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="tj-risk-row leaf">
				<!-- use a disabled button to provide padding for tree leaf -->
				<button mat-icon-button disabled style="border: 0"></button>
				<div class="tj-risk-information">
					<p>
						{{node.name}}
					</p>
					<p class="tj-risk-description">
						{{node.description}}
					</p>
				</div>
				<div class="tj-risk">
					<button class="tj-btn tj-btn__invisible" (click)="showModal('description', node, $event)">
						<i class="moon-pen-filled"></i>
					</button>
				</div>
				<div class="tj-risk">
					<p class="tj-risk-number">{{ (node.probability?.value || 0) + (node.severeDamage?.value || 0) }}</p>
					<button class="tj-btn tj-btn__invisible" (click)="showModal('hazard', node, $event)">
						<i class="moon-pen-filled"></i>
					</button>
				</div>
				<div class="tj-risk">
					<button class="tj-button-icon" [ngClass]="{'transparent': node.workplacePpe.length > 0}"
						(click)="openDrawer($event, 'ppe', node)">
						<i class="moon-dots" *ngIf="node.workplacePpe.length === 0"></i>
						<i class="moon-check-circle" *ngIf="node.workplacePpe.length > 0"></i>
					</button>

				</div>
				<div class="tj-risk">
					<button class="tj-button-icon" [ngClass]="{'transparent': node.workplacePrecaution.length > 0}"
						(click)="openDrawer($event, 'workplacePrecaution', node)">
						<i class="moon-dots" *ngIf="node.workplacePrecaution.length === 0"></i>
						<i class="moon-check-circle" *ngIf="node.workplacePrecaution.length > 0"></i>
					</button>
				</div>
				<div class="tj-risk-icon">
					<button class="tj-btn tj-btn__invisible" (click)="removeRisk($event, node)">
						<i class="moon-bin"></i>
					</button>
				</div>
			</mat-tree-node>
			<!-- This is the tree node template for expandable nodes -->
			<mat-tree-node *matTreeNodeDef="let node;when: shouldRenderActions" matTreeNodePadding class="tj-risk-row">
				<button class="tj-btn tj-btn__invisible" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
					(click)="pushLeaves(node.primaryKey)">
					<mat-icon class="mat-icon-rtl-mirror">
						{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
					</mat-icon>
				</button>

				<div class="tj-risk-information">
					<p>
						{{node.name}}
					</p>
				</div>
				<div class="tj-risk">
					&nbsp;
				</div>
				<div class="tj-risk">
					&nbsp;
				</div>
				<div class="tj-risk">
					&nbsp;
				</div>
				<div class="tj-risk">
					&nbsp;
				</div>
			</mat-tree-node>
		</mat-tree>
		<div class="tj-table-no-data" *ngIf="!dataSource.data.length">
			<p>{{ 'noDataSelected' | translate }}</p>
		</div>
		<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType === 'ppe'" [nzFooter]="footerTpl"
			nzPlacement="right" [nzTitle]="'ppe' | translate" (nzOnClose)="closeDrawer()"
			[nzWidth]="device === 'mobile' ? '100%' : 400" class="tj-risks-evaluated-drawer">
			<ng-container *nzDrawerContent>
				<div class="tj-search-bar">
					<label class="tj-search-icon">{{ 'searchByTitle' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" matInput [placeholder]="'searchByTitle' | translate"
							(input)="onKey($event, 'ppe')">
						<button type="button" mat-icon-button matPrefix [attr.aria-label]="'searchPPE' | translate">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
				</div>
				<mat-checkbox [checked]="tempPpe.includes(item.primaryKey)" *ngFor="let item of searchedPpe"
					(change)="onCheck(item.primaryKey, 'ppe')" style="display: block;"
					disableRipple>{{item.name}}</mat-checkbox>
			</ng-container>
			<ng-template #footerTpl>
				<div style="display: flex;gap: 1rem;justify-content: center;">
					<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
						<i class="moon-cross-filled"></i>
						<span>{{ 'cancel' | translate }}</span>
					</button>
					<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit()">
						<i class="moon-check-filled"></i>
						<span>{{ 'submit' | translate }}</span>
					</button>
				</div>
			</ng-template>
		</nz-drawer>
		<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType === 'workplacePrecaution'"
			[nzFooter]="footerTpl" nzPlacement="right" [nzTitle]="'occupationalMedicine' | translate"
			(nzOnClose)="closeDrawer()" [nzWidth]="device === 'mobile' ? '100%' : 400">
			<ng-container *nzDrawerContent>
				<div class="tj-search-bar">
					<label class="tj-search-icon">{{ 'searchByTitle' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" matInput [placeholder]="'searchByTitle' | translate"
							(input)="onKey($event, 'workplacePrecaution')">
						<button type="button" mat-icon-button matPrefix
							[attr.aria-label]="'searchMedicine' | translate">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
				</div>
				<mat-checkbox [checked]="tempWorkplacePrecaution.includes(item.primaryKey)"
					*ngFor="let item of searchedWorkplacePrecaution"
					(change)="onCheck(item.primaryKey, 'workplacePrecaution')" style="display: block;"
					disableRipple>{{item.name}}</mat-checkbox>
			</ng-container>
			<ng-template #footerTpl>
				<div style="display: flex;gap: 1rem;justify-content: center;">
					<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
						<i class="moon-cross-filled"></i>
						<span>{{ 'cancel' | translate }}</span>
					</button>
					<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit()">
						<i class="moon-check-filled"></i>
						<span>{{ 'submit' | translate }}</span>
					</button>
				</div>
			</ng-template>
		</nz-drawer>
		<nz-drawer [nzClosable]="false" [nzVisible]="visible && drawerType === 'riskFactors'" [nzFooter]="footerTpl"
			nzPlacement="right" [nzTitle]="'risksEvaluated' | translate" (nzOnClose)="closeDrawer()"
			[nzWidth]="device === 'mobile' ? '100%' : 400">
			<ng-container *nzDrawerContent>
				<div class="tj-search-bar" style="margin-bottom: 1rem;">
					<label class="tj-search-icon">{{ 'searchByTitle' | translate }}</label>
					<mat-form-field appearance="fill" hideRequiredMarker class="tj-drawer-input"
						subscriptSizing="dynamic">
						<input type="text" matInput [placeholder]="'searchByTitle' | translate"
							(input)="onKey($event, 'riskFactors')">
						<button type="button" mat-icon-button matPrefix [attr.aria-label]="'searchRisks' | translate">
							<i class="moon-search"></i>
						</button>
					</mat-form-field>
				</div>

				<nz-tree #nzTreeComponent [nzData]="risksList" nzCheckable [nzCheckedKeys]="checkedKeys"
					[nzExpandedIcon]="multiExpandedIconTpl" [nzSearchValue]="treeSearch"
					class="tj-risks-evaluated-tree">

					<ng-template #multiExpandedIconTpl let-node let-origin="origin">
						<button class="tj-btn tj-btn__invisible--tree" [class]="node.isExpanded ? 'tj-rotate-90' : ''">
							<i class="moon-arrow" *ngIf="node.level !== 2"></i>
						</button>
					</ng-template>
				</nz-tree>

			</ng-container>
			<ng-template #footerTpl>
				<div style="display: flex;gap: 1rem;justify-content: center;">
					<button class="tj-btn tj-btn__danger" style="width: 100%;" (click)="closeDrawer()">
						<i class="moon-cross-filled"></i>
						<span>{{ 'cancel' | translate }}</span>
					</button>
					<button class="tj-btn tj-btn__primary" style="width: 100%;" (click)="onSubmit()">
						<i class="moon-check-filled"></i>
						<span>{{ 'submit' | translate }}</span>
					</button>
				</div>
			</ng-template>
		</nz-drawer>
	</div>
</div>