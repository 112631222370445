<div class="tj-specific-characteristics">
	<div class="tj-accordion">
		<mat-accordion>
			<mat-expansion-panel hideToggle [expanded]="step.includes(0)" (opened)="onPaneClick(0)"
				(closed)="onPaneClick(0)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'specificCharacteristics' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': specificFeatures.value,
								'tj-rotate-180': step.includes(0)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="specificFeatures"
						[placeholder]="'value' | translate" (blur)="setVisit('workplace.specificFeatures')"
						cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion class="tj-adjust-bottom">
			<mat-expansion-panel hideToggle [expanded]="step.includes(1)" (opened)="onPaneClick(1)"
				(closed)="onPaneClick(1)">
				<mat-expansion-panel-header>
					<mat-panel-title class="tj-accordion-title">{{ 'healthPromotion' | translate
						}}</mat-panel-title>
					<mat-panel-description>
						<button class="tj-btn tj-btn__invisible">
							<i class="moon-arrow-down-filled" [ngClass]="{
								'tj-color__primary': healthPromotion.value,
								'tj-rotate-180': step.includes(1)
							}"></i>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<mat-form-field class="tj-fill-container tj-accordion-input" appearance="fill" hideRequiredMarker
					subscriptSizing="dynamic">
					<textarea type="text" matInput [formControl]="healthPromotion" [placeholder]="'value' | translate"
						(blur)="setVisit('workplace.healthPromotion')" cdkTextareaAutosize [readonly]="!startedByMe"></textarea>
				</mat-form-field>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
