import { Component, OnInit } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgFor } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Visit, VisitService } from 'src/app/services/visit.service';
import { RiskService } from 'src/app/views/visit/services/risk.service';
import { TranslatePipe, TranslateService } from 'src/app/pipes/translate.pipe';

@Component({
	standalone: true,
	selector: 'app-modal-hazard',
	templateUrl: './modal-hazard.component.html',
	styleUrls: ['./modal-hazard.component.scss'],
	imports: [ReactiveFormsModule, MatSelectModule, NgFor, TranslatePipe]
})
export class ModalHazardComponent implements OnInit {
	translate = (value: string, lang?: 'en' | 'de'): string => this.translateService.translate(value, lang);
	probability = new FormControl(0);
	severity = new FormControl(0);

	scaleProbability: any[] = [];
	scaleSevereDamage: any[] = [];

	constructor(
		public modal: NgbActiveModal,
		private riskService: RiskService,
		private visitService: VisitService,
		private translateService: TranslateService
	) {
		const risk = this.riskService.getRisk();

		this.probability.setValue(risk?.probability?.primaryKey || 0);
		this.severity.setValue(risk?.severeDamage?.primaryKey || 0);
	}

	ngOnInit(): void {
		this.getData();
	}

	async getData() {
		const damageProbabilityCatalogue = await this.visitService.getDamageProbabilityCatalogue();
		this.scaleProbability = damageProbabilityCatalogue.map((item) => {
			return { primaryKey: item.primaryKey, viewValue: item.name, value: item.value };
		});
		const damageSeverityCatalogue = await this.visitService.getDamageSeverityCatalogue();
		this.scaleSevereDamage = damageSeverityCatalogue.map((item) => {
			return { primaryKey: item.primaryKey, viewValue: item.name, value: item.value };
		});
	}

	leave() {
		this.modal.close(true);
	}

	submit() {
		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		const riskId = this.riskService.getRisk()?.primaryKey;

		const probabilityObj = this.scaleProbability.find((item) => item.primaryKey === this.probability.value);
		const severeDamageObj = this.scaleSevereDamage.find((item) => item.primaryKey === this.severity.value);

		let newRiskAssesment = 0;
		let assesed = 0;

		const newRisks = visit.workplaceRisks.map((risk) => {
			let sum = 0;

			if (risk.risk.primaryKey === riskId) {
				if (probabilityObj?.value) sum += probabilityObj?.value;
				if (severeDamageObj?.value) sum += severeDamageObj?.value;

				if (sum > 0) assesed++;

				newRiskAssesment += sum;

				return {
					...risk,
					probability: {
						primaryKey: probabilityObj?.primaryKey,
						name: probabilityObj?.viewValue,
						value: probabilityObj?.value
					},
					severeDamage: {
						primaryKey: severeDamageObj?.primaryKey,
						name: severeDamageObj?.viewValue,
						value: severeDamageObj?.value
					}
				};
			} else {
				if (risk?.probability?.value) sum += risk?.probability?.value;
				if (risk?.severeDamage?.value) sum += risk?.severeDamage?.value;

				if (sum > 0) assesed++;

				newRiskAssesment += sum;

				return risk;
			}
		});

		const riskScore = newRiskAssesment / assesed;

		this.visitService.setVisit({
			...visit,
			workplaceRisks: newRisks,
			workplace: {
				...visit.workplace,
				riskScore: parseFloat(riskScore.toFixed(2))
			},

			hasUnsavedFields: true
		} as Visit);

		this.riskService.clearRisk();
		this.modal.close(true);
	}
}
