import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, ReactiveFormsModule } from '@angular/forms';

import { Visit, VisitService } from 'src/app/services/visit.service';
import { RiskService } from 'src/app/views/visit/services/risk.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	standalone: true,
	selector: 'app-modal-description',
	templateUrl: './modal-description.component.html',
	styleUrls: ['./modal-description.component.scss'],
	imports: [MatInputModule, ReactiveFormsModule, TranslatePipe]
})
export class ModalDescriptionComponent {
	description = new FormControl('');

	constructor(public modal: NgbActiveModal, private visitService: VisitService, private riskService: RiskService) {
		this.description.setValue(this.riskService.getRisk()?.description || '');
	}

	leave() {
		this.modal.close(true);
	}

	submit() {
		const visit = this.visitService.getStoredVisit();
		if (!visit) return;

		const riskId = this.riskService.getRisk()?.primaryKey;
		const newRisks = visit.workplaceRisks.map((risk) => {
			if (risk.risk?.primaryKey === riskId) {
				return {
					...risk,
					description: this.description.value
				};
			}
			return risk;
		});

		this.visitService.setVisit({
			...visit,
			workplaceRisks: newRisks,
			hasUnsavedFields: true
		} as Visit);

		this.riskService.clearRisk();
		this.modal.close(true);
	}
}
