<div class="tj-auth-container">
	<div *ngIf="device === 'desktop'" class="tj-auth-half-container tj-auth-left-container">
		<img class="tj-brand" src={{logo}} alt="logo" />
		<div data-separator></div>
		<div class="tj-auth-copy">
			<h2>{{ 'authCopy' | translate }}</h2>
		</div>
	</div>
	<div
		[ngClass]="device === 'desktop' ? 'tj-auth-half-container tj-auth-right-container' : 'tj-auth-mobile-container'">
		<div class="tj-auth-container-inner">
			<img *ngIf="device === 'mobile'" src={{logo}} alt="logo" />
			<div class="tj-auth-form-header">
				<h1>{{ 'welcomeBack' | translate }}</h1>
				<h5>{{ 'loginToContinue' | translate }}</h5>
			</div>
			<form (ngSubmit)="login()" class="tj-auth-form">
				<!-- <div class="tj-auth-form-group">
					<mat-form-field class="tj-fill-container" appearance="fill" hideRequiredMarker>
						<mat-label>{{ 'userName' | translate }}</mat-label>
						<input type="text" matInput [formControl]="username" [errorStateMatcher]="matcher"
							[placeholder]="'userName' | translate">
						<mat-error *ngIf="username.hasError('required')">
							{{ 'pleaseEnterYourUsername' | translate }}
						</mat-error>
					</mat-form-field>
				</div>
				<div class="tj-auth-form-group">
					<mat-form-field class="tj-fill-container" appearance="fill" hideRequiredMarker>
						<mat-label>{{ 'password' | translate }}</mat-label>
						<input [type]="hide ? 'password' : 'text'" matInput [formControl]="password"
							[errorStateMatcher]="matcher" [placeholder]="'password' | translate">
						<button type="button" mat-icon-button matSuffix (click)="hide = !hide"
							[attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
							<mat-icon class="tj-auth-icon">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
						</button>
						<mat-error *ngIf="password.hasError('required')">
							{{ 'pleaseEnterYourPassword' | translate }}
						</mat-error>
					</mat-form-field>
				</div> -->
				<div class="tj-auth-form-group">
					<button type="submit">
						<span *ngIf="!authenticating; else loader">{{ 'login' | translate }}</span>
						<ng-template #loader>
							<mat-spinner mode="indeterminate" diameter="25"></mat-spinner>
						</ng-template>
					</button>
					<div class="tj-auth-forgot-password">
						<a href="/forgot-password" (click)="gotoForgotPassword($event)">{{ 'forgotPassword' | translate
							}}</a>
					</div>
				</div>
			</form>
		</div>
		<div [ngClass]="device === 'desktop' ? 'tj-auth-footer-copyright' : 'tj-auth-footer-copyright mobile'">
			<span>{{ 'copyright' | translate }}</span>
		</div>
	</div>
	<ng-template #mobileLogin>
	</ng-template>
</div>